import React, { Component } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
}

export interface S {
  isOngoingModal: boolean,
  isUnfinishedModal: boolean,
  unfinishedTasks: number,
  completedTasks: number,
  ongoingTasks: number,
  membersNotTrackingTime: number,
  emergencyTasks: number,
  ongoingEmergencyTasks: number,
  pastDueTasks: number,
  ongoingPastDueTasks: number,
  teamMetrics: any[],
  isTaskCreate: boolean,
  tasksOverTracked: number,
  totalHoursTracked: number,
  overBudget: number,
  percentageOfHoursAllocated: string,
}

export interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

class ManagerDashboardControllerWeb extends BlockComponent<
Props,
S,
SS
> {
  userSessionData: any;
  userToken: any;
  taskMetricsApiId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isOngoingModal: false,
      isUnfinishedModal: false,
      unfinishedTasks: 0,
      completedTasks: 0,
      ongoingTasks: 0,
      membersNotTrackingTime: 0,
      emergencyTasks: 0,
      ongoingEmergencyTasks: 0,
      pastDueTasks: 0,
      ongoingPastDueTasks: 0,
      teamMetrics: [],
      isTaskCreate: false,
      tasksOverTracked: 0,
      totalHoursTracked: 0,
      overBudget: 0,
      percentageOfHoursAllocated: "",
    };

    this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
  }

  async componentDidMount(): Promise<void> {
    this.getTaskMetrics();
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
  }

  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.taskMetricsApiId:
              let metrics = responseJson;
              this.setState({
                unfinishedTasks: metrics.task_metrics.unfinished_tasks,
                completedTasks: metrics.task_metrics.completed_tasks, 
                ongoingTasks: metrics.task_metrics.ongoing_tasks,
                membersNotTrackingTime: metrics.task_metrics.member_not_tracking_time,
                emergencyTasks: metrics.task_metrics.emergency_tasks,
                ongoingEmergencyTasks: metrics.task_metrics.ongoing_emergency_tasks,
                pastDueTasks: metrics.task_metrics.past_due_tasks,
                ongoingPastDueTasks: metrics.task_metrics.ongoing_past_due_tasks,
                teamMetrics: metrics.team_metrics,    
                tasksOverTracked: metrics.performance_metrics.tasks_over_tracked,
                totalHoursTracked: metrics.performance_metrics.tasks_over_tracked,
                overBudget: metrics.performance_metrics.over_budget,
                percentageOfHoursAllocated: metrics.performance_metrics.percentage_of_hours_allocated,
              })
              break;
          }
        }
      }
    }
  }

  ongoingTasks = [
    {
     "name": "Arnold Carter",
     "timer": "33:00/35:00" + this.t("hrs"),
     "unfinished": 0,
     "high_priority": 0,
     "past_due": 0,
     "completed": 0,
     "total": 0,
     "ongoing": "Time Off"
    },
    {
    "name": "Esther Howard",
    "timer": "43:00/36:00" + this.t("hrs"),
    "unfinished": 15,
    "high_priority": 23,
    "past_due": 18,
    "completed": 26,
    "total": 40,
    "ongoing": "Determine Retail Price for Newly Made Wines"
    },
    {
    "name": "Arnold Carter",
    "timer": "33:00/35:00" + this.t("hrs"),
    "unfinished": 0,
    "high_priority": 0,
    "past_due": 0,
    "completed": 0,
    "total": 0,
    "ongoing": "Time Off"
    },
    {
    "name": "Esther Howard",
    "timer": "43:00/36:00" + this.t("hrs"),
    "unfinished": 15,
    "high_priority": 23,
    "past_due": 18,
    "completed": 26,
    "total": 40,
    "ongoing": "Determine Retail Price for Newly Made Wines"
    },
]

closeOngoingModal = () => this.setState({isOngoingModal: false});

closeUnfinishedModal = () => this.setState({isUnfinishedModal: false});

handleTaskCreate = () => this.setState({isTaskCreate: !this.state.isTaskCreate});

taskMetricsApiEndpoint = "account_block/tasks/manager_focus";

getTaskMetrics = () => {

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.userToken?.meta?.token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.taskMetricsApiId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.taskMetricsApiEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
 }
  
}

  

export default ManagerDashboardControllerWeb;

import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Popover,
  CardMedia,

  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  FormControl,
  Select,

  MenuItem,

  InputAdornment,

  Switch,
  TextField,
  Modal,
  InputLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import CloseIcon from "@material-ui/icons/Close";
import {
  minimizeGray,
  closeGray,
  stepperArrow,
  expandForm,
  sldierImg2,
  helpCircle,
  attachmentIcon,
  previewTemplateCampaign,
  arrowDown,
  previewTxt,
  checkGreen,
} from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/CfsendinblueHelper.web";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import CreateGroup from "../../../components/src/CreateGroup.web";
import GroupContacts from "../../ContactsList/src/components/GroupContacts.web"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";


import WebLoader from "../../../components/src/WebLoader.web";

import ArrowBack from "@material-ui/icons/ArrowBack";

// Customizable Area End

import CreateEmailCampaignController, {
  Props,
  configJSON,
  // Customizable Area Start
  // Customizable Area End
} from "./CreateEmailCampaignController.web";
import { config } from "process";

export class CreateEmailCampaign extends CreateEmailCampaignController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getRenderedSteps = (activeStep: number) => {
    const { classes } = this.props;
    switch (activeStep) {
      case 0:
        return (
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    label={this.t(`${configJSON.campaignTitleTxt}`)}
                    value={this.state.campaignTitle}
                    isRequired={true}
                    errors={this.state.campaignErrors.campaignTitle}
                    name="campaignTitle"
                    onChange={(event: any) =>
                      this.handleCampaignFormInputs(
                        "campaignTitle",
                        event.target.value
                      )
                    }
                    data-test-id="campaign-title-input"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-col">
              {this.state.sendersList.length > 0 && (
                    <FormControl className="select-outer" variant="outlined">
                      <InputLabel id="stage">
                        {this.t(`${configJSON.emailAddressTxt}`)}
                      </InputLabel>
                      <Select
                        labelId="stage"
                        id="stage"
                        name="newStatus"
                        label={this.t(`${configJSON.emailAddressTxt}`)}
                        onChange={(e: any) => this.handleSenderEmailValue(e.target.value)}
                        value={this.state.fromEmailAddress}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: `${classes.dropdownStyleDeals}`,
                          },
                        }}
                        data-test-id="sender-email-list"
                      >
                        {this.state.sendersList.map((sender) => {
                          return (
                            <MenuItem key={sender.id} value={sender.email}>
                            {sender.email}
                          </MenuItem>
                          )
                        })}
                    
                      </Select>
                    </FormControl>
                )}
                <CustomInputWeb
                  type="text"
                  label={this.t(`${configJSON.nameTxt}`)}
                  value={this.state.fromName}
                  isRequired={true}
                  errors={this.state.campaignErrors.fromNameError}
                  name="fromName"
                  data-test-id="campaign-from-name"
                  onChange={(event: any) => this.handleName(event.target.value)}
                />  
                <Box className="preview-wrapper"> 
                <CardMedia
                component="img"
                className="preview-image"
                image={previewTxt}
                /> 
                </Box>
              </Box>
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
            <Box className="search-box">
                <CustomInputWeb
                  type="text"
                  data-test-id="search-lists-campaign"
                  label=""
                  placeholder={this.t(`${configJSON.searchTypeHereTxt}`)}
                  errors={""}
                  rows={4}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  value={this.state.searchListValue}
                  onChange={(event: any) => this.getSearchListValue(event.target.value)}
                />
              </Box>
              <Box className="radio-btn-wrapper-top">
                    <Typography>
                      {this.t("Groups")}
                    </Typography>
                    {this.sortByDropdown(classes)}
                  </Box>
              
              <Box className="form-row radio-btn-wrapper">
                {this.state.brevoContactLists.length > 0 && (
                  <Box className="un-assign-owner-list">
                    {this.state.brevoContactLists.map((list: any) => {
                      return (
                        <Box
                          className="owner-item"
                          key={list.id}
                          onClick={() =>
                            this.handleListsChange(list.brevo_group_id, list.id, list.contacts_count)
                          }
                          data-test-id={`select-contact-${list.brevo_group_id}`}
                        >
                          <Box className="owner-info">
                            <Typography className="name">
                              {list.name}
                            </Typography>
                            <Typography className="email">
                              {this.t("Farmer Group")}
                            </Typography>
                          </Box>
                          <Box className="count">
                            {list.contacts_count}
                            {this.state.campaignListIds.includes(
                              list.brevo_group_id
                            ) ? (
                              <CheckCircleIcon
                                className="icon-check"
                                //onClick={() => this.handleListsChange(list.id)}
                              />
                            ) : (
                              <RadioButtonUnchecked
                                className="circleCheck"
                                // onClick={() => this.handleListsChange(list.id)}
                                // data-test-id={`select-contact-${list.id}`}
                              />
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        );
      case 3:
        return (
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-col">
                <CustomInputWeb
                  type="text"
                  label={this.t(`${configJSON.subjectTxt}`)}
                  value={this.state.campaignSubjectTxt}
                  isRequired={true}
                  errors={this.state.campaignErrors.subjectTextError}
                  name="campaignSubjectTxt"
                  onChange={(event: any) =>
                    this.handleCampaignFormInputs(
                      "campaignSubjectTxt",
                      event.target.value
                    )
                  }
                  data-test-id="campaign-from-subject"
                />

                <CustomInputWeb
                  type="text"
                  label={this.t(`${configJSON.previewTxt}`)}
                  value={this.state.campaignPreviewTxt}
                  errors={this.state.campaignErrors.previewTextError}
                  name="campaignPreviewTxt"
                  onChange={(event: any) =>
                    this.handleCampaignFormInputs(
                      "campaignPreviewTxt",
                      event.target.value
                    )
                  }
                  data-test-id="campaign-from-preview-text"
                />
                <CardMedia
                component="img"
                className="preview-image"
                image={previewTxt}/>
              </Box>
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-row">
                <Box className="step-grid-wrapper">
                {this.state.emailTemplatesList.length === 0 ? 
                 <Box className="no-designs-available">
                 <Typography className="no-design-modal-title" component="h2">{this.t(`${configJSON.noDesignAvailable}`)}</Typography>
                 <Typography className="no-design-modal-desc" component="p">{this.t(`${configJSON.noDesignsAvailableTxt}`)}</Typography>
                 <Box className="button-group">
                   <Button
                     className={`primary-btn ${classes.primaryButton}`}
                     onClick={this.handleExploreShopBtn}
                     data-test-id="explore-shop-btn"
                   >
                     {this.t(`${configJSON.exploreShopBtn}`)}
                   </Button>
                   <Button
                   className={`secondary-btn blue-border ${classes.secondaryButton}`}
                   onClick={this.visitBrevoBtn}
                   data-test-id="visit-brevo-btn"
                   >
                    {this.t(`${configJSON.VisitBrevoBtn}`)}
                   </Button>
                 </Box>
               </Box> :  <>
                  {this.state.emailTemplatesList.map((template: any) => {
                    if (!template.isActive) {
                      return null;
                    }
                    const isSelected = this.state.campaignTemplateId === template.id ? "selected-template" : ""
                    return (
                      <Box
                        className={`step-grid-item ${isSelected}`}
                        onClick={() => this.useEmailCampaign(template.id)}
                        key={template.id}
                        data-test-id={`use-template-btn-${template.id}`}
                      >
                          <h3>{template.name}</h3>
                      </Box>
                    );
                  })}
               </>
                }
                 
                </Box>
              </Box>
            </Box>
          </Box>
        );
      case 5:
        return (
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-row">
                <Box className="switch-list-wrapper">
                  <Box className="dropdown-menu-item">
                    <Typography className="dropdown-menu-item-text">
                      {this.t(`${configJSON.googleAnalyticsTxt}`)}
                      <Box className="info-content">
                        <img
                          src={helpCircle}
                          className="img-score-outline"
                          alt="circle"
                        />

                        <Box className="para">
                          <Box className="sub-txt">
                            <Typography>
                              {this.t(`${configJSON.googleSubTxt}`)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Typography>

                    <Switch
                      className="dropdown-menu-item-switch"
                      checked={this.state.gAEnabled}
                      name="GoogleAnalytics"
                      data-test-id="ga-switch"
                      onChange={this.handleAdvancedCampaignSettings}
                    />
                    {this.state.gAEnabled && (
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.utmTrackingValueTxt}`)}
                        value={this.state.utmTrackingValue}
                        isRequired={false}
                        //errors={this.state.createDealError.dealNameError}
                        placeholder="Type here"
                        name="handleCampaignFormInputs"
                        onChange={(e: any) =>
                          this.handleCampaignFormInputs(
                            "utmTrackingValue",
                            e.target.value
                          )
                        }
                        data-test-id="utm-tracking-input"
                      />
                    )}
                  </Box>
                  <Box className="dropdown-menu-item">
                    <Typography className="dropdown-menu-item-text">
                      {this.t(`${configJSON.embedImageEmailTxt}`)}
                      <Box className="info-content">
                        <img
                          src={helpCircle}
                          className="img-score-outline"
                          alt="circle"
                        />

                        <Box className="para">
                          <Box className="sub-txt">
                            <Typography>
                              {this.t(`${configJSON.inserImageTxt}`)}
                            </Typography>
                            <Typography>
                              -{this.t(`${configJSON.functionNotSupportTxt}`)}
                            </Typography>
                            <Typography>
                              -{this.t(`${configJSON.cannotSendLargerImgTxt}`)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Typography>
                    <Switch
                      className="dropdown-menu-item-switch"
                      checked={this.state.EmbedImagesEnable}
                      name="EmbedImages"
                      onChange={this.handleAdvancedCampaignSettings}
                      data-test-id="embed-images-switch"
                    />
                  </Box>
                  <Box className="dropdown-menu-item">
                    <Typography className="dropdown-menu-item-text">
                      {this.t(`${configJSON.addAnAttachmentsTxt}`)}
                      <Box className="info-content">
                        <img
                          src={helpCircle}
                          alt="help circle"
                          className="img-score-outline"
                        />

                        <Box className="para">
                          <Box className="sub-txt">
                            <Typography>
                              {this.t(`${configJSON.chooseFileToAttachTxt}`)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Typography>
                    <Switch
                      className="dropdown-menu-item-switch"
                      checked={this.state.attachmentEnable}
                      name="addAttachment"
                      onChange={this.handleAdvancedCampaignSettings}
                      data-test-id="attachment-switch"
                    />
                    {this.state.attachmentEnable && (
                      <>
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.addAttachmentsTxt}`)}
                        isRequired={true}
                        name="Add attachment"
                        endAdornment={
                          <InputAdornment position="end">
                            <img src={attachmentIcon} alt="attachmenticon" onClick={this.onBrowse} />
                          </InputAdornment>
                        }
                      />
                      <form ref={this.formRef}>
                      <input
                        name="upload-attachment"
                        accept="*"
                        type="file"
                        multiple
                        onChange={(e: any) => this.handleOnDropAttachments(e.target.files)}
                        hidden
                        ref={this.fileRef}
                        data-test-id="campaign-attachment-file"
                      />
                    </form>
                    {this.state.attachmentsUploaded.map((name: any, index: number) => (
                      <List className="attachlist-ul" key={index}>
                        <ListItem
                          disableRipple
                          className="attachlist-li"
                          role={undefined}
                          dense
                          button
                          
                        >
                          <ListItemIcon className="attachlist-icon">
                            <img src={checkGreen} alt="checkGreen" />
                          </ListItemIcon>
                          <ListItemText
                            className="attachlist-label"
                            id="checkbox-list-label"
                            primary={name.name}
                          />
                          <ListItemSecondaryAction>
                            <Link
                              to="#"
                              className="tasklist-link link-gray"
                              onClick={() => this.removeAttachment(index)}
                              data-test-id="remove-file-attachment"
                            >
                              <img src={closeGray} alt="closeGray" />
                            </Link>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    ))}
                    </>
                    )}
                  </Box>
                  <Box className="dropdown-menu-item">
                    <Typography className="dropdown-menu-item-text">
                      {this.t(`${configJSON.mirrorTxt}`)}
                      <Box className="info-content">
                        <img
                          src={helpCircle}
                          className="img-score-outline"
                          alt="outline"
                        />
                        <Box className="para">
                          <Box className="sub-txt">
                            <Typography>
                              {this.t(`${configJSON.mirrorSubTxt}`)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Typography>

                    <Switch
                      className="dropdown-menu-item-switch"
                      checked={this.state.mirrorLinkEnable}
                      name="mirrolLink"
                      onChange={this.handleAdvancedCampaignSettings}
                      data-test-id="mirrolLink"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      case 6:
        return (
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-row">
                <Box className="form-col custom-datepicker">
                  <CustomDatePicker
                    placeholder={this.t(`${configJSON.chooseDateTxt}`)}
                    range={false}
                    //required={true}
                    error={this.state.scheduleDateError}
                    value={this.state.campaignScheduledate}
                    currentDate={this.state.campaignScheduledate ? this.state.campaignScheduledate : ""}
                    onChange={this.scheduleCampaignDate}
                    onOpenPickNewDate={false}
                    minDate={new Date()}
                    t={this.t}
                    data-test-id="schedule-date-input"
                    inPortal={true}
                    calendarPosition="bottom-right"
                    customPortal={document.body}
                  />
                  <Box className="time-picker-outer">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.chooseTime}`)}
                      value={this.state.campaignScheduleTime}
                      name="Choose Time"
                      isRequired={true}
                      errors={this.state.scheduleTimeError}
                      onClick={this.showTimePickerModal}
                      data-test-id="schedule-time-input"
                    />
                    {this.state.showTimePicker && (
                      <Box
                        className={classes.timestandDropbox}
                        id="timeStandId"
                      >
                        <Box className="timestand-inner">
                          <Box className="timestand-heading">
                            {this.t(`${configJSON.chooseTime}`)}
                          </Box>
                          <Box component="form">
                            <Box className="time">
                              <TextField
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                inputProps={{
                                  step: 1,
                                  min: 0,
                                  max: 999,
                                }}
                                placeholder="HH"
                                id="standard-basic"
                                name="hours"
                                label=""
                                value={this.state.hours}
                                onChange={this.hoursChange}
                                type="number"
                                data-test-id="campaignTaskHours"
                              />
                              <Box component="span" className="colon-txt">
                                :
                              </Box>
                              <TextField
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                placeholder="MM"
                                id="standard-basic2"
                                label=""
                                name="minutes"
                                type="number"
                                value={this.state.minutes}
                                onChange={this.minutesChange}
                                data-test-id="campaignTaskMinutes"
                              />
                              <Box component="span" className="colon-txt">
                                :
                              </Box>
                              <TextField
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                placeholder="SS"
                                id="standard-basic2"
                                label=""
                                name="seconds"
                                type="number"
                                value={this.state.seconds}
                                onChange={this.secondsChange}
                                data-test-id="campaignTaskSeconds"
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box className="timestand-actions">
                          <Link
                            to="#"
                            className="secondary-link"
                            onClick={this.closeTimePickerModal}
                            data-test-id="cancelHoursbtn"
                          >
                            {this.t(`${configJSON.cancelTxt}`)}
                          </Link>
                          <Link
                            to="#"
                            className="primary-link"
                            onClick={this.setHours}
                            data-test-id="setHoursSave"
                          >
                            {this.t(`${configJSON.saveTxt}`)}
                          </Link>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };
  showStepsTimeline = (steps: any, activeStep: number) => {
    const { classes } = this.props;
    const popOverOpen = Boolean(this.state.showStepperTimeline);
    const popoverId = popOverOpen ? "simple-stepper-popover" : undefined;
    const getNavigationContent = (listItemIndex: number) => {
      if (activeStep > listItemIndex) {
        return (
          <span className="complete">
            {this.t(`${configJSON.completedTxt}`)}
          </span>
        );
      } else if (activeStep === listItemIndex) {
        return (
          <span className="current">{this.t(`${configJSON.currentTxt}`)}</span>
        );
      } else {
        return (
          <span className="arrow" onClick={this.handleNext}>
            <img src={stepperArrow} alt="stepperArrow" />
          </span>
        );
      }
    };
    return (
      <Popover
      id={popoverId}
      open={this.state.showStepperTimeline}
      anchorEl={this.state.stepperAnchorEl}
      onClose={this.closeStepperBox}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      classes={{
        paper: `${classes.popoverStepper}`,
      }}
      onKeyDown={(event) => {
        this.handleEscCampaign(event);
      }}
      
      PaperProps={{
        style: {
          position: "absolute",
          top: "108px",
          left: "1100px"

        },
      }}
      //onClick={this.handleOutsideClick}
      data-test-id="stepper-popver"
    >
      <Box className={classes.stepperInsideModal}>
        <List>
          {steps.map(
            (
              listItem: { label: string; description: string },
              listItemIndex: number
            ) => (
              <ListItem
                key={listItemIndex}
                className={`${
                  activeStep === listItemIndex ? "current-step" : ""
                } ${activeStep > listItemIndex ? "completed-step" : ""}`}
              >
                <Box className="list-inner">
                  {activeStep > listItemIndex ? (
                    <span className="counter">
                      <em>1</em> <span className="checked"></span>{" "}
                    </span>
                  ) : (
                    <span className="counter">
                      <em>{listItemIndex + 1}</em>
                    </span>
                  )}

                  <Box className="content">
                    <Typography className="content-title">
                      {listItemIndex === 0 ? (
                        <>
                          {this.state.campaignTitle.trim() !== "" ? (
                            <>{this.state.campaignTitle}</>
                          ) : (
                            <>{this.t(`${listItem.label}`)}</>
                          )}
                        </>
                      ) : (
                        <>{this.t(`${listItem.label}`)}</>
                      )}
                    </Typography>
                    <Typography className="content-desc">
                      {this.t(`${listItem.description}`)}
                    </Typography>
                  </Box>
                  <Box className="navigation">
                    {getNavigationContent(listItemIndex)}
                  </Box>
                </Box>
              </ListItem>
            )
          )}
        </List>
        </Box>
      </Popover>
    );
  };
 

  sortByDropdown = (classes:any) => {
    return (
      <FormControl className="select-control-campaigns">
        <Select
          inputProps={{ IconComponent: () => null }}
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: classes.dropdownStyle },
          }}
          defaultValue={this.t(`${configJSON.sortCampaaignText}`)}
          renderValue={(value) => {
            return (
              <Box>
                {this.t(`${value}`)}
                <img
                  className="sort-icon"
                  src={arrowDown}
                  alt="sortExistingCampaign"
                />
              </Box>
            );
          }}
          onChange={(event) => this.handleSortByLists(event.target.value)}
          data-test-id="sort-contact-lists"
        >
           <MenuItem value="Sort by"> {this.t(`${configJSON.sortCampaaignText}`)}</MenuItem>
           <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
          <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
        </Select>
    </FormControl>
    )
  }

  

    showEmailCampaignForm = (classes:any, steps:any,headings: any) => {
      const popOverOpen = Boolean(this.props.popoveAnchorEl);
      const popoverId = popOverOpen ? "simple-popover" : undefined;
      const { activeStep ,campaignListIds} = this.state;
      const isCampaignSelected = campaignListIds.length > 0;
      return (
        <Popover
        id={popoverId}
        open={this.props.popoveAnchorEl}
        anchorEl={this.props.popoveAnchorEl}
        onClose={this.closeCreateCampaignForm}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{
          paper: `${classes.createCampaignBox} ${
            this.state.showStepperTimeline ? "stepper-timeline-visible" : ""
          }`,
        }}
        onKeyDown={(event) => {
          this.handleEscCampaign(event);
        }}
        //onClick={this.handleOutsideClick}
        data-test-id="campaign-popover"
      >
        <Box className={classes.navigationBtns}>
          {this.state.activeStep !== 6 && 
            <FormControl className="steps-select-outer" variant="outlined">
            <Select
              labelId="steps-count"
              id="steps-count"
              value={this.state.stepChangeValue}
              defaultValue="1"
              onChange={(event: any) => this.handleStepChange(event)}
              name="Steps"
              label={this.t(`${configJSON.stepsTxt}`)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                classes: {
                  paper: `${classes.dropdownStyleDeals} modal-dropdown`,
                },
              }}
              data-test-id="stepsChangeSelector"
              IconComponent={ExpandMoreIcon}
            >
              {steps.map((step:any, index:any) => {
                if(step.label !== "Schedule Campaign"){
                  return (
                    <MenuItem
                      key={index + 1}
                      value={index + 1}
                      className="status-item"
                    >
                      {index + 1} of {steps.length - 1}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
          }
          
          <Link
            to="#"
            className="heading-icon"
            onClick={this.openFloatCampaign}
            data-test-id="minimMizeCamgnForm"
          >
            <img src={minimizeGray} alt="minimizeGray" />
          </Link>
          <Link
            to="#"
            className="heading-icon"
            onClick={this.saveCampaignasDraft}
            data-test-id="email-cmpgn-close"
          >
            <img src={closeGray} alt="closeGray" />
          </Link>
        </Box>
        <Box className={classes.stepperMain}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className="stepper-wrapper"
          >
            {headings.map((step:any, index: any) => (
              <Step key={index}>
                <StepLabel>
                  {this.t(`${configJSON.firstStepTxt}`)}
                </StepLabel>
                <StepContent>
                  <Box className="campaign-form-heading">
                    <Box className="heading-left-deals">
                      <Typography className="modal-title" component="h2">
                      {this.t(`${step.label}`)} {index === 2 && <>
                        {this.state.campaignListIds.length > 0 &&   <>{this.state.totalRecipientsCount} {this.t(`${configJSON.recipientsTxt}`)}</>}
                       
                        </>}
                      </Typography>
                      <Box component="p" className="sub-txt">
                        {this.t(`${step.description}`)}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="campaign-description">
                    {this.getRenderedSteps(activeStep)}
                    {this.state.showStepperTimeline && (
                      <>{this.showStepsTimeline(steps, activeStep)}</>
                    )}
                  </Box>
                  {index === 2 && (
                      <Box className="createcampaign-button">
                        <Box className="full-btn step-6">
                          <Button
                            className={`secondary-btn ${classes.secondaryButton}`}
                            onClick={(event:any) => this.openCreateGroup(event)}
                            data-test-id="create-group-modal"
                          >
                            {this.t("Create Group")}
                          </Button>
                          <Button
                            className={`${isCampaignSelected ? classes.primaryButton : 'add-btn-disable'} ${classes.primaryButton}`}
                            onClick={this.handleNext}
                            data-test-id={`continue-step-${index + 1}`}
                            disabled={!isCampaignSelected}
                          >
                            {this.t(`${configJSON.continueTxt}`)}
                          </Button>
                        </Box>
                      </Box>
                  )}
                  {(index == headings.length - 2) && (
                    
                       <Box className="createcampaign-button">
                       <Box className="full-btn step-6">
                       <Button
                            className={`secondary-btn ${classes.secondaryButton}`}
                         
                           onClick={this.handleNext}
                           data-test-id={`schedule-campaign`}
                         >
                           {this.t(`${configJSON.scheduleCampaignTxt}`)}
                         </Button>
                         <Button
                           className={`primary-btn ${classes.primaryButton}`}
                           data-test-id={`send-campaign`}
                           onClick={() =>
                             this.sendEmailCampaign("Send Campaign")
                           }
                         >
                           {this.t(`${configJSON.sendNowTxt}`)}
                         </Button>
                         
                       </Box>
                     </Box>
                  )}
                  {index === headings.length - 1 && (
                    <Box className="createcampaign-button">
                      <Box className="full-btn step-6">
                        <Button
                          className={`secondary-btn ${classes.secondaryButton}`}
                          onClick={
                            this.handleBackButton
                          }
                          data-test-id={`back-button`}
                        >
                          {this.t(`${configJSON.backButtonTxt}`)}
                        </Button>
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          onClick={() =>
                            this.sendEmailCampaign("Schedule Campaign")
                          }
                          data-test-id={`schedule-now`}
                        >
                          {this.t(`${configJSON.scheduleCampaignNow}`)}
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {(index == 0 ||
                    index == 1 ||
                    index == 3 || 
                    index === 4 ) && (
                    <Box className="createcampaign-button">
                      <Box className="full-btn">
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          onClick={this.handleNext}
                          data-test-id={`continue-step-${index + 1}`}
                        >
                          {this.t(`${configJSON.continueTxt}`)}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Popover>
      )
    }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const steps = [
      {
        label: "Campaign Title",
        description: `Set a title for the campaign`,
      },
      {
        label: "From",
        description: "Who is sending this email campaign?",
      },
      {
        label: "To",
        description: `Choose your contact list`,
      },
      {
        label: "Subject",
        description: ` Add a subject line for this campaign.`,
      },
      {
        label: "Design",
        description: `Please choose from template.`,
      },
      {
        label: "Advanced Settings",
        description: `Customize campaign settings.`,
      },
      {
        label: "Schedule Campaign",
        description: `Select when you would like to send your campaign.`,
      },
    ];

    const headings = [
      {
        label: "Create an email campaign",
        description: `Keep subscribers engaged by sharing your latest news, promoting your bestselling products, or announcing an upcoming event.`,
      },
      {
        label: "From",
        description: "Who is sending this email campaign?",
      },
      {
        label: "To",
        description: `Choose your recipients.`,
      },
      {
        label: "Subject",
        description: `Add a subject line for this campaign.`,
      },
      {
        label: "Design",
        description: `Select a design template for this campaign..`,
      },
      {
        label: "Advanced Settings",
        description: `Customize your settings.`,
      },
      {
        label: "Schedule Campaign",
        description: `Select when you would like to send your campaign.`,
      },
    ];

   
    return (
      <>
        {this.state.isLoading && <WebLoader />}
        {this.state.showEmailCampaignForm && 
        <>{this.showEmailCampaignForm(classes, steps, headings)}</>
        }
          
       
        {this.state.floatEmailCampaign && (
          <Box className={classes.btnMinimizeDeals}>
            <CardMedia
              component="img"
              src={expandForm}
              className="expand-icon"
              onClick={this.openCreateEmailCampaignForm}
              data-test-id="maximizeCmpgnForm"
            />
            <Typography className="create-txt">
              {this.state.campaignTitle}
            </Typography>
            <Button className="minimize-close-btn">
              <CardMedia
                component="img"
                src={closeGray}
                className="close-icon"
                onClick={this.props.popoverClose}
                data-test-id="close-email-campaign-float"
              />
            </Button>
          </Box>
        )}

        {this.state.isCreateGroup && (
          <Popover
          open={
            this.state.isCreateGroup &&
            Boolean(this.state.createGroupEl)
          }
          anchorEl={this.state.createGroupEl}
          onClose={this.handleCloseCreateContact}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{ paper: `${classes.createContactBox}` }}
          style={{ bottom: "100px" }}
          data-test-id="create-group-popover"
        >
           <CreateGroup
            classes={classes}
            handleCloseDialog={this.handleCloseCreateContact}
            handleMinimizeCreateGroup={this.openCreateEmailCampaignForm}
            groupName={this.state.listgroupName}
            groupError={this.state.listgroupNameError}
            handleChangeGroupName={this.handleChangeGroupName}
            handleChangeContact={this.handleChangeContact}
            contactId={this.state.listContactId}
            openGroupsContact={this.handleOpenGroupContact}
            createGroup={this.createGroup}
            t={this.t}
            data-test-id="createGroup"
              />
          </Popover>
        )}
        
        {this.state.previewTemplate && (
          <Modal
            className={classes.modalDialog}
            aria-labelledby="modal-modal-title"
            onClose={this.closeFloatEmailCampaign}
            open={this.state.previewTemplate}
            aria-describedby="modal-modal-description"
          >
            <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
              <Box className="modal-heading">
                <Link
                  to="#"
                  className="close-icon"
                  onClick={this.closeFloatEmailCampaign}
                  data-test-id="view-template-close"
                >
                  <CloseIcon />
                </Link>
              </Box>
              <Box className="modal-description-deal">
                <Box className="header-sub-text">
                  <em><Link to="#"><ArrowBack onClick={this.closeFloatEmailCampaign}/></Link></em>
                  <span>FR sommelier mass mailing /</span> Design Preview
                </Box>

                <img
                  src={previewTemplateCampaign}
                  alt="previewTemplateCampaign"
                />
              </Box>
            </Box>
          </Modal>
        )}

         {this.state.showDraftBox && 
            <Modal
            open={this.state.showDraftBox}
            onClose={this.closeCreateCampaignForm}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalDialog}
          >
            <Box className={classes.modalDialogBoxDrafts}>
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                {this.t("Save as Draft")}
                </Typography>
                <Link to="#" className="close-icon" onClick={this.closeCreateCampaignForm}>
                  <CloseOutlinedIcon />
                </Link>
              </Box>
              <Box className="modal-description">
                <Typography className="modal-desc">
                {this.t("Do you want to save your draft?")}
                </Typography>
    
                <Box className="modal-footer">
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    title="Discard"
                    onClick={this.closeCreateCampaignForm}
                    data-test-id="close-draft-modal"
                  >
                   {this.t("Discard")}
                  </Button>
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    title="Save as Draft"
                    onClick={() => this.sendEmailCampaign("Save as Draft")}
                    data-test-id="save-mail-as-draft"
                  >
                   {this.t("Save as Draft")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          }
        <GroupContacts
            classes={classes}
            modalOpen = {this.state.showGroupsContact}
            modalClose={this.handleCloseGroupContact}
            searchContactKeyword={this.state.searchContactKeyword}
            handleSearchContact={this.handleSearchContact}
            contacts={this.state.contactListData}
            isSearching={this.state.isContactSearching}
            isSorting={this.state.isContactSorting}
            handleSortContact={this.handleSortContact}
            searchedContacts={this.state.searchedContacts}
            editGroupEvent={this.editGroupEvent}
            sortedContacts={this.state.sortedContacts}
            subTitle={this.t(`${configJSON.chooseContactInGroup}`)}
            t={this.t}
            data-test-id="groupContacts"
            actionType="create"
            />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(CreateEmailCampaign);
// Customizable Area End

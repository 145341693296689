//Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import { toast } from "react-toastify";
import i18n from "../../../web/src/utilities/i18n";
export const configJSON = require("./config");
export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  labelHeader: string;
  activeStep: number;
  token: any;
  sortValue: any;
  ischeck: boolean;
  selecteditem: "";
  submittedquizList: any[];
  quizList: any[];
  filteredQuizList: any[];
  isFetchingQuizes: boolean;
  searchQuery: string;
  modalOpen: boolean;
  viewQuizLibrary: boolean;
  isCreatingQuiz: boolean;
  createQuiz: {
    quizTitle: string;
    quizCaption: string;
  };
  createQuizError: {
    errorquizTitle: string;
    errorquizCaption: string;
  };
  sliders: any[];
  questionScoreError: boolean;
  savedQuizDetailsId: any;
  quizSubmissionData: any[];
  selectedValues: { [key: number]: number };
  isTakingQuiz: boolean;
  questionsRequestedData: any[];
  quizGrade: string;
  quizTitle: string;
  quizCaption: string;
  isEditing: boolean;
  isExitingQuiz: boolean;
  isRetakingQuiz: boolean;
  isDeletingQuestion: boolean;
  questionDeleteIndex: any;
  isLoading: boolean;
}
export interface SS {
  id: any;
}

export default class EnterningQuizControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuizzesListRequestId: string = "";
  getSubmittedQuizesListAPI: string = "";
  createQuestionsAPICallId: string = "";
  editQuestionsAPICallId: string = "";
  getQuizFromRequistedId: string = "";
  submitQuizFromRequestId: string = "";
  resultQuizFromRequestId: string = "";
  getQuizListAPI: string = "";
  userSessionData: any;
  userToken: any;
  quizDetails: any;
  createdQuiz: any;
  chosequizId: any;
  requestedQuizId: any;
  itemId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];
    this.state = {
      labelHeader: "",
      activeStep: 1,
      sortValue: null,
      token: "",
      ischeck: false,
      selecteditem: "",
      submittedquizList: [],
      quizList: [],
      isFetchingQuizes: true,
      filteredQuizList: [],
      searchQuery: "",
      modalOpen: false,
      viewQuizLibrary: false,
      isCreatingQuiz: false,
      createQuiz: {
        quizTitle: "",
        quizCaption: "",
      },
      createQuizError: {
        errorquizTitle: "",
        errorquizCaption: "",
      },
      sliders: [
        {
          minimumValue: 0,
          maximumValue: 100,
          minimumInput: 0,
          maximumInput: 100,
          isCreateQuestion: false,
          questionText: "",
          questionTextError: "",
          isEditable: true,
          selectedValue: 20,
          marks: [],
        },
      ],
      questionScoreError: false,
      savedQuizDetailsId: "",
      quizSubmissionData: [],
      selectedValues: {},
      isTakingQuiz: false,
      questionsRequestedData: [],
      quizGrade: "",
      quizTitle: "",
      quizCaption: "",
      isEditing: false,
      isDeletingQuestion: false,
      isExitingQuiz: false,
      isRetakingQuiz: false,
      questionDeleteIndex: "",
      isLoading: false,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.quizDetails = localStorage.getItem("quizdetails");
    this.chosequizId = localStorage.getItem("chosequizId");
    this.createdQuiz = JSON.parse(this.quizDetails);
    this.requestedQuizId = JSON.parse(this.chosequizId);
    this.itemId= this.getLastPartOfURL()
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.updateMarks();
    if(this.itemId !== ""){
      this.getQuizApiFromRequestedId(this.itemId)
    }else{
      if (this.requestedQuizId !== null) {
        this.getQuizApiFromRequestedId(this.requestedQuizId);
      }
    }
    
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const { sliders } = this.state;

    if (prevState.sliders !== sliders) {
      let updateRequired = false;

      const updatedSliders = sliders.map((slider, i) => {
        if (slider.questionText !== "" && slider.questionTextError !== "") {
         
          updateRequired = true;
          return {
            ...slider,
            questionTextError: "",
          };
        }
        return slider;
      });

      if (updateRequired) {
        this.setState({ sliders: updatedSliders });
      }
    }
  }
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }

  async receive(from: string, message: Message) {
    this.setState({isLoading: false})
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        // do errorResponse handling here or if there are some errors while calling the API, handle those errors here.
        return;
      }

      // error handling done here

      // Handle Request IDs here

      // for getting quizzes list
      if (apiRequestCallId === this.createQuestionsAPICallId) {
        if (
          responseJson["questions.title"] &&
          responseJson["questions.title"].length > 0
        ) {
          let error = responseJson["questions.title"];
          this.createToastNotificationError(this.t("Question title") + " " + this.t(error[0]));
          return;
        }
        if (responseJson.success === true) {
          this.setState({ savedQuizDetailsId: responseJson.quiz.id });
          this.setState({ isTakingQuiz: true });
          this.getQuizApiFromRequestedId(responseJson.quiz.id);
          this.handleNext();
          this.createToastNotificationSuccess(this.t("Quiz added successfully"));
        }
      } else if (apiRequestCallId === this.editQuestionsAPICallId) {
        if (responseJson.errors) {
          this.createToastNotificationError("Something went wrong");
        } else {
          this.setState({ isEditing: false });
          this.getQuizApiFromRequestedId(responseJson.id);
          this.handleNext();
          this.createToastNotificationSuccess("Quiz edited successfully");
        }
      } else if (apiRequestCallId === this.getQuizFromRequistedId) {
        console.log(responseJson.questions);
       
        this.setState({
          sliders: responseJson.questions.map((sliderData:any) => ({
            ...this.state.sliders,
            minimumValue: sliderData.min_score_value,
            maximumValue: sliderData.max_score_value,
            minimumInput: sliderData.min_score_value,
            maximumInput: sliderData.max_score_value,
            questionText: sliderData.title,
            selectedValue: sliderData.answer,
          })),
        });
       const formatedData = responseJson.questions.map((item:any)=>{
          return({ ...item, answer:item.answer?item.answer : 0 })
        });
        this.setState({
          questionsRequestedData: formatedData
        });
        this.setState({ quizTitle: responseJson.title });
        this.setState({ quizCaption: responseJson.caption });
      } else if (apiRequestCallId === this.submitQuizFromRequestId) {
        if (responseJson.success) {
          if (this.requestedQuizId === null) {
            localStorage.setItem("retakeQuizId", this.state.savedQuizDetailsId);
          } else {
            localStorage.setItem("retakeQuizId", this.requestedQuizId);
          }

          this.resultApi();
        }
        this.handleNext();
      } else if (apiRequestCallId === this.resultQuizFromRequestId) {
        this.setState({ quizGrade: responseJson.grade });
        localStorage.removeItem("chosequizId");
        localStorage.removeItem("quizdetails");
      }
    }
  }

  createToastNotificationError = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  createToastNotificationSuccess = (toastMessage: string) => {
    toast.success(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  isStepCompleted(step: number) {
    return this.state.activeStep > step;
  }
  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  /*Slider functions*/
  handleChange = (index: number, newValue: any) => {
    this.setState((prevState) => {
      const { sliders } = prevState;
      const updatedSliders = sliders.map((slider, i) =>
        i === index ? { ...slider, selectedValue: newValue } : slider
      );
      return { sliders: updatedSliders };
    });
  };

  handleMinimumInputChange = (
    index: number,
    event: { target: { value: string } }
  ) => {
    const inputValue = event.target.value;
    const minValue =  parseInt(inputValue);

    // Prevent negative values
    if (minValue < 0 || minValue > 9999) {
      return;
    }

    this.setState((prevState) => {
      const { sliders } = prevState;
      const updatedSliders = sliders.map((slider, i) =>
        i === index
          ? {
              ...slider,
              minimumInput: minValue,
              minimumValue: minValue,
            }
          : slider
      );
      return { sliders: updatedSliders };
    }, this.updateMarks);
  };

  handleMaximumInputChange = (
    index: number,
    event: { target: { value: string } }
  ) => {
    const inputValue = event.target.value;
    const maxValue = parseInt(inputValue);

    // Prevent negative values
    if (maxValue < 0 || maxValue > 9999) {
      return;
    }
    this.setState((prevState) => {
      const { sliders } = prevState;
      const updatedSliders = sliders.map((slider, i) =>
        i === index
          ? {
              ...slider,
              maximumInput: maxValue,
              maximumValue: maxValue,
            }
          : slider
      );
      return { sliders: updatedSliders };
    }, this.updateMarks);
  };

  handleQuestionChange = (
    index: number,
    event: { target: { value: string } }
  ) => {
    const questionValue = event.target.value;
    this.setState((prevState) => {
      const { sliders } = prevState;
      const updatedSliders = sliders.map((slider, i) =>
        i === index
          ? {
              ...slider,
              questionText: questionValue,
            }
          : slider
      );
      return { sliders: updatedSliders };
    });
  };

  handleBackArrow = () => {
    if (this.state.activeStep === 1) {
      this.setState({isExitingQuiz : true});
      if (
        localStorage.getItem("chosequizId") ||
        localStorage.getItem("quizdetails")
      ) {
        localStorage.removeItem("chosequizId");
        localStorage.removeItem("quizdetails");
      }
     
    } else {
      this.setState({ activeStep: this.state.activeStep - 1 });
    }
  };

  handleExitRedirect = () => {
  this.props.navigation.navigate("EquizDashboardWeb");
  }

  handleEditBtn = () => {
    this.setState({ isEditing: true });
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleEditBackBtn = () => {
    this.setState({ isEditing: false });
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleExitQuiz = () => {
    this.setState({isExitingQuiz : true});

  }

  handleExitQuizClose = () => {
    this.setState({isExitingQuiz : false});
    
  }

  reTakeBtnHandler = () => {
    this.props.navigation.navigate("ReTakeQuizWeb");
  };
  handleDashboardClick = () => {
    this.props.navigation.navigate("EquizDashboardWeb");
    localStorage.removeItem("retakeQuizId");
  };

  handleAddSlider = (index: number) => {
    let errorFlag = false;
    this.state.sliders.forEach((slider) => {
      if (slider.questionText.trim() === "") {
        errorFlag = true;
      }
      if (slider.minimumValue === slider.maximumValue) {
        errorFlag = true;
        this.createToastNotificationError(
          "Best scores and worst scores for question can't be same"
        );
      }
    });

    if (errorFlag) {
      this.setState((prevState) => {
        const { sliders } = prevState;
        const updatedSliders = sliders.map((slider, i) =>
          i === index
            ? {
                ...slider,
                questionTextError: "Title can't be empty",
              }
            : slider
        );
        return { sliders: updatedSliders };
      });
      return false;
      //questionTextError
    } else {
      this.setState((prevState) => {
        const { sliders } = prevState;
        const newSlider = {
          minimumValue: 20,
          maximumValue: 100,
          questionText: "",
          selectedValue: 20,
          minimumInput: 20,
          maximumInput: 100,
          marks: [],
        };
        const updatedSliders = [...sliders, newSlider];
        return { sliders: updatedSliders };
      }, this.updateMarks);
    }
  };

  handleDeleteSlider = () => {
    const {questionDeleteIndex} = this.state;
    
    this.setState((prevState) => {
      const { sliders } = prevState;
      const deleteSlide = sliders.filter((element, i) => i !== questionDeleteIndex);

      return { sliders: deleteSlide };
    }, this.updateMarks);
    this.setState({isDeletingQuestion : false})
    this.createToastNotificationSuccess("Question deleted successfully")
  };

  handleDeleteQuestion = (index: number) => {
    this.setState({isDeletingQuestion : true})
    this.setState({questionDeleteIndex : index})
  }

  closeDeleteQuestion = () => {
    this.setState({isDeletingQuestion : false})
  }

  addQuestion = () => {
    let errorFlag = false;
    this.state.sliders.forEach((slider) => {
      if (slider.minimumValue === slider.maximumValue) {
        errorFlag = true;
        this.createToastNotificationError(
          "Best scores and worst scores for question can't be same"
        );
      }
    });
    if (errorFlag) {
      return;
    }
    const questions_attributes = this.state.sliders.map((slides) => {
      return {
        title: slides.questionText.trim(),
        min_score_value: slides.minimumValue,
        max_score_value: slides.maximumValue,
      };
    });

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      quiz: {
        title: this.createdQuiz.quizTitle,
        caption: this.createdQuiz.quizCaption,
        questions_attributes: questions_attributes,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createQuestionsAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createQuizEndPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    this.setState({isLoading: true})

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editQuestionSbmt = () => {
    let errorFlag = false;
    this.state.sliders.forEach((slider) => {
      if (slider.minimumValue === slider.maximumValue) {
        errorFlag = true;
        this.createToastNotificationError(
          "Best scores and worst scores for question can't be same"
        );
      }
    });
    if (errorFlag) {
      return;
    }
    const question_ids = this.state.questionsRequestedData.map((question) => {
      return {
        id: question.id,
      };
    });

    const questions_attributes = this.state.sliders.map((slides, index) => {
      return {
        ...question_ids[index],
        title: slides.questionText,
        min_score_value: slides.minimumValue,
        max_score_value: slides.maximumValue,
      };
    });

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      quiz: {
        title: this.createdQuiz.quizTitle,
        caption: this.createdQuiz.quizCaption,
        questions_attributes: questions_attributes,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editQuestionsAPICallId = requestMessage.messageId;

    let endPoint =
      configJSON.createQuizEndPoint + "/" + this.state.savedQuizDetailsId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
      this.setState({isLoading: true})
    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateMarks() {
    const { sliders } = this.state;
    const updatedSliders = sliders.map((slider) => {
      const { minimumValue, maximumValue } = slider;
      const interval = (maximumValue - minimumValue) / 4;
      if (minimumValue == maximumValue) {
        this.setState({ questionScoreError: true });
        return { ...slider };
      } 
      else {
        this.setState({ questionScoreError: false });
        const marks = Array.from({ length: 5 }, (_, i) => ({
          value: minimumValue + i * interval,
          label: (
            <>
              <span>{String.fromCharCode(69 - i)}</span>{" "}
              <span>{minimumValue + i * interval}</span>
            </>
          ),
        }));
        if (minimumValue > maximumValue) {
          console.log("in if");
          marks.reverse(); 
          marks.forEach((mark: any, index) => {
            mark.label = String.fromCharCode(69 - index) + ': ' + mark.value;
          });
        }
        return { ...slider, marks };
      }
    });
    this.setState({ sliders: updatedSliders });
  }

  getQuizApiFromRequestedId = (id: number) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.createQuizEndPoint + "/" + id;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuizFromRequistedId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    // console.log("Request Message", requestMessage)
    this.setState({isLoading: true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveSlideAnswer = (index: number, newValue: any) => {
    const updatedAnswer = [...this.state.questionsRequestedData];
    updatedAnswer[index].answer = newValue;
    this.setState({ questionsRequestedData: updatedAnswer });
  };

  handleQuizSubmission = () => {
    const quizSubmittedData = this.state.questionsRequestedData;

    const submissions = quizSubmittedData.map((item) => {
      return {
        ...item,
        question_id: item.id,
      };
    });
    
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      submissions: submissions,
    };

    let endPoint = configJSON.submitQuizEndPoint;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitQuizFromRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    // console.log("Request Message", requestMessage)
    this.setState({isLoading: true})
    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resultApi = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    let submittedQuizId = "";
    if (this.requestedQuizId === null) {
      submittedQuizId = this.state.savedQuizDetailsId;
    } else {
      submittedQuizId = this.requestedQuizId;
    }
    let endPoint =
      configJSON.quizResultEndPoint + "?quiz_id=" + submittedQuizId;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resultQuizFromRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Get"
    );

    // console.log("Request Message", requestMessage)

    //* Making Network Request
    this.setState({isLoading: true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getLastPartOfURL = () => {
    let url = window.location.pathname;
    let parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    if(isNaN(Number(lastPart))){
      return ""
    }
    return lastPart;
  };
}
//Customizable Area End

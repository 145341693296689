import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import {sendOutline, saveDraft, multipleAccounts} from "./assets"
import { DateObject } from "react-multi-date-picker";
import moment from "moment";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  popoveAnchorEl:any;
  popoverClose:() => void;
  getAllCampaingsList:() => void;
  isReusingCampaign?:boolean;
  campaignIdToReuse?:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isMenuDrawerOpened: boolean;
  anchorEl: any;
  popoveAnchorEl: any;
  createCampaignEl: any;
  createEmailCampaignForm: boolean;
  activeStep: number;
  floatEmailCampaign: boolean;
  stepChangeValue: number;
  gAEnabled: boolean;
  EmbedImagesEnable: boolean;
  attachmentEnable: boolean;
  mirrorLinkEnable: boolean;
  isContentVisible: boolean;
  campaignScheduledate: any;
  campaignScheduleTime: any;
  campaignTitle: string;
  fromEmailAddress: string;
  campaignSubjectTxt: string;
  campaignPreviewTxt: string;
  campaignErrors: {
    campaignTitle: string;
    fromEmailError: string;
    fromNameError: string;
    subjectTextError: string;
    previewTextError: string;

  };
  scheduleDateError: string;
  scheduleTimeError: string;
  sentCampaignList: any;
  isLoading: boolean;
  openViewCampaignModal: boolean;
  emailCampaignType: string;
  showStepperTimeline: boolean;
  previewTemplate: boolean;
  showTimePicker: boolean;
  hours: string;
  minutes: string;
  seconds: string;
  sortCampgnVal: string;
  isSorting: boolean;
  brevoContactLists: any;
  campaignListIds: any;
  emailTemplatesList: any;
  campaignId: string;
  campaignListCount: string;
  utmTrackingValue: string;
  fromName: string;
  sendersList: any[];
  searchListValue: string;
  isSearchLists:boolean;
  sortListDirection: string;
  isSortingLists: boolean;
  campaignTemplateId: string;
  totalRecipientsCount: number;
  attachmentsUploaded: any;
  isScheduling: boolean;
  showEmailCampaignForm: boolean;
  isCreateGroup: boolean;
  listgroupName: string;
  listgroupNameError: string;
  listContactId: string;
  showGroupsContact: boolean;
  stepperAnchorEl:any;
  showDraftBox: boolean;
  createGroupEl: any;
  searchContactKeyword: string;
  isContactSearching: boolean;
  searchedContacts:any;
  isContactSorting:boolean;
  sortContactValue: string;
  sortedContacts:any;
  contactListData: any;
  campaignGroupIds: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateEmailCampaignController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCampaignsRequestId: string = "";
  createEmailCampaignRequestId: string = "";
  getContactListsRequestId: string = "";
  validateBrevoAPIRequestId: string = "";
  getEmailTemplateListAPIRequestId: string = "";
  getSenderListAPIRequestId: string = "";
  viewReuseCampaignAPIId:string = "";
  createListsApiCallId:string = "";
  getContactlistApiID: string = "";
  popoverRef: React.RefObject<any>;
  userSessionData: any;
  userToken: any;
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.popoverRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isMenuDrawerOpened: false,
      anchorEl: null,
      createCampaignEl: false,
      popoveAnchorEl: null,
      createEmailCampaignForm: false,
      activeStep: 0,
      floatEmailCampaign: false,
      stepChangeValue: 1,
      gAEnabled: false,
      EmbedImagesEnable: false,
      attachmentEnable: false,
      mirrorLinkEnable: false,
      isContentVisible: false,
      campaignScheduledate: "",
      campaignScheduleTime: "",
      campaignTitle: "",
      fromEmailAddress: "",
      campaignSubjectTxt: "",
      campaignPreviewTxt: "",
      campaignErrors: {
        campaignTitle: "",
        fromEmailError: "",
        fromNameError: "",
        subjectTextError: "",
        previewTextError: "",
      },
      scheduleDateError: "",
      scheduleTimeError: "",
      sentCampaignList: [],
      campaignListCount: "",
      isLoading: false,
      openViewCampaignModal: false,
      emailCampaignType: "sent",
      showStepperTimeline: false,
      previewTemplate: false,
      showTimePicker: false,
      hours: "HH",
      minutes: "MM",
      seconds: "SS",
      sortCampgnVal: "",
      isSorting: false,
      brevoContactLists: [],
      
      campaignListIds: [],
      emailTemplatesList: [],
      campaignId: "",
      utmTrackingValue: "",
      fromName: "",
      sendersList: [],
      searchListValue: "",
      isSearchLists: false,
      sortListDirection: "",
      isSortingLists: false,
      campaignTemplateId: '',
      totalRecipientsCount: 0,
      attachmentsUploaded: [],
      isScheduling: false,
      showEmailCampaignForm: true,
      isCreateGroup: false,
      listgroupName: "",
      listgroupNameError: "",
      listContactId: "",
      showGroupsContact: false,
      stepperAnchorEl:null,
      showDraftBox: false,
      createGroupEl: null,
      searchContactKeyword: "",
      isContactSearching: false,
      searchedContacts:[],
      isContactSorting:false,
      sortContactValue: "",
      sortedContacts:[],
      contactListData: [],
      campaignGroupIds: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.fileRef = React.createRef();
    this.formRef = React.createRef();

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        return;
      }
      switch (apiRequestCallId) {
        case this.createEmailCampaignRequestId:
          this.setState({ isLoading: false });
          if (responseJson.error) {
            this.createToastNotificationError(responseJson.error);
          } else {
            this.setState({
              campaignTitle: "",
              fromEmailAddress: "",
              campaignListIds: "",
              campaignSubjectTxt: "",
              utmTrackingValue: "",
              fromName: "",
              campaignScheduledate: "",
              campaignScheduleTime: "",
              campaignPreviewTxt: "",
              scheduleDateError: "",
              scheduleTimeError: "",
              activeStep:0,
              stepChangeValue:1,
              campaignGroupIds: "",
            });
            if(this.state.showDraftBox){
              this.createToastNotificationSuccess("Draft Saved", saveDraft);
            }else{
              this.createToastNotificationSuccess("Email Campaign sent", sendOutline);
            }
            this.props.getAllCampaingsList();
            this.closeCreateCampaignForm();
          }

          break;
        case this.getContactListsRequestId:
          this.setState({ brevoContactLists: responseJson.data.lists });
          this.setState({ isLoading: false });
          break;
        
        case this.getEmailTemplateListAPIRequestId:
          this.handleTemplateResponse(responseJson)
          break;
        case this.getSenderListAPIRequestId:
          this.setState({ sendersList: responseJson.data.senders });
          break;
        case this.viewReuseCampaignAPIId:
          this.handleReuseCampaign(responseJson.data);
          break;
        case this.createListsApiCallId:
          this.setState({
            isLoading : false,
          })
          this.createToastNotificationSuccess("Group Created",multipleAccounts)
          this.handleCloseCreateContact()
          localStorage.removeItem("contactIds")
          break;
        case this.getContactlistApiID:
          this.setState({contactListData:responseJson.data});
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const langT = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(langT);
    this.getAllListsfromBrevo();
    this.getEmailTemplateLists();
    this.getSendersList();
    this.getContactlist();
    if(this.props.isReusingCampaign){
     this.fetchDetailsofCampaign()
    }
    this.handleStorageData()
  }

  handleStorageData = async() => {
    const isMinimized = await getStorageData('campaignForm')
    const parsedData = JSON.parse(isMinimized)
    this.setState({ floatEmailCampaign: parsedData.minimizeCampaignForm })
    if(parsedData.minimizeCampaignForm) {
      this.setState({ showEmailCampaignForm: false })
    }
    const campaignData = await getStorageData("campaignFormData")
    const parsedFormData = JSON.parse(campaignData)
    if(isMinimized) {
      this.setState({ activeStep: parsedFormData.campaignNextStep, stepChangeValue: parsedFormData.campaignStepCount })
      if(parsedFormData.campaignTitle){
        this.handleCampaignFormInputs("campaignTitle", parsedFormData.campaignTitle)
      }
      if(parsedFormData.campaignFromEmail) {
        this.handleSenderEmailValue(parsedFormData.campaignFromEmail)
        this.setState({ fromName: parsedFormData.campaignFromEmailName })
      }
      if(parsedFormData.campaignGroupsSelected) {
        this.setState({ campaignListIds: parsedFormData.campaignGroupsSelected, totalRecipientsCount: parsedFormData.campaignGroupCount })
      }
      if(parsedFormData.campaignSubject) {
        this.handleCampaignFormInputs("campaignSubjectTxt", parsedFormData.campaignSubject)
        this.handleCampaignFormInputs("campaignPreviewTxt", parsedFormData.campaignPreview)
      }
      if(parsedFormData.campaignSelectedTemplate) {
        this.setState({ campaignTemplateId: parsedFormData.campaignSelectedTemplate })
      }
      if(parsedFormData.campaignGaEnabled) {
        this.handleAdvancedCampaignSettings({target: {name: "GoogleAnalytics", checked: parsedFormData.campaignGaEnabled}} as unknown as React.ChangeEvent<HTMLInputElement>)
        this.handleCampaignFormInputs("utmTrackingValue", parsedFormData.campaignUTMTrack)
      }
      if(parsedFormData.campaignImageEnable) {
        this.handleAdvancedCampaignSettings({target: {name: "EmbedImages", checked: parsedFormData.campaignImageEnable}} as unknown as React.ChangeEvent<HTMLInputElement>)
      }
      if(parsedFormData.campaignAttchEnable) {
        this.handleAdvancedCampaignSettings({target: {name: "addAttachment", checked: parsedFormData.campaignAttchEnable}} as unknown as React.ChangeEvent<HTMLInputElement>)
        this.handleOnDropAttachments(JSON.parse(parsedFormData.campaignAttachment))
      }
      if(parsedFormData.campaignMirrorEnable) {
        this.handleAdvancedCampaignSettings({target: {name: "mirrolLink", checked: parsedFormData.campaignMirrorEnable}} as unknown as React.ChangeEvent<HTMLInputElement>)
      }
      if(parsedFormData.campaignScheduleDate) {
        this.setState({ campaignScheduledate: parsedFormData.campaignScheduleDate })
      }
      if(parsedFormData.campaignHours) {
        this.setState({ hours: parsedFormData.campaignHours, minutes: parsedFormData.campaignMinutes, seconds: parsedFormData.campaignSeconds }, () => this.setHours())
      }
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.campaignTitle !== this.state.campaignTitle) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          campaignTitle: "",
        },
      });
    }
    if (prevState.fromEmailAddress !== this.state.fromEmailAddress) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          fromEmailError: "",
        },
      });
    }
    if (prevState.fromName !== this.state.fromName) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          fromNameError: "",
        },
      });
    }
    if (prevState.campaignSubjectTxt !== this.state.campaignSubjectTxt) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          subjectTextError: "",
        },
      });
    }
    if (prevState.campaignPreviewTxt !== this.state.campaignPreviewTxt) {
      this.setState({
        campaignErrors: {
          ...this.state.campaignErrors,
          previewTextError: "",
        },
      });
    }
  }
  t(key: any, variable?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variable });
  }

  handleReuseCampaign = (responseJson:any) => {
   let passedBrevoGroupIds = responseJson.recipients.map((recipient:any) => Number(recipient));
   this.getRecipientsCount(passedBrevoGroupIds);
    if(responseJson.name) {
      this.setState({ 
        campaignTitle: responseJson.name, 
        stepChangeValue: this.state.stepChangeValue + 1, 
        activeStep: this.state.activeStep + 1, 
        showStepperTimeline: true 
      })
    } else {
      return;
    }
    if(responseJson.sender.email) {
      this.setState({ 
        fromEmailAddress: responseJson.sender.email, 
        fromName: responseJson.sender.name, 
        stepChangeValue: this.state.stepChangeValue + 1, 
        activeStep: this.state.activeStep + 1,
        showStepperTimeline: true
      })
    } else {
      return;
    }
    if(responseJson.recipients.map((recipient:any) => Number(recipient))) {
      this.setState({ 
        campaignListIds: responseJson.recipients.map((recipient:any) => Number(recipient)), 
        stepChangeValue: this.state.stepChangeValue + 1, 
        activeStep: this.state.activeStep + 1,
        showStepperTimeline: true
       })
    } else {
      return;
    }
    if(responseJson.subject) {
      this.setState({ 
        campaignSubjectTxt: responseJson.subject, 
        stepChangeValue: 6, 
        activeStep: 5,
        showStepperTimeline: true 
      })
    } else {
      return;
    }
    if(responseJson.scheduled_at) {
      const scheduledAt = responseJson.scheduled_at;
      const dateObj2 = new Date(scheduledAt);
      const dateObj1 = moment(scheduledAt).format("YYYY-MM-DD");
      const time = moment(dateObj2).format("HH:mm:ss")

      this.setState({
        campaignScheduledate: dateObj1,
        campaignScheduleTime: time,
      })
    } else {
      return;
    }
  }
  getRecipientsCount = (passedBrevoGroupIds:any) => {
    const {brevoContactLists} = this.state;
    const totalContactsCount = brevoContactLists
    .filter((group:any) => passedBrevoGroupIds.includes(group.brevo_group_id))
    .reduce((sum:any, group:any) => sum + group.contacts_count, 0);
    this.setState({
      totalRecipientsCount : totalContactsCount
    })
  }
  previewCampaignTemplate = () => {
    this.setState({ previewTemplate: true });
  };
  useEmailCampaign = (templateId:string) => {
    this.setState({
      campaignTemplateId : templateId
    })
  }
  hoursChange = (event: any) => {
    let newValue = event.target.value;
    // Validate input: Allow only numbers and limit the value to 0-999
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 24) {
      newValue = this.state.hours;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ hours: newValue });
  };
  minutesChange = (event: any) => {
    let newValue = event.target.value;
    // Validate input: Allow only numbers and limit the value to 0-999
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 60) {
      newValue = this.state.minutes;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ minutes: newValue });
  };
  secondsChange = (event: any) => {
    let newValue = event.target.value;
    // Validate input: Allow only numbers and limit the value to 0-999
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 60) {
      newValue = this.state.seconds;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ seconds: newValue });
  };
  setHours = () => {
    let totalHours = "";
    if (
      this.state.hours !== "" ||
      this.state.minutes !== "" ||
      this.state.seconds !== ""
    ) {
      totalHours = this.state.hours == "" ? "00" : this.state.hours;
      totalHours += ":";
      totalHours += this.state.minutes == "" ? "00" : this.state.minutes;
      totalHours += ":";
      totalHours += this.state.seconds == "" ? "00" : this.state.seconds;
    }

    this.setState({
      campaignScheduleTime: totalHours,
      showTimePicker: false,
    });
  };
  

  handleNext = () => {
    const { campaignErrors, campaignTitle, fromName, campaignSubjectTxt, campaignPreviewTxt, activeStep, stepChangeValue } = this.state;
  
    let errorFlag = false;
    let titleError = "";
    let emailError = "";
    let nameError = "";
    let subjectTextError = "";
    let previewTextError = "";
    switch (activeStep) {
      case 0:
        if (campaignTitle.trim() === "") {
          titleError = this.t("Please enter campaign title");
          errorFlag = true;
        }
        if (campaignTitle.length > 128) {
          titleError = this.t("Title cannot be more than 128 characters");
          errorFlag = true;
        }
        break;
  
      case 1:
        if (fromName.trim() === "") {
          nameError = this.t("Please enter sender name");
          errorFlag = true;
        }
        break;
  
      case 3:
        if (campaignSubjectTxt.trim() === "") {
          subjectTextError = this.t("Please enter Subject line");
          errorFlag = true;
        }
        if (campaignSubjectTxt.length > 200) {
          subjectTextError = this.t("Subject line cannot be more than 200 characters");
          errorFlag = true;
        }
        if (campaignPreviewTxt.length > 200) {
          previewTextError = this.t("Preview text cannot be more than 200 characters");
          errorFlag = true;
        }
        break;
      default:
        break;
    }
    if (errorFlag) {
      this.setState({
        campaignErrors: {
          ...campaignErrors,
          campaignTitle: titleError,
          fromEmailError: emailError,
          fromNameError: nameError,
          subjectTextError: subjectTextError,
          previewTextError: previewTextError,
        },
      });
      return true;
    }
  
    this.setState({
      activeStep: activeStep + 1,
      stepChangeValue: stepChangeValue + 1,
    });
  };
  
  

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  openFloatCampaign = () => {
    const attachmentsAdded = JSON.stringify(this.state.attachmentsUploaded.map((file: any) => {
      return {
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
        file: file.type,
        webkitRelativePath: file.webkitRelativePath,
      };
    }))
    setStorageData('campaignForm', JSON.stringify({ minimizeCampaignForm: true }))
    setStorageData("campaignFormData", JSON.stringify({ 
      campaignTitle: this.state.campaignTitle,
      campaignNextStep: this.state.activeStep,
      campaignStepCount: this.state.stepChangeValue,
      campaignFromEmail: this.state.fromEmailAddress,
      campaignFromEmailName: this.state.fromName,
      campaignGroupsSelected: this.state.campaignListIds,
      campaignGroupCount: this.state.totalRecipientsCount,
      campaignSubject: this.state.campaignSubjectTxt,
      campaignPreview: this.state.campaignPreviewTxt,
      campaignSelectedTemplate: this.state.campaignTemplateId,
      campaignGaEnabled: this.state.gAEnabled,
      campaignUTMTrack: this.state.utmTrackingValue,
      campaignImageEnable: this.state.EmbedImagesEnable,
      campaignAttchEnable: this.state.attachmentEnable,
      campaignAttachment: attachmentsAdded,
      campaignMirrorEnable: this.state.mirrorLinkEnable,
      campaignScheduleDate: this.state.campaignScheduledate,
      campaignHours: this.state.hours,
      campaignMinutes: this.state.minutes,
      campaignSeconds: this.state.seconds,
     }))
    this.setState({ floatEmailCampaign: true, showEmailCampaignForm: false });
  };
  openCreateEmailCampaignForm = () => {
    removeStorageData('campaignForm')
    this.setState({ showEmailCampaignForm: true, floatEmailCampaign: false });
  };
  handleEscCampaign = (event: any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.openFloatCampaign();
    }
  }
  closeFloatEmailCampaign = () => {
    removeStorageData('campaignForm')
    this.setState({ floatEmailCampaign: false });
  };
  handleStepChange = (event:any) => {
    this.setState((prevState) => ({
      showStepperTimeline: !prevState.showStepperTimeline,
    }));
    this.setState({ stepChangeValue: event.target.value });
    this.setState({ activeStep: event.target.value - 1 });
    this.setState({
      stepperAnchorEl: event.currentTarget,
    })
  };
  handleAdvancedCampaignSettings = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.name;
    const checked = event.target.checked;
    switch (name) {
      case "GoogleAnalytics":
        this.setState({ gAEnabled: checked });
        break;
      case "EmbedImages":
        this.setState({ EmbedImagesEnable: checked });
        break;
      case "addAttachment":
        this.setState({ attachmentEnable: checked });
        break;
      case "mirrolLink":
        this.setState({ mirrorLinkEnable: checked });
        break;
    }
  };
  scheduleCampaignDate = (date: any) => {
    this.setState({
      campaignScheduledate: date?.isValid ? date.format("YYYY-MM-DD") : "",
    });
  };

  showTimePickerModal = (time: any) => {
    this.setState({
      showTimePicker: true,
    });
  };
  closeTimePickerModal = (time: any) => {
    this.setState({
      showTimePicker: false,
    });
  };



  handleCampaignFormInputs = (name: any, value: string) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleSenderEmailValue = (value: any) => {
    if (value != undefined) {
      this.setState({ fromEmailAddress: value }, () =>
        this.preFillSenderName()
      );
    }
  };
  preFillSenderName = () => {
    const { sendersList, fromEmailAddress } = this.state;
    const senderNAme = sendersList.find(
      (senderNAme) => senderNAme.email === fromEmailAddress
    );

    this.setState({
      fromName: senderNAme?.name,
    });
  };
  

 

  
  
  handleBackButton = () => {
    const {activeStep, stepChangeValue} = this.state;
      this.setState({
        activeStep: activeStep - 1,
        stepChangeValue: stepChangeValue - 1,
      });
  }
  
  sendEmailCampaign = (campaignType: string) => {
    removeStorageData("campaignFormData")
    removeStorageData("campaignId")
    const {
      campaignTitle,
      fromEmailAddress,
      campaignListIds,
      campaignSubjectTxt,
      utmTrackingValue,
      fromName,
      campaignScheduledate,
      campaignScheduleTime,
      campaignTemplateId,
      attachmentsUploaded,
      campaignGroupIds
    } = this.state;
    
    let errorFlag = false;
    let scheduleDateError = this.state.scheduleDateError;
    let scheduleTimeError =  this.state.scheduleTimeError;
    const header = {
      token: this.userToken.meta.token,
    };
    const formData = new FormData();
    const timezoneOffset = "+05:30";
    const scheduledAt = `${campaignScheduledate}T${campaignScheduleTime}${timezoneOffset}`;
    const currentDateTime = new Date();
    const scheduledDateTime = new Date(scheduledAt);
    if (campaignType === "Schedule Campaign") {
      if(campaignScheduledate.trim() === ""){
        scheduleDateError = "Please select the date";
        errorFlag = true;
      }
      if(campaignScheduleTime.trim() === ""){
        scheduleTimeError = "Please choose time";
        errorFlag = true;
      }
      if (scheduledDateTime < currentDateTime) {
        scheduleTimeError = "Time cannot be in the past";
        errorFlag = true;
    }
      
      if (errorFlag) {
        this.setState({
          scheduleDateError: scheduleDateError,
          scheduleTimeError: scheduleTimeError,
        });
        return true;
      }

    }
        formData.append("campaign[name]", campaignTitle);
        formData.append("campaign[subject]", campaignSubjectTxt);
        formData.append("campaign[sender][name]", fromName);
        formData.append("campaign[sender][email]", fromEmailAddress);
        formData.append("campaign[template_id]", campaignTemplateId);
        if(campaignListIds.length > 0){
          campaignListIds.forEach((selectedId: any) => {
        formData.append("campaign[recipients][list_ids][]", selectedId);
          })
        }
        if(campaignGroupIds.length > 0){
          campaignGroupIds.forEach((selectedId: any) => {
            formData.append("group_id[]", selectedId);
          })
        }
        formData.append("campaign[inline_image_activation]", "false");
        formData.append("campaign[mirror_active]", "false");
        formData.append("campaign[brevo_email_type]", "classic");
        formData.append("campaign[utm_campaign]", utmTrackingValue);
        if (attachmentsUploaded.length > 0) {
          attachmentsUploaded.forEach((file:any) => {
            formData.append("campaign[mail_attachments][]", file, file.name);
          });
        }
        if (campaignType === "Schedule Campaign") {
          const timezoneOffset = "+05:30";
          const scheduledAt = `${campaignScheduledate}T${campaignScheduleTime}${timezoneOffset}`;
          
          formData.append("campaign[status]", "queued");
          formData.append("campaign[scheduled_at]", scheduledAt);
        }  else if(campaignType === "Save as Draft") {
          formData.append("campaign[status]", "draft");
        }else {
          formData.append("campaign[status]", "sent");
        }

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createEmailCampaignRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEmailCampaign
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllListsfromBrevo = () => {
    const {isSearchLists,searchListValue,isSortingLists,sortListDirection} = this.state;
    let endPoint;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    let baseURL =  configJSON.getContactLists;
    if(isSearchLists){
      endPoint = baseURL + `?search_key=${searchListValue}`
    } else if(isSortingLists && sortListDirection !=="") {
      endPoint = baseURL + `?sort=name&sort_direction=${sortListDirection}`
    }else{
      endPoint = baseURL
    }
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactListsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  
  createToastNotificationSuccess = (toastMessage: string, toastIcon?:string) => {
    toast.success(
      <div className="toast-notification">
      <div className="notification-txt">{toastMessage}</div>    
    </div>,
    {
      position: toast.POSITION.TOP_CENTER,
      icon: toastIcon ? () => <img src={toastIcon} alt="icon" /> : undefined,
     
    }

    );
  };
  createToastNotificationError = (toastMessage: string) => {
    toast.error(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
 

  handleListsChange = (listId: string, groupsId: number, recipientCount: number) => {
    this.setState((prevState) => {
      let updatedCampaignListIds = [...prevState.campaignListIds];
      let updatedCampaignGroupIds = [...prevState.campaignGroupIds];
      let newTotalRecipientsCount = prevState.totalRecipientsCount;
      
      const index = updatedCampaignListIds.indexOf(listId);
      
      if (index === -1) {
        updatedCampaignListIds.push(listId);
        updatedCampaignGroupIds.push(groupsId);
        newTotalRecipientsCount += recipientCount;
      } else {
        updatedCampaignListIds.splice(index, 1);
        updatedCampaignGroupIds.splice(index, 1);
        newTotalRecipientsCount -= recipientCount;
      }
  
      return {
        campaignListIds: updatedCampaignListIds,
        totalRecipientsCount: newTotalRecipientsCount,
        campaignGroupIds: updatedCampaignGroupIds,
      };
    });
  };
  
  
  getEmailTemplateLists = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmailTemplateListAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTemplates
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSendersList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSenderListAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSenderList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getSearchListValue = (searchValue:string) => {
    this.setState({isSearchLists : true, searchListValue : searchValue}, () => this.getAllListsfromBrevo())
  }
  handleSortByLists = (value:any) => {
    if(value === "A-Z"){
      this.setState({
        sortListDirection : 'asc'
      })
    }else if(value === "Z-A"){
      this.setState({
        sortListDirection : 'desc'
      })
    }else {
      this.setState({
        sortListDirection : ''
      })
    }
    this.setState({isSortingLists: true}, () => this.getAllListsfromBrevo())
  }
  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  }
  handleOnDropAttachments = (fileUploaded: any) => { 
    let fileObject:File[] = [];
    if(fileUploaded != null){
      if (fileUploaded instanceof FileList || Array.isArray(fileUploaded) && fileUploaded[0] instanceof File) {
        fileObject = Array.from(fileUploaded);
      } else if (Array.isArray(fileUploaded)) {
        fileObject = fileUploaded.map((file) => 
          new File([new Blob()], file.name, { type: file.file })
      );
      } else {
        return;
      }
      this.setState({attachmentsUploaded: [...this.state.attachmentsUploaded,...Object.values(fileObject)]});
    }
  };
  removeAttachment = (index:any) => {
    const updatedArray = [...this.state.attachmentsUploaded.slice(0, index), ...this.state.attachmentsUploaded.slice(index + 1)];
    this.setState({ attachmentsUploaded: updatedArray });
  }
  handleTemplateResponse = (responseJson:any) => {
    if(responseJson.data?.templates.length > 0){
      this.setState({ emailTemplatesList: responseJson.data.templates });
      }
  }
  openCreateGroup = (event:any) => {
    this.setState({
      isCreateGroup: true,
      createGroupEl: event.currentTarget,
    })
  }
  handleChangeGroupName = (text: string) => {
    this.setState({ listgroupName: text });
  };

  handleChangeContact = (listContactId: string) => {
    this.setState({ listContactId });
  };
  handleOpenGroupContact = () => {
    this.setState({ showGroupsContact: true });
  };
  handleCloseGroupContact = () => {
    this.setState({ showGroupsContact: false });
  }

  handleSearchContact = (text: string) => {
    this.setState({ searchContactKeyword: text, isContactSearching:true  },()=>{
      //this.getContactSearchtList()
    });
  };
  handleSortContact= (text: string) => {
    this.setState({ sortContactValue: text, isContactSorting:true }, () => {
      //this.getSortContact(); 
    });
  };
  editGroupEvent = () => {
    console.log("edit")
  }
  createGroup = () => {
    const {listgroupName} = this.state;
    let error = this.state.listgroupNameError;
    let hasError = false;
    if (this.state.listgroupName.trim() === "") {
      error = this.t(`${configJSON.groupNameEmptyErrorMsg}`); 
      this.setState({ listgroupNameError: error });
      hasError = true;
    }

    if (this.state.listgroupName.length > 50) {
      error = this.t(`${configJSON.errorMessageChar}`);
      this.setState({ listgroupNameError: error });
      hasError = true;
    }

    if (hasError) {
      return;
    }
   const retrievedArray = JSON.parse(localStorage.getItem("contactIds") ?? '[]');

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      group :{
        name:listgroupName,
        contact_ids: retrievedArray,
   }
    }
    let endPoint = configJSON.viewGroupPath;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createListsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    this.setState({
      isLoading : true,
    })

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCloseCreateContact = () => {
    this.setState({ listgroupName:"", });
    this.setState({
      isCreateGroup: false,
      createGroupEl: null,
    })
    
  };  handleName = (fromName:string) => {
    this.setState({fromName : fromName})
  }
  closeCreateCampaignForm = () => {
    removeStorageData("campaignFormData")
    removeStorageData("campaignId")
    this.props.popoverClose();
    this.setState({  showDraftBox: false });
    this.setState({
      campaignTitle : "",
      fromEmailAddress: "",
      campaignSubjectTxt: "",
      campaignPreviewTxt: "",
      campaignScheduledate: "",
      campaignScheduleTime: "",
      fromName: "",
      campaignListIds: [],
      campaignTemplateId: "",
      activeStep: 0,
      stepChangeValue:1,
      campaignGroupIds: [],
    })
  }
  closeStepperBox = () => {

      this.setState({
        showStepperTimeline : false,
      })
      
    
  }
  saveCampaignasDraft = () => {
    this.setState({  showDraftBox: true });
  }
  fetchDetailsofCampaign = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
   
   
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewReuseCampaignAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecificMail + `?id=${this.props.campaignIdToReuse}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getContactlist = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = `${configJSON.contactsPath}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactlistApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleExploreShopBtn = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CfEtohShop");
    message.addData(getName(MessageEnum.NavigationPropsMessage), {navigation: this.props.navigation});
   this.send(message);
  }
  visitBrevoBtn = () => {
   window.open("https://www.brevo.com/", "_blank", "noopener");
 }
  // Customizable Area End
}

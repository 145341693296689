import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import {
  SubscribedUser,
  ChatMessagesResponse,
  Chat,
  UserToken,
  GroupedMessages,
  chatRoomData,
  Type,
  LiveChatEtohData,
  ExternalChat,
} from "../../../components/src/ReusableEnums";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  liveChatId: string;
  // Customizable Area Start
  classes: Record<string, string>;
  isMobile: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDrawerOpened: boolean;
  openNewChat: boolean;
  isSortModalOpen: boolean;
  deleteMessage: string;
  deleteMessageId: string;
  isdeleteModalopen:boolean;
  isLoading: boolean;
  chatTxtMessage: string;
  fileDrop: boolean;
  chatRoomList: Array<Chat>;
  chatRoomAllList: Array<Chat>;
  allSinglePersonChat: Array<ChatMessagesResponse>;
  messageId: string;
  openExternalChat: boolean;
  email: string;
  error: {
    email: string;
  };
  filesUploaded: File[] | null;
  previews: string[];
  hasNextPage: boolean;
  page: number;
  subscribedUserData: Array<SubscribedUser>;
  userSortBy: string;
  userSearch: string;
  chatRoomSortBy: string;
  chatRoomSearch: string;
  groupedMessages: Array<GroupedMessages>;
  openEmailvarification: boolean;
  totalRecords:number;
  itemData:Chat;
  currentUserId: number; 
  open: boolean;
  message: string;
  selectedImage: string; 
  selectedMediaType:string;
  selectedDocument:string;
  electedDocument:string;
  projectDetail:[];
  teamDetail:[];
  projectDetailName:string;
  projectDetailSearchVal:string;
  teamDetailName:string;
  teamDetailSearchVal:string;
  attachMedia: boolean;
  fullScreenImgURL:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class LiveChat2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  userSessionData: string | null;
  userToken: UserToken;
  createNewChatRequestId: string = "";
  getAllChatRoomsRequestId: string = "";
  getAllMessageRequestId: string = "";
  createExternalChat: string = "";
  formRef: React.RefObject<HTMLFormElement>;
  fileRef: React.RefObject<HTMLInputElement>;
  totalPage: number = 0;
  getAllUsersRequestId: string = "";
  deleteMessageApiCallId: string = "";
  createExternalInternalChatRequestId: string = "";
  markAsReadAllRequestId: string = "";
  createExternalInternalMessageeRquestId: string = "";
  etohSupportLiveChatRequestId:string="";
  LiveChatEndRef: React.RefObject<HTMLDivElement>;
  inviteMemberRequestId:string="";
  getAllChatRoomsRequestID: string="";
  fetchProjectDetailsRequestId: string="";
  fetchTeamDetailsRequestId: string="";
  applyAllFilterDetailsRequestId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isDrawerOpened: false,
      openNewChat: false,
      isSortModalOpen: false,
      fileDrop: false,
      deleteMessage: "",
      deleteMessageId: "",
      isdeleteModalopen: false,
      isLoading: false,
      chatTxtMessage: "",
      chatRoomList: [],
      chatRoomAllList:[],
      allSinglePersonChat: [],
      messageId: "",
      openExternalChat: false,
      email: "",
      error: {
        email: "",
      },
      filesUploaded: null,
      previews: [],
      hasNextPage: false,
      page: 1,
      subscribedUserData: [],
      userSortBy: this.translateChat(`${configJSON.sortByTxt}`),
      userSearch: "",
      chatRoomSortBy: this.translateChat(`${configJSON.sortByTxt}`),
      chatRoomSearch: "",
      groupedMessages: [],
      openEmailvarification: false,
      totalRecords:0,
      itemData:{id:"",attributes:{}},
      currentUserId: 0,
      open: false,
      selectedImage: "",
      selectedDocument:"",
      electedDocument:"",
      selectedMediaType:"",
      message: "", 
      projectDetail:[],
      teamDetail:[],
      projectDetailName:"",
      projectDetailSearchVal:"",
      teamDetailName:"",
      teamDetailSearchVal:"",
      attachMedia:false,
      fullScreenImgURL:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.formRef = React.createRef();
    this.fileRef = React.createRef();
    this.totalPage = 0;
    this.userSessionData = sessionStorage.getItem("userData") || "";
    this.userToken = JSON.parse(this.userSessionData);
    this.LiveChatEndRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
       if (responseJson.error) {
        this.setState({ isLoading: false });
        return;
       }
        switch (apiRequestCallId) {
          case this.getAllChatRoomsRequestId:
            this.handleAllChatRoomsResponse(responseJson.data);
            break;
          case this.getAllChatRoomsRequestID:
            this.handleAllChatRoomsResponseData(responseJson.data);                  
            break;       
          case this.fetchProjectDetailsRequestId:
            this.handleProjectDetailResponse(responseJson.data);
            break;
          case this.fetchTeamDetailsRequestId:
            this.handleTeamDetailResponse(responseJson.data);
            break;
          case this.applyAllFilterDetailsRequestId:
            this.handleapplyAllFilterDetailResponse(responseJson);
            this.setState({ isSortModalOpen: false });
            break;
          case this.getAllMessageRequestId:
            const messages = responseJson.data;  
                       
            this.setState(() => ({
              isLoading: false,
              allSinglePersonChat: messages,
            }));          
            this.groupMessages();
            this.handleChatRooms();    
            break;
          case this.createNewChatRequestId:
            this.setState({
              chatTxtMessage: "",
              isLoading: false,
              previews: [],
              filesUploaded: [],
            });
            this.getAllMessage(this.state.itemData, this.state.page);
            break;
          case this.getAllUsersRequestId:
            this.setState({
              isLoading: false,
              subscribedUserData: responseJson.data,
            });
            break;
          case this.deleteMessageApiCallId:
            this.getAllMessage(this.state.itemData, this.state.page);
            this.setState({
              isLoading: false,
            });
            this.CloseDeleteModal();
            break;
          case this.createExternalChat:
            this.handleExternalChatResponse(responseJson);           
            break;
          case this.createExternalInternalChatRequestId:
            responseJson.errors &&
              this.createToastNotification(
                this.translateChat(responseJson.errors.chat.base[0])
              );
            this.setState({ openNewChat: false });
            this.setState({ isSortModalOpen: false });
            this.handleChatRooms();
            break;
            case this.createExternalInternalMessageeRquestId: 
            this.setState({
              isLoading: false,
              chatTxtMessage: "",
              filesUploaded: [],
              previews: [],
            });         
            this.getAllMessage(this.state.itemData,this.state.page);
            if(responseJson.errors[0] === "Media file cannot include an executable file") {
              this.createToastNotificationError(this.translateChat("Unsupported file format"))
            }
            break;
            case this.etohSupportLiveChatRequestId: 
            this.handleEtohSupportLiveChatResponse(responseJson)
            break;
            case this.inviteMemberRequestId:
              if(responseJson.message){
              this.createToastNotification(responseJson.message);
              this.closeEmailVarification();
              this.closeExternalChat();             
            }else{                          
              this.handleInviteMember();
            }
            break;            
          default:
            this.parseApiCatchErrorResponse(errorResponse);
            break;
        }

    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleAllChatRoomsResponse(responseJson: Array<Chat>) {
    this.setState({
      isLoading: false,
      chatRoomList: responseJson,
    });
  }
  handleAllChatRoomsResponseData(responseJson: Array<Chat>) {
    if(responseJson){
      this.setState({
        chatRoomAllList: responseJson
      });
    }else{
      this.setState({
        chatRoomAllList: []
      });
    }    
  }

  handleProjectDetailResponse(responseJson: any) {
    let dropDownResponse = responseJson.map((item: any) => ({
      label: `${item.attributes.title}`,
      value: item.id,
    }));
    this.setState({projectDetail:dropDownResponse});
  }

  handleTeamDetailResponse(responseJson: any) {
    let dropDownResponse = responseJson.map((item: any) => ({
      label: `${item.attributes.title}`,
      value: item.id,
    }));
    this.setState({teamDetail:dropDownResponse});
  }

  handleapplyAllFilterDetailResponse(responseJson: any) {
    if (responseJson.message) {
      this.setState({
        isLoading: false,
        chatRoomList: [],
      });
    } else {
      this.setState({
        isLoading: false,
        chatRoomList: responseJson.data,
      });
    }
  }

  handleEtohSupportLiveChatResponse(responseJson:LiveChatEtohData) {
    this.setState({ isLoading: false });
    if (responseJson.errors) {
      const etohData=this.state.chatRoomList.filter((cvl)=>{
        return cvl.attributes.chat_type=='etoh_shop_support'
      })
      
       this.getAllMessage(etohData[0],this.state.page)    } else {
      this.createToastNotification(configJSON.etohSupportChatAdded);
    }
    this.closeNewChat();
    this.handleChatRooms();
  }

handleExternalChatResponse(responseJson: ExternalChat) {
  if (responseJson.message === "Member succesfully found") {
    this.setState({
      isLoading: false,
      openExternalChat: false,
    });
    this.createExternalAndInternalChatEvent(responseJson.account.data, "external");
  } else {
    this.setState({
      isLoading: false,
      error: {
        email: `${this.translateChat(configJSON.errorMessageForUserNotFoud)}`,
      },
    });
  }
}
  
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.clearError(prevState);
    this.fetchUsersUpdate(prevState);
    if (prevState.allSinglePersonChat.length !== this.state.allSinglePersonChat.length) {
      setTimeout(() => {
        this.scrollToChatBottom();
      }, 0);
    }
  }
  scrollToChatBottom = () => {
    if (this.LiveChatEndRef.current) {
      this.LiveChatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  async componentDidMount(): Promise<void> {
  const userSessionData = sessionStorage.getItem("userData");
  const userInfo = userSessionData ? JSON.parse(userSessionData) : null;
  const currentUserId = userInfo?.data?.id ? parseInt(userInfo.data.id, 10) : 0;   
  this.setState({ currentUserId });
    this.handleChatRooms();
    this.handleGetChatRooms();
    this.getAllSubscribedUsers();
    this.groupMessages();
    this.fetchProjectDetails();
    this.fetchTeamDetails();
    const lang = localStorage.getItem("lang") || "en";
    await i18n.changeLanguage(lang);
  }
  createToastNotification = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.translateChat(toastMesssage)}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  createToastNotificationError = (toastMesssage: string) => {
    toast.error(
      <div className="toast-notification">
        <div className="notification-txt">{this.translateChat(toastMesssage)}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  groupMessages = () => {
    const { allSinglePersonChat } = this.state;
    
    const groupedMessages: Array<GroupedMessages> = [];

    allSinglePersonChat.forEach((message) => {
      const createdAt = moment(message.attributes.created_at);
      const today = moment().startOf("day");
      const yesterday = moment().subtract(1, "days").startOf("day");

      let dateKey: string;
      switch (true) {
        case createdAt.isSame(today, "d"):
          dateKey = "Today";
          break;
        case createdAt.isSame(yesterday, "d"):
          dateKey = "Yesterday";
          break;
        default:
          dateKey = createdAt.format("DD/MM/YYYY");
      }

      const messageData = groupedMessages.findIndex(
        (group) => group.date === dateKey
      );

      if (messageData === -1) {
        groupedMessages.push({ date: dateKey, messages: [message] });
      } else {
        groupedMessages[messageData].messages.push(message);
      }
    });

    this.setState({ groupedMessages });
  };

  translateChat(keyValue: string) {
    return i18n.t(keyValue, { ns: "translation" });
  }
  toggleProfileDrawer = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };
  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };
  openSortModal = () => {
    this.setState({ isSortModalOpen: true });
  };

  closeSortModal = () => {
    this.setState({
      isSortModalOpen: false,
      projectDetailName: '',
      teamDetailName:'',
      attachMedia:false,
    }, () => this.handleChatRooms());
  };
  openNewChat = () => {
    this.setState({
      openNewChat: true,
    });
  };
  closeNewChat = () => {
    this.setState({
      openNewChat: false,
    });
  };
  openExternalChatEvent = () => {
    this.setState({
      openExternalChat: true,
    });
  };
  closeExternalChat = () => {
    this.setState({
      openExternalChat: false,
    });
  };
  closeEmailVarification = () => {
    this.setState({ openEmailvarification: false });
  };
  handleInviteMember = () => {
    this.setState({ openEmailvarification: true });
  };
  handleBackBtn = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CustomisableUserProfiles"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  
  fetchMoreData() {
    const { allSinglePersonChat, page, totalRecords } = this.state;

    if (allSinglePersonChat.length >= totalRecords) {
      this.setState({ hasNextPage: false });
      return;
    }

    const newPage = page + 1;
    this.getAllMessage(this.state.itemData, newPage);
  }

  fetchUsersUpdate = (prevState: S) => {
    if (
      prevState.userSearch !== this.state.userSearch ||
      prevState.userSortBy !== this.state.userSortBy
    ) {
      this.getAllSubscribedUsers();
    }
    if (
      prevState.chatRoomSearch !== this.state.chatRoomSearch ||
      prevState.chatRoomSortBy !== this.state.chatRoomSortBy
    ) {
      this.handleChatRooms();
    }
  };

  handleAllInputChange = (event: Type) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleKeyDown = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if(this.state.itemData.attributes.chat_type === "etoh_shop_support") {
        this.handleSubmitMessage();
      } else {
        this.createExternalAndInternalChatMessgaeEvent()
      }
    }
  };
  clearError = (prevState: S) => {
    let error = { ...this.state.error };
    const email = this.state.email.trim();
    if (prevState.email !== email) {
      if (email !== "") {
        error.email = "";
      } else if (!configJSON.emailRegex.test(email)) {
        error.email = this.translateChat(configJSON.emailErrorMsg);
      } else {
        error.email = "";
      }
    }
    if (JSON.stringify(error) !== JSON.stringify(this.state.error)) {
      this.setState({ error: error });
    }
  };
  createExternalChatEvent = () => {
    let error = this.state.error;
    let hasError = false;  
    const email = this.state.email.trim();
    const userSessionData = sessionStorage.getItem("userData");
    const userInfo = userSessionData ? JSON.parse(userSessionData) : null;
    const currentUserEmail = userInfo?.data?.attributes?.email;
    if (currentUserEmail && email === currentUserEmail) {
      this.setState({
        error: {
          ...this.state.error,
          email: "You're unable to create a chat with yourself",
        },
      });
      return; 
    }
      if (email === "") {
      this.setState({
        error: {
          ...this.state.error,
          email: this.translateChat(configJSON.emailEmptyErrorMsg),
        },
      });
      return;
    } else if (!configJSON.emailRegex.test(email)) {
      error.email = this.translateChat(configJSON.emailErrorMsg);
      this.setState({
        error: error,
      });
      hasError = true;
    }
  
    if (hasError) {
      return; 
    }
  
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
  
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createExternalChat = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createExternalChatApi}?email=${this.state.email}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createExternalAndInternalChatEvent = (
    dataList: chatRoomData,
    type: string
  ) => {
    const userName =
      dataList.attributes.first_name + " " + dataList.attributes.last_name;
    const formdata = new FormData();
    formdata.append("chat[chat_type]", type);
    formdata.append("chat[name]", userName);
    formdata.append("account_id", dataList.id);

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createExternalInternalChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createChatForExternalAndInternalUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createExternalAndInternalChatMessgaeEvent = () => {
    if (this.state.itemData.attributes.receiver?.deactivated) {
      this.createToastNotificationError(this.translateChat(`${configJSON.deactivatedUser}`));
       return;
      }
    const formdata = new FormData();
    formdata.append("chat_message[message]", this.state.chatTxtMessage);
    formdata.append("chat_message[chat_id]", this.state.messageId);   
    formdata.append("account_id", this.state.itemData.attributes.receiver?.id);
    if (this.state.filesUploaded) {
      for (const file of this.state.filesUploaded) {
        const fileType = file && file.type.split("/")[0];
        formdata.append("chat_message[media_file][]", file);
        formdata.append("chat_message[media_type]",fileType);
      }
    }

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.externalAndInternalMessage
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.createExternalInternalMessageeRquestId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );    
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onBrowse = () => {
    if (this.fileRef.current) {
      this.fileRef.current.click();
    }
  };

  dragEnter = () => {
    this.setState({
      fileDrop: true,
    });
  };
  dragLeave = () => {
    this.setState({
      fileDrop: false,
    });
  };

  handleOnDrop = (files: FileList | null) => {
    const filesData: File[] = Array.from(files || []);
    const previews: string[] = [];

    filesData.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        previews.push(reader.result as string);
        if (previews.length === filesData.length) {
          this.setState({
            filesUploaded: filesData,
            previews,
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };
  handleCloseAttachment = () => {
    this.setState({ filesUploaded: [], previews: [] });
  };
  handleSubmitMessage = () => {
    const formData = new FormData();
    formData.append("chat_message[message]", this.state.chatTxtMessage);
    formData.append("chat_message[chat_id]", this.state.messageId);
    if (this.state.filesUploaded) {
      for (const file of this.state.filesUploaded) {
        const fileType = file && file.type.split("/")[0];
        formData.append("chat_message[media_file][]", file);
        formData.append("chat_message[media_type]",fileType);
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      token: this.userToken.meta.token,
    };

    this.createNewChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendChatRoomMsg}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChatRooms = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const newValue = this.state.chatRoomSortBy;

    let processedValue = "";

    switch (newValue) {
      case "Recent":
        processedValue = "recent";
        break;
      case "Unread":
        processedValue = "unread";
        break;
    }

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllChatRoomsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatPath}?search_query=${this.state.chatRoomSearch}&sort=${processedValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    // this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetChatRooms = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllChatRoomsRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatPath}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllMessage = (item:Chat,newPage:number) => {
    this.markAsReadAll(item.id);
    this.setState({ itemData: item , messageId:item.id });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendChatRoomMsg}?chat_id=${item.id}&page[number]=${newPage}&page[size]=100`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({hasNextPage:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSubscribedUsers = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const newValue = this.state.userSortBy;
    let processedValue = "";

    switch (newValue) {
      case "A-Z":
        processedValue = "A-Z";
        break;
      case "Z-A":
        processedValue = "Z-A";
        break;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllUsersRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subscribedUserList}?search_query=${this.state.userSearch}&sort_by=${processedValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleAllUsersResponse = (responseJson: any) => {
    const users = responseJson.data.map((user: any) => ({
      ...user,
      attributes: {
        ...user.attributes,
        team: user.attributes.team || [],
      },
    }));
  
    this.setState({
      subscribedUserData: users,
      isLoading: false,
    });
  };
  markAsReadAll = (chatID: string) => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadAllRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAsRead}?chat_id=${chatID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createEtohSupportChat = () => {
    const header = {
      token: this.userToken.meta.token,
    };
    const formData = new FormData();
    formData.append("chat[chat_type]", "etoh_shop_support");
    formData.append("chat[name]", "Etoh Support");    

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.etohSupportLiveChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChatPath
    );

    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  inviteMemberEvent = () => {
    const formdata = new FormData();
    formdata.append("[email]", this.state.email);

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteMemberPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.inviteMemberRequestId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );    
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  isSender = (item : any, currentUserId : any) => {
    if(item.attributes.unread_count === 0 || item?.attributes?.last_message?.data?.attributes?.sender?.data?.id === currentUserId)
    {
      return true;
    }
    return false;
  }
  openDeleteMessage = (messageId : string ) => {
    this.setState({ deleteMessage: messageId });
  };
  CloseDeleteMessage = () => {
    this.setState({deleteMessage : ""})
  }
  CloseDeleteModal = () => {
    this.setState({isdeleteModalopen : false, deleteMessage : ""});
  }
  opendeleteMessageModal = (item: string) => {
    this.setState({isdeleteModalopen : true , deleteMessage : "", deleteMessageId: item});
  };
  deleteMessage = () => {
    let MessageId = this.state.deleteMessageId;    

     const headers = {
        "Content-Type": "application/json",
        token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteMessageEndPoint}/${MessageId}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethod
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchProjectDetails = async () => {
    let token = this.userToken.meta.token;
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchProjectDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectfilterEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchTeamDetails = async () => {
    let token = this.userToken.meta.token;
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchTeamDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teamfilterEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChatFilterData = async () => {
    const token = this.userToken.meta.token;
    const header = {
      token
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
  
    this.applyAllFilterDetailsRequestId = requestMessage.messageId;
    let queryParams = `?project_name=${this.state.projectDetailName}&team=${this.state.teamDetailName}`;
  
    if (this.state.attachMedia) {
      queryParams += `&media_file=${this.state.attachMedia}`;
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chats/filter_chats${queryParams}`
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )
  
    runEngine.sendMessage(requestMessage.messageId, requestMessage); 
  }
  projectHandler = (e: any) => {
    this.setState({ projectDetailName: e.target.value });
  };
  projectmultiSelectSearchChange = (event: any) => {
    this.setState({
      projectDetailSearchVal: event.target.value,
    }, () => this.displayproject())
  };
  displayproject = () => { return this.state.projectDetail.filter((obj: any) => this.containsText(obj.label, this.state.projectDetailSearchVal)); }
  teamHandler = (e: any) => {
    this.setState({ teamDetailName: e.target.value });
  };
  teammultiSelectSearchChange = (event: any) => {
    this.setState({
      teamDetailSearchVal: event.target.value,
    }, () => this.displayteam())
  };
  displayteam = () => { return this.state.teamDetail.filter((obj: any) => this.containsText(obj.label, this.state.teamDetailSearchVal)); }
  containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  handleAttachMedia = (event:any) => {
    this.setState({ attachMedia: event.target.checked });
  }

  fullScreenImgModelOpen =(val:string)=>{
    this.setState({ fullScreenImgURL:val });
  }

  handleReset = () => {
    this.setState({
      projectDetailName: "",
      teamDetailName: "",
      attachMedia: false,
    })
  }
  // Customizable Area End
}

import React from "react";
import { Box, Typography, Grid, FormControl, Select, MenuItem, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { customStyles } from "./EquizStyles.web";
export const configJSON = require("./config");
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import CommonModal from "./components/CommonModal";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import SortQuizWeb from "./SortQuiz.web";
import {info_logo, eclipse} from "./assets";
import ManagerDashboardControllerWeb from "./ManagerDashboardController.web";
import UnfinishedTasksModal from "./components/UnfinishedTasksModal";
import OngoingTasksModal from "./components/OngoingTasksModal";


export class ManagerDashboard extends ManagerDashboardControllerWeb {

  render() {

    const t = this.t;
    const {classes} = this.props;
    const pageURL = window.location.pathname;

    return (

    <Box>
    <Box className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}>
        <GlobalHeaderWeb pageLink={pageURL} openCreateTaskFromUnfinish={this.state.isTaskCreate}/>
        <Box className={classes.innerWrapper}>
            <Box className={classes.teamModuleWrapper}>
                <Grid container spacing={2}>
                    <Grid item sm={8} xs={8}>
                        <Box className="google-heading-wrapper">
                            <Box className="heading-left">
                                <Typography className="heading" variant="h1">
                                    {t("Task Metrics")}
                                </Typography>
                            </Box>
                            <Box className="card-container-one" sx={{ width: "100%" }}>
                                <Box className="google-card-row">
                                    <Card className={'followers-card'} onClick={() => this.setState({ isUnfinishedModal: true })}>
                                        <CardContent className="content-card">
                                            <Box className="right-content">
                                                <Box className="sub-cards">
                                                    <span>{t("Unfinished")}</span>
                                                    <span className="info-container">
                                                        <img src={info_logo} alt={t("info")} />
                                                    </span>
                                                </Box>
                                                <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                    <span>{this.state.unfinishedTasks} &nbsp;</span>
                                                </Box>
                                                <Box className="quiz-date">{t("And")} {this.state.completedTasks} {t("Completed")}</Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <Card className={'followers-card'} onClick={() => this.setState({ isOngoingModal: true })}>
                                        <CardContent className="content-card">
                                            <Box className="right-content">
                                                <Box className="sub-cards">
                                                    <span>{t("Ongoing")}</span>
                                                    <img src={info_logo} alt={t("info")} />
                                                </Box>
                                                <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                    <span>{this.state.ongoingTasks} &nbsp;</span>
                                                </Box>
                                                <Box className="quiz-date">{this.state.membersNotTrackingTime} {t("members not tracking time")}</Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <Card className={'followers-card'}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                            {t("Emergency")}
                                                            </span>
                                                            <img src={info_logo} alt={t("info")} />
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                {this.state.emergencyTasks} &nbsp;
                                                            </span>
                                                        </Box>
                                                        <Box className="quiz-date">
                                                        {this.state.ongoingEmergencyTasks} {t("Ongoing")}
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="sub-cards">
                                                            <span>
                                                            {t("Past Due")}
                                                            </span>
                                                            <img src={info_logo} alt={t("info")} />
                                                        </Box>
                                                        
                                                        <Box className="card-val" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                             {this.state.pastDueTasks} &nbsp;
                                                            </span>
                                                        </Box>
                                                        <Box className="quiz-date">
                                                          {this.state.ongoingPastDueTasks} {t("Ongoing")}
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="google-heading-wrapper">
                            <Box className="heading-container">
                                <Box>
                                    <Typography className="heading" variant="h1">{t("Ongoing Tasks")}</Typography>
                                    <Typography className="sub-head-container">
                                        <span>10 {t("Members")}</span>
                                        <img width="8px" height="8px" src={eclipse} alt={t("eclipse")} />
                                        <span>{t("Average of 6 tasks per member")}</span>
                                    </Typography>
                                </Box>
                                <Typography onClick={() => this.setState({ isOngoingModal: true })}
                                style={{ cursor:'pointer',fontWeight: 500, fontSize: "14px", color: "#4BA3B7", fontFamily: "Expletus Sans" }} variant="h3">
                                    {t("See All")}
                                </Typography>
                            </Box>

                            <Box sx={{ width: "100%" }}>
                                <Box className="ongoing-tasks-box">
                                    {this.state.teamMetrics.map((obj) => (
                                        <Card className={'followers-card'} key={obj.name}>
                                            <CardContent>
                                                <Box className="ongoing-tasks-card">
                                                    <Box className="row-one">
                                                        <span>{obj.member.first_name + " " + obj.member.last_name}</span>
                                                        <span>{Math.round(obj.hours_tracked * 100)/100 + " / " + Math.round(obj.ongoing_task_assign_hours * 100)/100 + " hrs"}</span>
                                                    </Box>
                                                    <Box className="row-two">
                                                        <span className="row-elem">
                                                            <span className="sub-head">{t("Unfinished")}</span>
                                                            <span>{obj.unfinished_tasks}</span>
                                                        </span>
                                                        <span className="row-elem">
                                                            <span className="sub-head">{t("High Priority")}</span>
                                                            <span>{obj.high_priority_tasks}</span>
                                                        </span>
                                                        <span className="row-elem">
                                                            <span className="sub-head">{t("Past Due")}</span>
                                                            <span>{obj.past_due_tasks}</span>
                                                        </span>
                                                        <span className="row-elem">
                                                            <span className="sub-head">{t("Completed")}</span>
                                                            <span>{obj.completed_tasks}</span>
                                                        </span>
                                                        <span className="row-elem">
                                                            <span className="sub-head">{t("Total")}</span>
                                                            <span>{obj.total_tasks}</span>
                                                        </span>
                                                    </Box>
                                                    <Box className="row-three">
                                                        <span className="sub-head">{t("Ongoing")}</span>
                                                        <span style={{ fontSize: "12px" }}>{obj.ongoing_task_names.join(", ")}</span>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item sm={4} xs={4}>
                        <Box className="google-side-wrapper">
                            <Typography style={{ fontWeight: 400, fontSize: "22px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                {t("Performance Metrics")}
                            </Typography>

                            <Box className={"performance-card-container"}>
                                <Box className={'performance-metrics-card'}>
                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                        {t("Tasks Over Tracked")}
                                    </Typography>
                                    <Typography style={{ marginBottom: 6, fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                        {this.state.tasksOverTracked}
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: "12px", color: "#94A3B8", fontFamily: "Roboto" }} variant="h1">
                                         {"£ " + this.state.overBudget + t(" over budget")}
                                    </Typography>
                                </Box>
                                <Box className={'performance-metrics-card'}>
                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                        {t("Hours Tracked")}
                                    </Typography>
                                    <Typography style={{ marginBottom: 6, fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                        {this.state.totalHoursTracked}
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: "12px", color: "#94A3B8", fontFamily: "Roboto" }} variant="h1">
                                        {this.state.percentageOfHoursAllocated} {t("of Hours Allocated")}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Box>

    {this.state.isOngoingModal && (
        <OngoingTasksModal classes={classes} modalOpen={this.state.isOngoingModal} closeModal={this.closeOngoingModal} tasks={this.ongoingTasks} />
    )}

    {this.state.isUnfinishedModal && (
        <UnfinishedTasksModal classes={classes} 
        modalOpen={this.state.isUnfinishedModal} 
        closeModal={this.closeUnfinishedModal} 
        tasks={this.ongoingTasks} 
        isTaskCreate={this.state.isTaskCreate}
        handleTaskCreate={this.handleTaskCreate}
        unfinishedTasks={this.state.unfinishedTasks} 
        completedTasks={this.state.completedTasks}/>
    )}
</Box>
    )

  }
}

export default withStyles(customStyles, { withTheme: true })(ManagerDashboard);

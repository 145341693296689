// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import { clockOutline, CalenderIcon } from "../../blocks/cfsendinblueapi/src/assets";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    campaignMarketingRoot: {
      display: "flex",
      flexWrap: "wrap",
      minHeight: "calc(100vh - 88px)",
      flex: "1",
      "& .mobile-heading-deals": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingBottom: "0",
          marginBottom: "48px",
          borderBottom: "0",
          padding: "24px 24px 0",
        },
        "& .marketing-menu-deals": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 20px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            letterSpacing: "0",
          },
        },
        "& .back-btn-wrap-deals": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
      },
      "& .marketing-content-deals": {
        height: "100%",
        display: "block",
        "& .marketing-top-deals": {
          display: "flex",
          overflowX: 'auto',
          alignItems: "center",
          padding: "8px 19px",
          borderBottom: "1px solid #E8ECF2",
          width: "100%",
          // paddingTop:"10%",
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
          "& .top-left": {
            display: "flex",
            alignItems: "center",
            flex: '0 0 40%',
            minWidth: "40%",
            
            "& .email-count": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              letterSpacing: "0.1px",
              whiteSpace: 'nowrap',
              marginRight: "18px",
              paddingRight: "18px",
              borderRight: "1px solid #E8ECF2",
            },
            "& .email-search-wrapper": {
              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },

              "& .MuiInputAdornment-positionStart": {
                marginRight: "12px",
              },
              "& .MuiInput-root": {
                maxWidth: "100%",
              },

              "& .MuiInput-underline": {
                "&:before, &:after": {
                  display: "none",
                },
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                color: "#2B2B2B",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                "&::-webkit-input-placeholder": {
                  opacity: "0.37",
                  color: "#2B2B2B",
                },
                "&:-ms-input-placeholder": {
                  opacity: "0.37",
                  color: "#2B2B2B",
                },
                "&::placeholder": {
                  opacity: "0.37",
                  color: "#2B2B2B",
                },
              },
              "& .MuiSvgIcon-root": {
                color: "#2b2b2b",
                fontSize: "20px",
                opacity: "0.67",
              },
            },
          },
          "& .top-right": {
            [theme.breakpoints.down(1040)]: {
              marginLeft: "30px",
            },
            "& .filter-list": {
              display: "flex",
              overflowX: 'auto',
              alignItems: "center",
              
             
              "& .filter-control": {
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                whiteSpace: 'nowrap',
                display: "flex",
                alignItems: "center",
                gap: '15px',
                "& .icon": {
                  marginLeft: '7px !important',
                  maxWidth: 'fit-content !important',
                },
                "& .datepicker-wrapper": {
                  marginRight: "0",
                  "& .rmdp-container": {
                    width: "100px",
                    "& .rmdp-input": {
                      border: "0",
                      padding: "0 0 0 24px",
                      //minHeight: "unset",
                      backgroundPosition: "left center",
                      backgroundImage: `url(${CalenderIcon})`,
                      opacity: "0.67",
                      "&::-webkit-input-placeholder": {
                        color: "#2B2B2B",
                      },
                      "&:-ms-input-placeholder": {
                        color: "#2B2B2B",
                      },
                      "&::placeholder": {
                        color: "#2B2B2B",
                      },
                    },
                  },
                },
                "& .select-control": {   
                  marginRight: '14px',
                  marginLeft: '60px',                 
                  "& .MuiInput-underline:before": {
                    display: "none",
                  },
                  "& .MuiInput-underline:after": {
                    display: "none",
                  },
                  "& .MuiInputBase-root": {
                    color:"rgb(109 106 106 / 87%)",
                    fontFamily: "Expletus Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    "& fieldset": {
                      border: "none",
                    },
                  },
                },
              },
              "& .sortby-control": {
                "& .MuiInput-underline": {
                  "&::before": {
                    display: "none",
                  },
                  "&::after": {
                    display: "none",
                  },
                  "& .MuiSelect-selectMenu": {
                    "&:focus": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiBox-root": {
                      display: "flex",
                      alignItems: "center",
                      opacity: "0.67",
                      "& img": {
                        marginRight: "5px",
                      },
                    },
                  },
                },
              },
              "& .switch-wrapper": {
                marginLeft: '25px',
                "& .MuiFormControlLabel-root": {
                  marginRight: "0",
                },
              },
              "& .border-hl": {
                // marginLeft: "24px",
                // paddingLeft: "24px",
                padding: '0px 10px',
                borderLeft: "1px solid #E8ECF2",
                
              },
              "& .filter-select-campaign": {
                marginRight: "24px",
                [theme.breakpoints.down(1440)]: {
                  marginRight: "14px",
                },
                [theme.breakpoints.down(1366)]: {
                  marginRight: "25px",
                },
                [theme.breakpoints.down(1199)]: {
                  marginRight: "0",
                  flex: "0 0 25%",
                  maxWidth: "25%",
                  padding: "0 6px 12px",
                },
                "& .MuiInputBase-root": {
                  [theme.breakpoints.down(1199)]: {
                    width: "max-content",
                  },
                },
                "&:last-child": {
                  marginRight: "0",
                },
              },
              "& .select-control-deals": {
                // marginRight: "24px",
                marginLeft: '20px',
                [theme.breakpoints.down(1440)]: {
                  marginRight: "14px",
                },
                [theme.breakpoints.down(1366)]: {
                  marginRight: "25px",
                },
                [theme.breakpoints.down(1199)]: {
                  marginRight: "0",
                  flex: "0 0 25%",
                  maxWidth: "25%",
                  padding: "0 6px 12px",
                },
                "& .MuiInputBase-root": {
                  [theme.breakpoints.down(1199)]: {
                    width: "max-content",
                  },
                },
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
          },
          "& .filter-select-campaign": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                top: "3px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.1px",
              color: "rgba(43, 43, 43, 0.87)",
              opacity: "0.671",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                [theme.breakpoints.down(1199)]: {
                  justifyContent: "flex-start",
                },
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
            "&.sortby-control": {
              "& .MuiSelect-select": {
                paddingRight: "0",
              },
              "& .select-icon": {
                top: "3px",
              },
            },
            "& .select-icon": {
              position: "relative",
              marginRight: "6px",
            },
          },
        },
        "& .empty-box": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          [theme.breakpoints.down(767)]: {
            flexDirection: "column",
          },

          "& .img-contact-book": {
            width: "300px",
            height: "244px",
            objectFit: "contain",
          },
          "& .box-empty-right": {
            margin: "0 0 0 93px",
            maxWidth:"415px",
            [theme.breakpoints.down(767)]: {
              margin: "0",
              padding: "0 25px",
            },
            "& .txt-no-contact-title": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "normal",
              letterSpacing: "0.25px",
            },
            "& .txt-no-contact-sub-title": {
              color: "var(--task-completed-color, #94A3B8)",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "20px",
              letterSpacing: "0.25px",
              margin: "12px 0",
            },
            "& .MuiButton-root": {
              borderRadius: "6px",
              background: "var(--brand-color-primary-100, #4BA3B7)",
              color: "#ffffff",
              textTransform: "none",
              fontFamily: "Expletus Sans",
              width: "211px",
              height: "48px",
              margin: "48px 0px 0px",
            },
            "& .brevo-connect-btn":{
              width: "136px",
            }
          },
        },
      },
    },
    switchWrapperDeals: {
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          height: "20px",
          padding: "5px",
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px",
          },
        },
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        fontSize: "14px",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          width: "100%",
          justifyContent: "space-between",
          opacity: "1",
          color: "#2B2B2B",
        },
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000000",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        "& .MuiFormControlLabel-label": {
          font: "inherit",
        },
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },

      "& .MuiSwitch-track": {
        backgroundColor: "#d3d9e1",
        opacity: 1,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8",
        },
      },

      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px",
        },
      },
    },
    stepperMain: {
      "& .stepper-wrapper": {
        "& .MuiStep-root": {
          "& .MuiStepLabel-root": {
            display: "none",
          },
          "& .MuiStepContent-root": {
            margin: "0",
            padding: "0",
            border: "0",
          },
        },
        "& .MuiStepConnector-vertical": {
          display: "none",
        },
      },
    },
    timestandDropbox: {
      borderRadius: "12px",
      padding: "24px",
      minWidth: "230px",
      background: "#fff",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      bottom: "74%",
      right: "65px",
      position: "absolute",
      "& .timestand-inner": {
        "& .timestand-heading": {
          fontSize: "16px",
          lineHeight: "24px",
          color: "#2B2B2B",
          fontWeight: 400,
          fontFamily: "Expletus Sans",
          marginBottom: "16px",
        },
        "& .time": {
          display: "flex",
          alignItems: "center",
          "& .MuiFormControl-root": {
            maxWidth: "25px",
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            padding: "0",
            "&::-webkit-input-placeholder": {
              opacity: "1",
              color: "rgba(43, 43, 43, 0.87)",
            },
            "&:-ms-input-placeholder": {
              opacity: "1",
              color: "rgba(43, 43, 43, 0.87)",
            },
            "&::placeholder": {
              opacity: "1",
              color: "rgba(43, 43, 43, 0.87)",
            },
          },
          "& .colon-txt": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            margin: "0 10px 0 5px",
          },
        },
      },
      "& .timestand-actions": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingTop: "16px",
        marginTop: "16px",
        borderTop: "1px solid #E8ECF2",
        "& >a": {
          fontSize: "14px",
          lineHeight: "18px",
          color: "#94A3B8",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          textDecoration: "none",
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
          "&.primary-link": {
            color: "#4ba3b7",
          },
        },
      },
    },
    navigationBtns: {
      display: "flex",
      alignItems: "flex-start",
      position: "absolute",
      top: "63px",
      right: "60px",
      "& .steps-select-outer": {
        position: "absolute",
        top: "-10px",
        right: "64px",
        minWidth: "90px",
        "& .MuiInputBase-root":{
          color: "rgba(0, 0, 0, 0.87)",
          cursor: "text",
          display: "inline-flex",
          position: "relative",
          fontSize: "1rem",
          boxSizing: "border-box",
          alignItems: "center",
          fontFamily: "'Expletus Sans'",
          fontWeight: 400,
          lineHeight: "1.1876em",
          letterSpacing: "0.00938em",
          "& .MuiSelect-icon":{
            top: "calc(50% - 12px)",
            color: "rgb(148, 163, 184, 1)",
            right: "0",
            position: "absolute",
            pointerEvents: "none"
          },
        },
        "& .MuiSelect-root": {
          padding: "3px 21px 3px 11px",
          height: "33px",
          lineHeight: "38px",
          "& .MuiSelect-iconOutlined": {
            right: "0",
          },
        },
        "& legend > span": {
          visibility: "visible",
          adding: "0 5px",
          fontSize: "10px",
          top: "-3px",
          position: "relative",
          color: "#94A3B8",
        },
      },
      "& .heading-icon": {
        marginRight: "15px",
        "&:last-child": {
          marginRight: "0",
        },
      },
    },
    stepperInsideModal: {
      backgroundColor: "#FFF",
      borderRadius: "12px",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      padding: "24px",
     // position: "absolute",
      //top: "120px",
      zIndex: 999,
      maxWidth: "100%",
      "& .MuiList-root": {
        padding: "0",
        "& .MuiListItem-root": {
          padding: "0",
          display: "block",
          marginBottom: "16px",
          "&:last-child": {
            marginBottom: "0",
          },
          "&.completed-step": {
            "& .list-inner": {
              borderColor: "#00BA88",
              "& .counter": {
                borderColor: "#00BA88",
                "& em": {
                  color: "#00BA88",
                  display: "none",
                },
                "& .checked": {
                  display: "block",
                },
              },
              "& .content": {
                "& .content-title": {
                  color: "#00BA88",                
                },
                "& .content-desc": {
                  color: "#00BA88",
                },
              },
            },
          },
          "&.current-step": {
            "& .list-inner": {
              borderColor: "#4BA3B7",
              "& .counter": {
                borderColor: "#4BA3B7",
                "& em": {
                  color: "#4BA3B7",
                },
              },
              "& .content": {
                "& .content-title": {
                  color: "#4BA3B7",
                  wordWrap: "break-word",
                },
                "& .content-desc": {
                  color: "#94A3B8",
                },
              },
            },
          },
          "& .list-inner": {
            padding: "16px 24px",
            border: "1px solid #94A3B8",
            borderRadius: "12px",
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            "& .counter": {
              position: "absolute",
              top: "50%",
              left: "-12px",
              transform: "translateY(-50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "24px",
              height: "24px",
              border: "1px solid #94A3B8",
              borderRadius: "50%",
              backgroundColor: "#FFF",
              "& em": {
                color: "#94A3B8",
                fontSize: "12px",
                fontWeight: "600",
                fontFamily: "Roboto",
                fontStyle: "normal",
              },
              "& .checked": {
                display: "none",
                border: "1px solid transparent",
                height: "13px",
                width: "6px",
                top: "3px",
                left: "8px",
                transform: "rotate(45deg)",
                borderTop: "0",
                borderLeft: "0",
                position: "absolute",
                borderColor: "#00ba88",
              },
            },
            "& .content": {
              flex: "0 0 calc(100% - 85px)",
              maxWidth: "calc(100% - 85px)",
              "& .content-title": {
                color: "#2B2B2B",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
              },
              "& .content-desc": {
                color: "#94A3B8",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Roboto",
              },
            },
            "& .navigation": {
              flex: "0 0 85px",
              maxWidth: "85px",
              paddingLeft: "10px",
              "& .current": {
                color: "#4BA3B7",
                fontSize: "12px",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                display: "block",
                textAlign: "right",
              },
              "& .complete": {
                color: "#00BA88",
                fontSize: "12px",
                fontWeight: "500",
                fontFamily: "Expletus Sans",
                display: "block",
                textAlign: "right",
              },
              "& .arrow": {
                display: "block",
                textAlign: "right",
              },
            },
          },
        },
      },
    },
    dropdownDeals: {
      "&.changepriority-dropdown": {
        "& .MuiMenu-paper": {
          marginTop: "0",
        },
      },
      "&.tablemenu-dropdown": {
        "& .MuiMenu-paper": {
          minWidth: "291px",
        },
        "& .table-menu-item": {
          "& .name": {
            marginRight: "10px",
          },
          "& .icon": {
            marginLeft: "auto",
          },
        },
      },
      "& .MuiMenu-paper": {
        minWidth: "164px",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        marginTop: "12px",

        "& .MuiList-padding": {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
        "& .table-menu-item": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& .icon": {
            maxWidth: "24px",
            width: "24px",
            marginLeft: "10px",
            textAlign: "right",
          },
        },
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#2b2b2b",
          padding: "16px 24px",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "flex",
          justifyContent: "flex-start",
          borderBottom: "1px solid #ECF1F4",
          "& .priority-name": {
            marginRight: "10px",
          },
          "& .priority-icon": {
            marginLeft: "auto",
          },
        },
      },
    },
    dropdownStyleDeals: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "&.modal-dropdown": {
        marginTop: "0",
      },
      "& .status-item": {
        display: "flex",
        "& .status-name": {
          marginRight: "10px",
          textTransform: "capitalize",
        },
        "& .status": {
          width: "12px",
          height: "12px",
          background: "#94A3B8",
          borderRadius: "100%",
          marginLeft: "auto",
          "&.open": {
            background: "#4BA3B7",
          },
          "&.cancelled": {
            background: "#F7E223",
          },
          "&.sky-blue": {
            background: "#12AEF1",
          },
          "&.orange": {
            background: "#F6A318",
          },
          "&.yellow": {
            background: "#F7E223",
          },
          "&.green": {
            background: "#00BA88",
          },
          "&.black": {
            background: "#2B2B2B",
          },
        },
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        "&.assignee-multiSelect": {
          maxWidth: "400px",
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            position: "static",
            "&.multiselect-subheader": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              borderBottom: "1px solid #ECF1F4",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginBottom: "5px",
              "& .tasklist-link": {
                marginLeft: "10px",
                color: "#C7263E",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                textDecoration: "none",
              },
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .priority-name": {
            margin: "0",
            "& .MuiTypography-root": {
              display: "flex",
            },
            "& .priority-status": {
              marginLeft: "auto",
            },
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
            "& .assignee-detail": {
              "& .assignee-right": {
                "& .assinee-selected": {
                  "& .normal-img": {
                    display: "none",
                  },
                  "& .selected-img": {
                    display: "block",
                  },
                },
              },
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .select-icon": {
          marginRight: "6px",
        },
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
      "& .assignee-detail": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        "& .assignee-left": {
          display: "flex",
          alignItems: "center",
          "& .assignee-info": {
            marginLeft: "10px",
          },
          "& .assignee-profile": {
            width: "39px",
            height: "39px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ECF1F4",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Roboto",
            fontSize: "15.485px",
            fontWeight: 600,
            lineHeight: "21.679px",
            letterSpacing: "-0.395px",
          },
          "& .assignee-name": {
            fontSize: "14px",
            lineHeight: "19px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
          "& .assignee-email": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .assignee-right": {
          marginLeft: "10px",
          "& .assinee-selected": {
            width: "20px",
            height: "20px",
            "& .normal-img": {
              display: "none",
            },
            "& .selected-img": {
              display: "block",
            },
          },
          "& .assinee-unselected": {
            width: "20px",
            height: "20px",
            "& .normal-img": {
              display: "block",
            },
            "& .selected-img": {
              display: "none",
            },
          },
        },
      },
    },
    table: {
      "& .select-modal-field": {
        "& .form-control": {
          marginBottom: "0",
          "& .MuiOutlinedInput-input": {
            padding: "0",
            width: "0",
          },
          "& .MuiInputAdornment-positionEnd": {
            marginLeft: "0",
          },
          "& .MuiOutlinedInput-root": {
            padding: "0",
            borderRadius: "0",
            display: "flex",
            justifyContent: "flex-start",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
              padding: "0",
            },
          },
        },
        "& .MuiInputAdornment-positionEnd": {
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: "#94A3B8",
          },
        },
      },
      "& .status-item": {
        display: "flex",
        alignItems: "center",
        "& .status-name": {
          marginRight: "10px",
          textTransform: "capitalize",
        },
        "& .status": {
          width: "12px",
          height: "12px",
          background: "#94A3B8",
          borderRadius: "100%",
          marginLeft: "auto",
          "&.open": {
            background: "#4BA3B7",
          },
          "&.cancelled": {
            background: "#F7E223",
          },
          "&.sky-blue": {
            background: "#12AEF1",
          },
          "&.orange": {
            background: "#F6A318",
          },
          "&.yellow": {
            background: "#F7E223",
          },
          "&.green": {
            background: "#00BA88",
          },
          "&.black": {
            background: "#2B2B2B",
          },
        },
      },
      "&.table-wrap-deals": {
        "& .MuiTableRow-root": {
          "&.Mui-selected": {
            background: "#fafcfe",
            "&:hover": {
              background: "#fafcfe",
            },
            "& .MuiTableCell-root": {
              "& .profile-name": {
                backgroundColor: "#eaeff2",
              },
            },
          },
          "&.disable-member-row": {
            "&:hover": {
              background: "#ffffff",
            },
          },
        },
        "& .MuiTableCell-head": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          lineHeight: "20px",
          color: "rgba(43, 43, 43, 0.369)",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          padding: "19px 25px",
          borderBottom: "1px solid #E8ECF2",
          [theme.breakpoints.down("md")]: {
            padding: "19px 25px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "17px 10px",
          },
          "&:first-child": {
            paddingLeft: "0",
          },
          "&:last-child": {
            paddingRight: "0",
          },
          "&.MuiTableCell-paddingNone": {
            padding: "0",
          },
          "&.MuiTableCell-paddingCheckbox": {
            padding: "0 40px 0 18px",
          },
          "&.name-cell": {
            paddingLeft: "29px",
            cursor: "pointer",
          },
        },
        "& .MuiTableCell-body": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          lineHeight: "20px",
          color: "#2b2b2b",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          padding: "11px 25px",
          borderBottom: "1px solid #E8ECF2",
          [theme.breakpoints.down("md")]: {
            padding: "11px 25px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "10px",
          },
          "&:first-child": {
            paddingLeft: "0",
          },
          "&:last-child": {
            paddingRight: "0",
          },
          "& p.text-light": {
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: "400",
            color: "#94A3B8",
            marginBottom: "0px",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          },
          "& .table-text": {
            fontFamily: "Expletus Sans",
            display: "flex",
            alignItems:"center",
            color: "#2B2B2B",
            fontSize: "14px",
            fontWeight: "500",
            marginBottom: "3px",
            width:"140px",
            "& .assignee-bubble":{
              color: "rgba(43, 43, 43, 0.87)",
              width: "20px",
              border: "2px solid #FFF",
              height: "20px",
              display: "flex",
              position: "relative",
              fontSize: "9.586px",
              background: "#ECF1F4",
              alignItems: "center",
              fontFamily: "Roboto",
              fontWeight: 600,
              lineHeight: "13.42px",
              marginLeft: "4px",
              borderRadius: "100%",
              letterSpacing: "-0.244px",
              justifyContent: "center",
              textWrap: "nowrap",
              "&:hover":{
                "& .text-detail":{
                  opacity: 1,
                  visibility: "visible",
                  zIndex: 99999,
                },
              },
            },
           
            "& .text-detail":{
              top:"32px",
              left:"-2px",
              position: "absolute",
              boxShadow: "3px 1px 29px -2px rgba(0, 0, 0, 0.3)",
              borderRadius: "15px",
              backgroundColor: "#fff",
              padding: "15px",
              minWidth:"150px",
              transition: "all 0.5s",
              opacity: 0,
              visibility: "hidden",
              [theme.breakpoints.down(575)]: {
                left:"auto",
              },
              [theme.breakpoints.down(360)]: {
                minWidth:"100%",
              },
              "& ul":{
                padding:"0",
                "& li":{
                  padding:"0",
                  margin:"0 0 10px",
                  "&:last-child":{
                    margin:"0",
                  },
                  "& .title":{
                    flex: "0 0 100px", 
                    maxWidth: "100px",
                    color: "#2B2B2B",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "1.28",
                  },
                
                }
              }
            },
          },
          "&.MuiTableCell-paddingNone": {
            padding: "0",
          },
          "&.MuiTableCell-paddingCheckbox": {
            padding: "0 40px 0 18px",
          },
          "&.name-cell": {
            paddingLeft: "0",
            cursor: "pointer",
          },
          "& .MuiLink-root": {
            color: "inherit",
          },
          "&.action-link": {
            whiteSpace: "noWrap",
            "& .table-link": {
              marginLeft: "0",
              whiteSpace: "noWrap",
            },
          },
          "& .show-me-link": {
            marginLeft: "5px",
            background: "#4ba3b7",
            borderRadius: "6px",
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            padding: "2px 8px",
            textTransform: "capitalize",
          },
          "& .attachment-link": {
            font: "inherit",
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            whiteSpace: "noWrap",
            "& .attach-icon-deals": {
              marginRight: "12px",
            },
          },
          "& .table-menu": {
            display: "inline-block",
            "& .table-link": {
              padding: "0",
              textTransform: "none",
              font: "inherit",
              "& .MuiButton-endIcon": {
                marginLeft: "2px",
              },
            },
          },
          "& .table-link": {
            color: "#2b2b2b",
            textDecoration: "none",
            marginLeft: "10px",
            "&:hover": {
              textDecoration: "underline",
            },
          },
          "& .name-col": {
            display: "flex",
            alignItems: "center",
            "& .table-link": {
              marginLeft: "0",
            },
          },
          "& .desc": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "1",
            "-webkit-box-orient": "vertical",
          },
          "& .profile-name": {
            width: "48px",
            height: "48px",
            borderRadius: "24px",
            backgroundColor: "#fafcfe",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textTransform: "uppercase",
            fontWeight: "700",
            marginRight: "12px",
          },
          "& .filter-select-campaign": {
            "& .MuiSelect-select": {
              fontSize: "14px",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .action-link": {
          width: "14.6%",
          [theme.breakpoints.down("md")]: {
            width: "21.6%",
          },
          "&> a": {
            marginRight: "25px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
        "& .value-text": {
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "400",
          color: "#4F4F4F",
          letterSpacing: "0.105px",
          "& span": {
            fontSize: "12px",
          },
        },
        "& .sender-cell": {
          // width:"20%",
          width: "180px",
          minWidth: "180px",
        },
        "& .draft-cell":{
          color : "rgba(199, 38, 62, 1)",
        },
        "& .title-cell": {
          // width:"20%",
          width: "200px",
          minWidth: "200px",
        },
        "& .delivered-cell": {
          // width:"12%",
          width: "20px",
          minWidth: "20px",
        },
        "& .openers-cell": {
          // width:"12%",
          width: "20px",
          minWidth: "20px",
        },
        "& .clicks-cell": {
          // width:"12%",
          width: "20px",
          minWidth: "20px",
        },
        "& .unsubscribers-cell": {
          // width:"12%",
          width: "20px",
          minWidth: "20px",
        },
        "& .sent-cell":{
          width: "150px",
          minWidth: "150px",
        },
        "& .action-cell": {
          // width:"12% !important",
          width: "80px !important",
          minWidth: "80px !important",
        },
      },
      "&.direct-emails-sent": {
        minWidth: "1553px",
        "& .MuiTableCell-head": {
          "&.name-cell": {
            width: "18.5%",
            cursor: "pointer",
          },
          "&.title-cell": {
            width: "13.8%",
          },
          "&.desc-cell": {
            width: "25.5%",
          },
          "&.date-cell": {
            width: "14%",
          },
          "&.attachment-cell": {
            width: "20.5%",
          },
          "&.action-cell": {
            width: "7.7%",
          },
        },
      },
      "& .MuiTableRow-root": {
        "&.Mui-selected": {
          background: "#fafcfe",
          "&:hover": {
            background: "#fafcfe",
          },
          "& .MuiTableCell-root": {
            "& .profile-name": {
              backgroundColor: "#eaeff2",
            },
          },
        },
        "&.disable-member-row": {
          "&:hover": {
            background: "#ffffff",
          },
        },
      },
      "& .MuiTableCell-head": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        color: "rgba(43, 43, 43, 0.369)",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        padding: "19px 16px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("md")]: {
          padding: "19px 10px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "17px 10px",
        },
        "&.MuiTableCell-paddingNone": {
          padding: "0",
        },
        "&.MuiTableCell-paddingCheckbox": {
          padding: "0 40px 0 18px",
        },
        "&.name-cell": {
          paddingLeft: "29px",
          cursor: "pointer",
        },
      },
      "& .MuiTableCell-body": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        lineHeight: "20px",
        color: "#2b2b2b",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        padding: "11px 16px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("md")]: {
          padding: "11px 10px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "10px",
        },
        "&.MuiTableCell-paddingNone": {
          padding: "0",
        },
        "&.MuiTableCell-paddingCheckbox": {
          padding: "0 40px 0 18px",
        },
        "&.name-cell": {
          paddingLeft: "29px",
          cursor: "pointer",
        },
        "& .MuiLink-root": {
          color: "inherit",
        },
        "&.action-link": {
          whiteSpace: "noWrap",
          "& .table-link": {
            marginLeft: "0",
            whiteSpace: "noWrap",
          },
        },
        "& .show-me-link": {
          marginLeft: "5px",
          background: "#4ba3b7",
          borderRadius: "6px",
          color: "#FFFFFF",
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.0075em",
          padding: "2px 8px",
          textTransform: "capitalize",
        },
        "& .attachment-link": {
          font: "inherit",
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          alignItems: "center",
          whiteSpace: "noWrap",
          "& .attach-icon-deals": {
            marginRight: "12px",
          },
        },
        "& .table-menu": {
          display: "inline-block",
          "& .table-link": {
            padding: "0",
            textTransform: "none",
            font: "inherit",
            "& .MuiButton-endIcon": {
              marginLeft: "2px",
            },
          },
        },
        "& .table-link": {
          color: "#2b2b2b",
          textDecoration: "none",
          marginLeft: "10px",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        "& .name-col": {
          display: "flex",
          alignItems: "center",
          "& .table-link": {
            marginLeft: "0",
          },
        },
        "& .desc": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          "-webkit-line-clamp": "1",
          "-webkit-box-orient": "vertical",
        },
        "& .profile-name": {
          width: "48px",
          height: "48px",
          borderRadius: "24px",
          backgroundColor: "#fafcfe",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "uppercase",
          fontWeight: "700",
          marginRight: "12px",
        },
        "& .filter-select-campaign": {
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0.1px",
            color: "#2b2b2b",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
      },
      "& .action-link": {
        width: "14.6%",
        [theme.breakpoints.down("md")]: {
          width: "21.6%",
        },
        "&> a": {
          marginRight: "25px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    createTaskWrapper: {
      "& .task-actions": {
        position: "fixed",
        right: "32px",
        bottom: "32px",
        background: "#ffffff",
        [theme.breakpoints.down("sm")]: {
          bottom: "60px",
        },
        "& .secondary-btn": {
          boxShadow: "0px 0px 18px 2px rgba(0, 0, 0, 0.10)",
          marginRight: "16px",
          minWidth: "auto",
          color: "rgba(43, 43, 43, 0.87)",
          "&:hover": {
            border: "1px solid rgba(43, 43, 43, 0.87)",
          },
          "& .draft-name": {
            display: "flex",
            alignItems: "center",
          },
          "& .draft-img": {
            marginRight: "6px",
          },
          "& .close": {
            margin: "0 0 0 5px",
            cursor: "pointer",
          },
        },
      },
      "& .primary-btn": {
        boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
        minWidth: "136px",
      },
    },
    createCampaignBox: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      padding: "0",
      overflow: "auto",
      maxWidth: "591px",
      width: "100%",
      marginLeft: "auto",
      // maxHeight: "unset",
      display: "inline-block",
      top: "50% !important",
      transform: "translateY(-50%) !important",
      bottom: "auto !important",
      right: "32px",
      position: "fixed",
      "&.stepper-timeline-visible": {
        bottom: "15px !important",
        top: "auto !important",
        transform: "none !important",
        [theme.breakpoints.down("sm")]: {
          bottom: "65px",
        },
        [theme.breakpoints.down("xs")]: {
          bottom: "0",
        },
        "& .campaign-description": {
          height: "calc(100vh - 240px)",
          [theme.breakpoints.down("sm")]: {
            height: "calc(100vh - 240px)",
          },
          [theme.breakpoints.down("xs")]: {
            height: "calc(100vh - 193px)",
          },
        },
      },
      [theme.breakpoints.down("xs")]: {
        top: "0 !important",
        bottom: "auto",
        left: "0 !important",
        right: "0",
        maxWidth: "100%",
        borderRadius: "0",
        boxShadow: "none",
      },
      "& .campaign-form-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "40px 40px 14px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 17px",
        },
        "& .heading-left-deals": {
          maxWidth: "335px",
        },
        "& .modal-title": {
          color: "#2B2B2B",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "24px",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          marginBottom: "5px",
        },
        "& .sub-txt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.09px",
        },
        "& .heading-right": {
          display: "flex",
          marginLeft: "10px",
          // kruti pujara
          "& .heading-icon": {
            display: "inline-block",
            width: "20px",
            height: "auto",
            "& img": {
              width: "100%",
              height: "auto",
            },
            marginRight: "15px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .campaign-description": {
        padding: "10px 40px",
        overflowY: "auto",
        height: "auto",
        [theme.breakpoints.down("xs")]: {
          padding: "10px 24px",
        },
        "& .task-form": {
          marginBottom: "15px",
          "& .MuiFormControl-root": {
            "& .MuiFormLabel-root.MuiInputLabel-shrink": {
              "& span": {
                top: "0",
              },
            },
          },
          "& .radio-btn-wrapper-top":{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #E8ECF2",
              marginBottom: "16px",
              paddingBottom: "16px",
              justifyContent: "space-between",
                "& .top-title":{
                  color: "rgba(43, 43, 43, 0.67)",
                  fontSize: "12px",
                  fontFamily: "Expletus Sans",
                  fontWeight: 500,
                  lineHeight: "16px"
                },
                "&>p":{
                  color: "rgba(43, 43, 43, 0.67)",
                  fontSize: "12px",
                  fontFamily: "Expletus Sans",
                  fontWeight: 500,
                  lineHeight: "16px"
                },
                "& .select-control-campaigns":{                  
                  "& .MuiInput-underline":{
                    "&::before":{
                      display:"none",
                    },
                    "&::after":{
                      display:"none",
                    }
                  },
                  "& .MuiSelect-select":{
                    "&.MuiSelect-select":{
                      padding:"0",
                      backgroundColor:"transparent",
                      "& .MuiBox-root":{
                        display:"flex",
                        alignItems:"center",
                        color: "rgba(43, 43, 43, 0.67)",
                        fontSize: "12px",
                        fontFamily: "Expletus Sans",
                        fontWeight: 500,
                        lineHeight: "16px",
                        "& img":{
                          marginLeft:"5px",
                          color: "rgba(43, 43, 43, 0.67)",
                        }
                      }
                    }
                  }
                  
                }
          },
          "& .radio-btn-wrapper": {
            "&.form-row":{
              // backgroundColor:"red",
            },
            "& .un-assign-owner-list": {
              paddingTop: "16px",
              width: "100%",
              "& .select-contact-item": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "8px 0px",
                "& .owner-info": {
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "0",
                  "& .name": {
                    color: "var(--task-completed-color, #2B2B2B)",
                    fontFamily: "Expletus Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  },
                  "& .email": {
                    color: "var(--text-secondary, #94A3B8)",
                    fontFamily: "Expletus Sans",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  },
                  "& .departments": {
                    display: "flex",
                    flexWrap: "wrap",
                    "& .dep-item": {
                      display: "flex",
                      padding: "6px",
                      marginRight: "16px",
                      alignItems: "flex-start",
                      gap: "8px",
                      borderRadius: "6px",
                      background:
                        "var(--background-background-medium, #ECF1F4)",
                      "& .dep-name": {
                        color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                        fontFamily: "Roboto",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      },
                    },
                  },
                },
                "& .checkedCirlce": {
                  color: "#4BA3B7",
                  position: "static",
                  marginLeft: "7px",
                },
              },
              "& .owner-item": {
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "0 0 15px",
                marginBottom: "10px",
                borderBottom: "1px solid #ECF1F4",
                cursor: "pointer",
                justifyContent: "space-between",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .count": {
                  display: "flex",
                  alignItems: "center",
                  "& svg":{
                    marginLeft:"10px",
                    "&.icon-check":{
                      fill:"#4ba3b7",
                    }
                  },
                },
                "& .owner-info": {
                  display: "flex",
                  flexDirection: "column",
                  "& .name": {
                    color: "var(--task-completed-color, #2B2B2B)",
                    fontFamily: "Expletus Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  },
                  "& .email": {
                    color: "var(--text-secondary, #94A3B8)",
                    fontFamily: "Expletus Sans",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  },
                  "& .departments": {
                    display: "flex",
                    flexWrap: "wrap",
                    "& .dep-item": {
                      display: "flex",
                      padding: "6px",
                      marginRight: "16px",
                      alignItems: "flex-start",
                      gap: "8px",
                      borderRadius: "6px",
                      background:
                        "var(--background-background-medium, #ECF1F4)",
                      "& .dep-name": {
                        color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                        fontFamily: "Roboto",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      },
                    },
                  },
                },
                "& .circleCheck": {
                  position: "static",
                  color: "#E8ECF2",
                  marginLeft: "7px",
                },
              },
            },
          },
          "& .step-grid-wrapper": {
            display: "flex",
            flexWrap: "wrap",
            margin: "0 -9x -18px",
            width: "100%",
            "& .step-grid-item": {
              "&.disable": {
                opacity: "0.5",
                pointerEvents: "none",
              },
              padding: "16px",
              border: "1px solid #e0e0e0",
              borderRadius: "12px",
              backgroundColor: "#ffffff",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              marginBottom: "16px",
              width: "100%",
              "&.selected-template":{
                "& h3": {
                  color: "gray",
                },
              },
              "& h3": {
                fontSize: "14px",
                color: "#2B2B2B",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 500,
                fontFamily: "Expletus Sans",
              },
              // flex: "0 0 33.33%",
              // maxWidth: "33.33%",
              //padding: "0 9px 18px",
             
            },
            "& .no-designs-available":{
              backgroundColor: "#f0f7fc",
              padding: "24px",
              borderRadius: "8px",
              textAlign: "center",
              width: "100%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              "& .no-design-modal-title":{
                color: "#2B2B2B",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24px",
                fontFamily: "Expletus Sans",
                letterSpacing: "0",
                marginBottom: "10px",
                textAlign: "left",
              },
              "& .no-design-modal-desc":{
                color: "#2B2B2B",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "26px",
                fontFamily: "Roboto",
                letterSpacing: "0",
                marginBottom: "20px",
                textAlign: "left",
              },
              "& .button-group":{
                display: "flex", 
                justifyContent: "start", 
                gap: "16px",
                "& .blue-border":{
                  color: "#4BA3B7",
                  border: "1px solid #4BA3B7",
                },
              },
            },
          },
          "& .switch-list-wrapper": {
            width: "100%",
            "& .dropdown-menu-item": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              "& + .dropdown-menu-item": {
                marginTop: "25px",
              },
              "& .dropdown-menu-item-text": {
                display: "flex",
                alignItems: "center",
                "& .info-content": {
                  "& .para": {
                    display: "none",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#fff",
                    padding: "24px",
                    zIndex: "2",
                    borderRadius: "12px",
                    minWidth: "327px",
                    maxWidth: "500px",
                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
                    "& .sub-txt": {
                      "& p": {
                        color: "#2b2b2bde",
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Roboto",
                      },
                    },
                  },
                },
                "& .img-score-outline:hover + .para": {
                  display: "block",
                },
                "& img": {
                  marginLeft: "5px",
                },
                "& .custom-tooltip": {
                  backgroundColor: "#FFF",
                  color: "#373737",
                  fontSize: "14px",
                  position: "relative",
                },
              },
              "& .MuiSwitch-colorSecondary": {
                "&.Mui-checked": {
                  color: "#4BA3B7",
                  "& + .MuiSwitch-track": {
                    backgroundColor: "#4BA3B7",
                  },
                },
              },
              "& .custom-input-wrapper": {
                marginBottom: "0",
                flex: "0 0 100%",
                maxWidth: "100%",
                marginTop: "10px",
              },
            },
            "& .primary-btn": {
              marginTop: "30px",
              minWidth: "100%",
            },
          },
          "& .custom-datepicker": {
            "& .datepicker-wrapper": {
              marginBottom: "24px",
            },
            "&> .rmdp-container": {
              width: "100%",
              position:"relative !important",
              "&>input": {
                color: "#2B2B2B",
                width: "100%",
                border: "1px solid #E8ECF2",
                height: "auto",
                padding: "19px 55px 19px 16px",
                fontSize: "14px",
                minHeight: "56px",
                fontFamily: "Roboto",
                fontWeight: 400,
                lineHeight: "normal",
                borderRadius: "6px",
                backgroundImage: `url(${clockOutline})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "calc(100% - 24px) center",
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
            },
          },
          "& .time-picker-outer":{
            position:"relative",
          },
        },
      },
      "& .form-heading": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginBottom: "16px",
      },
      "& .form-info-wrapper": {
        marginBottom: "16px",
        "&:last-child": {
          marginBottom: "0",
        },

        "& .stepper-wrapper": {
          backgroundColor: "red",
          "& .MuiStepper-root": {
            padding: "10px",
            "& .MuiStep-root": {
              padding: "16px",
              border: "1px solid #E8ECF2",
              borderRadius: "12px",
              position: "relative",
              "&.active-campaign-step": {
                borderColor: "#4BA3B7",
              },
              "&.MuiStep-completed": {
                borderColor: "#00BA88",
                "& .MuiStepIcon-root": {
                  borderColor: "#00BA88",
                  color: "#00BA88",
                  backgroundColor: "#00BA88",
                  "& path": {
                    fill: "#FFF",
                  },
                },
                "& .MuiStepIcon-text": {
                  fill: "#00BA88",
                },
                "& .MuiStepContent-root": {
                  "& p": {
                    color: "#00BA88",
                  },
                },
              },
              "& .MuiStepIcon-root": {
                color: "#FFF",
                border: "1px solid #94A3B8",
                borderRadius: "50%",
                "&.MuiStepIcon-active": {
                  borderColor: "#4BA3B7",
                  "& .MuiStepIcon-text": {
                    fill: "#4BA3B7",
                  },
                },
                "& .MuiStepIcon-text": {
                  color: "#94A3B8",
                },
              },
              "& .MuiStepLabel-root": {
                "& .MuiStepLabel-iconContainer": {
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "-12px",
                },
                "& .MuiStepLabel-labelContainer": {
                  "& .MuiStepLabel-label": {
                    "&.MuiStepLabel-active": {
                      color: "#4BA3B7",
                      fontSize: "14px",
                      fontWeight: "500",
                    },
                    "&.MuiStepLabel-completed": {
                      color: "#00BA88",
                    },
                  },
                },
              },
              "& .MuiStepContent-root": {
                margin: "0",
                padding: "0",
                border: "0",
                "& p": {
                  color: "#94A3B8",
                  fontSize: "12px",
                  marginTop: "4px",
                },
                "& .MuiBox-root": {
                  // display:"none",
                },
              },
            },
            "& .MuiStepConnector-vertical": {
              "& .MuiStepConnector-lineVertical": {
                border: "0",
              },
            },
          },
        },
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -8px",
        "&:last-child": {
          "& .form-control": {
            marginBottom: "0",
          },
        },
        "& .form-col": {
          padding: "0 8px",
          flex: "0 0 100%",
          maxWidth: "100%",
          "&.col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          "& .form-control": {
            marginBottom: "16px",
          },
        },
      },
      "& .attachlist-ul": {
        padding: "0",
        "& .attachlist-li": {
          paddingLeft: "0",
          paddingTop: "15px",
          paddingBottom: "15px",
          borderBottom: "1px solid #E8ECF2",
          background: "transparent",
          "&:last-child": {
            borderBottom: "0",
          },
          "& .attachlist-label": {
            color: "#2B2B2B",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            margin: "0",
            "& .MuiTypography-root": {
              font: "inherit",
              letterSpacing: "inherit",
            },
          },
          "& .attachlist-icon": {
            minWidth: "unset",
            marginRight: "15px",
          },
        },
      },
      "& .multiselect-autocomplete": {
        marginBottom: "16px",
        "&.MuiAutocomplete-hasPopupIcon": {
          "&.MuiAutocomplete-hasClearIcon": {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
              paddingRight: "85px",
            },
          },
        },
        "& .MuiOutlinedInput-root": {
          paddingLeft: "16px",
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
              borderWidth: "1px",
            },
          },
        },
        "& .MuiInputAdornment-root": {
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "29px",
          height: "auto",
        },
        "& .start-wrapper": {
          display: "flex",
        },
        "& .end-icon": {
          position: "absolute",
          top: "calc(50% - 12px)",
          right: "60px",
          marginLeft: "0",
        },
        "& .MuiChip-root": {
          backgroundColor: "#ECF1F4",
          borderRadius: "6px",
          padding: "6px 8px",
          height: "auto",
          color: "#4F4F4F",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "17px",
          margin: "0 5px 5px 0",
          "& .MuiChip-label": {
            paddingLeft: "0",
          },
          "& .MuiChip-deleteIcon": {
            width: "17px",
            height: "17px",
            margin: "0",
          },
        },
        "& .MuiAutocomplete-endAdornment": {
          "& .MuiIconButton-root": {
            color: "#94A3B8",
          },
        },
      },
      "& .inventory-wrapper": {
        marginBottom: "12px",
        "& .links-wrapper": {
          display: "flex",
          flexWrap: "wrap",
        },
        "& .inventory-link": {
          padding: "5px 12px",
          borderRadius: "15px",
          border: "1px solid #E8ECF2",
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.09px",
          textDecoration: "none",
          marginRight: "12px",
          marginBottom: "12px",
        },
        "& .items-list-wrapper": {
          "& .items-list": {
            padding: "24px",
            marginBottom: "16px",
            borderRadius: "6px",
            border: "1px solid #E8ECF2",
            "& .items-title": {
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            },
            "& .title-icon": {
              "& .MuiSvgIcon-root": {
                color: "#94A3B8",
                "&.close-icon": {
                  fontSize: "20px",
                },
              },
            },
            "& .primary-txt": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .sub-txt": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& span": {
                color: "#2B2B2B",
              },
            },
          },
        },
      },
      "& .createcampaign-button": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "14px 10px 10px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 10px 15px",
        },
        "& .secondary-btn":{
          borderRadius: "6px",
          borderColor:"#4BA3B7",
          color: "#4BA3B7",
          cursor: "pointer",
          maxWidth: "155px",
        },       
        "& .add-btn-disable": { 
          cursor: "pointer",        
          borderRadius: "6px",
          borderColor:"#92C7D3",
          color: "#FAFCFE",
          background: "#92C7D3",         
        },
        "& .full-btn": {
          width: "100%",
          "& .MuiButton-root": {
            width: "100%",
          },
          "&.step-6": {
            display: "flex",
            justifyContent: "space-between",
            "& .MuiButton-root": {
              maxWidth: "165px",
            },
          },
        },
        "& .primary-btn": {
          minWidth: "165px",
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "24px",
        "& .MuiInputLabel-formControl": {
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#000000",
          opacity: "1",
          fontWeight: "400",
          fontFamily: "Roboto",
          display: "flex",
          alignItems: "center",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .status-name": {
            color: "#2B2B2B",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
          "& .status": {
            width: "12px",
            height: "12px",
            background: "#94A3B8",
            borderRadius: "100%",
            marginLeft: "10px",
            "&.open": {
              background: "#4BA3B7",
            },
            "&.cancelled": {
              background: "#F7E223",
            },
            "&.yellow": {
              background: "#F7E223",
            },
            "&.green": {
              background: "#00BA88",
            },
            "&.orange": {
              background: "#F6A318",
            },
            "&.sky-blue": {
              background: "#12AEF1",
            },

            "&.black": {
              background: "#2B2B2B",
            },
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
          color: "#94A3B8",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
    },
    dropDropdown: {
      "&.changepriority-dropdown": {
        "& .MuiMenu-paper": {
          marginTop: "0",
        },
      },
      "& .MuiMenu-paper": {
        minWidth: "164px",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        marginTop: "12px",
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#2b2b2b",
          padding: "16px 24px",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "flex",
          justifyContent: "flex-start",
          // borderBottom: "1px solid #ECF1F4",
          "& .priority-name": {
            marginRight: "10px",
          },
          "& .priority-icon": {
            marginLeft: "auto",
          },
        },
        "& .MuiList-padding": {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
    },
    fileUploadWrapper: {
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      "& .file-heading": {
        fontSize: "12px",
        lineHeight: "16px",
        margin: "0 0 7px",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.09px",
        marginBottom: "0",
        display: "flex",
        alignItems: "center",
        "& .attach-icon-deals": {
          marginRight: "4px",
        },
      },
      "& .browse-link-file": {
        color: "#4ba3b7",
        cursor: "pointer",
        fontWeight: 700,
        marginLeft: "3px",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    campaigndrawerWrapper: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    fileDropDrag: {
      filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
      backgroundColor: "#fafcfe",
      borderColor: "transparent",
    },
    autocompleteDropbox: {
      padding: "0",
      "& .MuiAutocomplete-paper": {
        borderRadius: "12px",
        background: "#ffffff",
        filter: "none",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      },
      "& .MuiAutocomplete-listbox": {
        padding: "0",
        "& .MuiAutocomplete-option": {
          padding: "0",
          background: "transparent",
        },
      },
      "& .tasklist-li": {
        display: "flex",
        justifyContent: "space-between",
        background: "transparent",
        position: "relative",
        borderBottom: "1px solid #ECF1F4",
        padding: "18px 24px",
        width: "100%",
        "& .tasklist-left": {
          display: "flex",
          alignItems: "center",
          "& .assignee-info": {
            marginLeft: "12px",
          },
          "& .assignee-profile": {
            width: "38px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ECF1F4",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Roboto",
            fontSize: "15.485px",
            fontWeight: 600,
            lineHeight: "21.679px",
            letterSpacing: "-0.395px",
          },
          "& .assignee-name": {
            fontSize: "14px",
            lineHeight: "19px",
            color: "#000000",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
          "& .assignee-email": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .tasklist-icon": {
          minWidth: "unset",
        },
      },
    },
    btnMinimizeDeals: {
      gap: "16px",
      right: "230px",
      bottom: "34px",
      height: "56px",
      display: "flex",
      padding: "14px 16px",
      position: "fixed",
      background: "#FFF",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      alignItems: "center",
      borderRadius: "8px",
      flexDirection: "row",
      "& .expand-icon": {
        width: " 16px",
        height: "16px",
      },
      "& .create-txt": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontStyle: "normal",
        fontFamily: " Expletus Sans",
        fontWeight: 500,
        lineHeight: "normal",
      },
      "& .minimize-close-btn": {
        minWidth: "24px",
      },
      "& .close-icon": {
        width: " 16px",
        height: "16px",
      },
    },
    campaignRootWrapper: {
      "& .MuiBackdrop-root": {
        [theme.breakpoints.down("xs")]: {
          background: "rgba(34, 89, 128, 0.70)",
        },
      },
      "& .drawer-inner": {
        [theme.breakpoints.down("xs")]: {
          padding: "56px 24px",
        },
      },
    },
    campaigndrawer: {
      minWidth: "367px",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      position: "sticky",
      top: "88px",
      maxHeight: "calc(100vh - 88px)",
      [theme.breakpoints.down("md")]: {
        minWidth: "320px",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    campaignPaper: {
      width: "100%",
      position: "static",
      borderRight: "0",
      padding: "18px 40px 18px 28px",
      maxHeight: "100%",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "18px 28px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        position: "fixed",
      },
    },
    dealsdrawerContent: {
      width: "calc(100% - 367px)",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 320px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "& .marketing-inner": {
        "& .MuiTableContainer-root": {
          padding: "15px",
        },
      },
    },
    modalCenterDialogBox: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 580,
      backgroundColor: "#ffffff",
      padding: "48px",
      borderRadius: "24px",
      // [theme.breakpoints.down("md")]: {
      //   padding: "35px 35px 20px",
      //   width: 520,
      // },
      // [theme.breakpoints.down("sm")]: {
      //   padding: "25px 25px 20px",
      //   maxWidth: "90%",
      // },
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
        maxWidth: "327px",
        width: "90%",
        borderRadius: "12px",
        backgroundColor: "#F2F8FC",
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "42px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "23px",
        },
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "30px",
        color: "#2B2B2B",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        // [theme.breakpoints.down("md")]: {
        //   fontSize: "20px",
        //   lineHeight: "24px",
        // },
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
      "& .modal-desc": {
        fontSize: "14px",
        letterSpacing: "0.0075em",
        lineHeight: "26px",
        color: "#2b2b2b",
        fontWeight: 400,
        fontFamily: "Roboto",
        [theme.breakpoints.down("xs")]: {
          fontSize: "13px",
          lineHeight: "17px",
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "24px",
        "& .MuiInputLabel-formControl": {
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
            color: "rgba(43,43,43,0.87)",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: "16px",
            letterSpacing: "0.0075em",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#000000",
          opacity: "1",
          fontWeight: "400",
          fontFamily: "Roboto",
          display: "flex",
          alignItems: "center",
          paddingRight: "45px",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .status-name": {
            color: "#2B2B2B",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "19px",
            letterSpacing: "normal",
          },
          "& .status": {
            width: "12px",
            height: "12px",
            background: "#94A3B8",
            borderRadius: "100%",
            marginLeft: "10px",
            "&.open": {
              background: "#4BA3B7",
            },
            "&.cancelled": {
              background: "#F7E223",
            },
            "&.sky-blue": {
              background: "#12AEF1",
            },
            "&.orange": {
              background: "#F6A318",
            },
            "&.yellow": {
              background: "#F7E223",
            },
            "&.green": {
              background: "#00BA88",
            },
            "&.black": {
              background: "#2B2B2B",
            },
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
          color: "#94A3B8",
          right: "16px",
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "& .form-col": {
            padding: "0 8px",
            flex: "0 0 100%",
            maxWidth: "100%",
            "&.col6": {
              flex: "0 0 50%",
              maxWidth: "50%",
            },
            "& .form-control": {
              marginBottom: "24px",
            },
          },
        },
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "32px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "24px",
        },
        "& .primary-btn, & .secondary-btn ": {
          width: "auto",
          minWidth: "157px",
          [theme.breakpoints.down("xs")]: {
            minWidth: "70px",
            padding: "4px 6px",
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
        },
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
          border: "0",
          color: "#4BA3B7",
        },
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
      "&.deals-modal": {
        "& .modal-heading": {
          marginBottom: "8px",
          "& .modal-title": {
            color: "rgba(43,43,43,0.87)",
            fontSize: "24px",
            lineHeight: "30px",
          },
        },
        "& .sub-txt": {
          letterSpacing: "0.105px",
          color: "#94A3B8",
          marginBottom: "24px",
        },
        "& .modal-form-stage": {
          marginTop: "0",
          "& .MuiListSubheader-gutters": {
            position: "static",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#225980",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "17px 16px 16px",
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
              },
            },
          },
        },
      },
      "&.view-modal-dialog": {
        "& .modal-heading": {
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          borderBottom: "1px solid #E8ECF2",
          padding: "24px 40px",
          marginBottom: "0",
          "& .action-links":{
          marginRight: "10px",
          },
          "& .heading-links": {
            display: "flex",
            alignItems: "center",
            "& .heading-link": {
              marginRight: "24px",
              "&:last-child": {
                marginRight: "0",
              },
            },
          },
          "& .close-icon": {
            color: "#94A3B8",
            lineHeight: "0",
          },
        },
        "& .modal-title": {
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "0",
          "& .priority-status": {
            color: "#C7263E",
            marginRight: "5px",
          },
        },
        "& .modal-description-stage": {
          padding: "24px 40px",
        },
        "& .box-content":{
          padding: "12px 40px",
          "& .border-box":{
            border: "1px solid #E8ECF2",
            borderRadius:"12px",
            padding:" 24px",
            "& .task-form": {
              marginBottom: "15px",
              "& .modal-box-title":{
                color: "#2B2B2B",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Expletus Sans", 
                display:"flex",
              },
              "& .sub-txt": {
                color: "#94A3B8",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Roboto",
                marginTop: "0",
                lineHeight: "26px",
                marginBottom: "15px",
                letterSpacing: "0.105px",
                "& .image":{
                 marginRight:"10px",
                },
              },
              "& .MuiFormControl-root": {
                "& .MuiFormLabel-root.MuiInputLabel-shrink": {
                  "& span": {
                    top: "0",
                  },
                },
              },
              "& .switch-list-wrapper": {
                width: "100%",
                "& .dropdown-menu-item": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  "& + .dropdown-menu-item": {
                    marginTop: "25px",
                  },
                  "& .dropdown-menu-item-text": {
                    display: "flex",
                    alignItems: "center",
                    "& .info-content": {
                      "& .para": {
                        display: "none",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#fff",
                        padding: "24px",
                        zIndex: "2",
                        borderRadius: "12px",
                        minWidth: "327px",
                        maxWidth: "500px",
                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
                        "& .sub-txt": {
                          "& p": {
                            color: "#2b2b2bde",
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                          },
                        },
                      },
                    },
                    "& .img-score-outline:hover + .para": {
                      display: "block",
                    },
                    "& img": {
                      marginLeft: "5px",
                    },
                    "& .custom-tooltip": {
                      backgroundColor: "#FFF",
                      color: "#373737",
                      fontSize: "14px",
                      position: "relative",
                    },
                  },
                  "& .MuiSwitch-colorSecondary": {
                    "&.Mui-checked": {
                      color: "#4BA3B7",
                      "& + .MuiSwitch-track": {
                        backgroundColor: "#4BA3B7",
                      },
                    },
                  },
                  "& .custom-input-wrapper": {
                    marginBottom: "0",
                    flex: "0 0 100%",
                    maxWidth: "100%",
                    marginTop: "10px",
                  },
                },
                "& .primary-btn": {
                  marginTop: "30px",
                  minWidth: "100%",
                },
              },
            },
          },
        },
        "& .modal-description-deal": {
          padding: "24px 40px",
          "& .back-arrow-txt":{
            display: "flex",
            alignItems:"center",
            marginBottom: "24px",
            "& .sub-txt": {
              color: "#94A3B8",
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Expletus Sans",
              margin: "0",
              marginLeft:"4px",
            },
            "& .setting-title": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              marginTop: "0",
              lineHeight: "26px",
            },
          },        
          "& .sub-txt": {
            color: "#94A3B8",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Roboto",
            marginTop: "0",
            lineHeight: "26px",
            marginBottom: "15px",
            letterSpacing: "0.105px",
          },
          "& .blue-link": {
            fontWeight: 600,
            color: "#4BA3B7",
            letterSpacing: "0.105",
            textDecoration: "none",
          },
          "& .header-sub-text":{
            color: "#000",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Expletus Sans",
            display:"flex",
            alignItems:"center",
            "& em":{
              marginRight:"10px",
            },
            "& span":{
              color:"#94A3B8",
            },
          },
        },
        "& .dealview-list": {
          marginBottom: "8px",
          padding: "0",
          "& .status-control-sent": {
            color: "#73D19F",
            backgroundColor: "rgba(0, 186, 136, 0.07)",
            display: "inline-block",
            padding: "1px 11px",
            borderRadius: "12px",
          },
          "& .MuiListItem-root": {
            padding: "16px 0",
            borderBottom: "1px solid #E8ECF2",
            display: "flex",
            alignItems: "center",
            "&:last-child": {
              borderBottom: "0",
            },
            "&.date-item-root": {
              alignItems: "flex-start",
            },
            "& .list-description": {
              letterSpacing: "0.105px",
              color: "#2B2B2B",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "26px",
              paddingLeft: "15px",
              fontFamily: "Roboto",
              width: "67%",
              "& .date-cal-icon": {
                display: "flex",
                alignItems: "center",
                marginLeft: "8px",
                "& .icon": {
                  marginLeft: "8px",
                },
              },
              "& .list-subtitle": {
                letterSpacing: "normal",
                color: "#94A3B8",
                lineHeight: "14px",
                fontSize: "10px",
              },
              "& .assignee-list": {
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  marginLeft: "-4px",
                  color: "rgba(43, 43, 43, 0.87)",
                  letterSpacing: "-0.244px",
                  justifyContent: "center",
                  background: "#ECF1F4",
                  width: "24px",
                  height: "24px",
                  borderRadius: "100%",
                  border: "2px solid #FFF",
                  fontSize: "9.586px",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  lineHeight: "13.42px",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  "&:first-child": {
                    marginLeft: "0",
                  },
                  "&.more-bubble": {
                    cursor: "pointer",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .label": {
                  color: "#94A3B8",
                  minWidth: "67px",
                  textAlign: "right",
                },
                "& .value": {
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
              "& .badges-wrapper": {
                padding: "0",
                display: "flex",
                "&>li": {
                  border: "0",
                  padding: "0",
                  display: "block",
                  width: "auto",
                  "& span.gray-badge": {
                    color: "#2B2B2B",
                    borderRadius: "6px",
                    backgroundColor: "#ECF1F4",
                    display: "inline-block",
                    padding: "3px 9px",
                    fontSize: "12px",
                    fontWeight: 400,
                  },
                  "& + li": {
                    marginLeft: "8px",
                  },
                },
              },
            },
            "& .list-label": {
              letterSpacing: "0.105px",
              fontWeight: 400,
              lineHeight: "26px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "14px",
              width: "33%",
            },
          },
        },
        "& .tasklist-tabwrapper": {
          marginBottom: "45px",
          "&:last-child": {
            marginBottom: "0",
          },
          "& .assignee-tabs": {
            "& .MuiTabs-root": {
              marginBottom: "0",
            },
          },
          "& .MuiTabs-root": {
            borderBottom: "1px solid #E8ECF2",
            marginBottom: "17px",
            "& .MuiTab-root": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textTransform: "capitalize",
              opacity: "0.67",
              minWidth: "unset",
              padding: "16px 24px",
              letterSpacing: "0",
              "& .MuiTouchRipple-root": {
                display: "none",
              },
              "&.Mui-selected": {
                opacity: "1",
              },
            },
          },
          "& .tab-heading": {
            marginBottom: "24px",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            "& .sub-txt": {
              letterSpacing: "0",
              marginBottom: "0",
              fontWeight: 400,
              lineHeight: "17px",
              fontSize: "13px",
              fontFamily: "Roboto",
              color: "#94A3B8",
            },
            "& .primary-txt": {
              fontFamily: "Expletus Sans",
              marginBottom: "5px",
              lineHeight: "21px",
              fontWeight: 500,
              fontSize: "16px",
              letterSpacing: "0",
              color: "#2B2B2B",
            },
            "& .heading-right": {
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              "& .heading-link": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
          },
          "& .attachments-list": {
            "& .list-secondary": {
              right: "0",
              "& .MuiIconButton-root": {
                margin: "0",
                padding: "0",
                color: "#828282",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              },
            },
            "& .list-item": {
              paddingLeft: "0",
              paddingBottom: "16px",
              paddingTop: "16px",
              borderBottom: "1px solid #E8ECF2",
              "& .list-img": {
                marginRight: "8px",
                width: "28px",
              },
              "& .list-name": {
                fontFamily: "Expletus Sans",
                lineHeight: "16px",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
            },
            "& .subheader-title": {
              borderBottom: "1px solid #E8ECF2",
              paddingBottom: "16px",
              justifyContent: "space-between",
              padding: "0",
              position: "static",
              display: "flex",
              "& .title": {
                opacity: "0.67",
                lineHeight: "16px",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
              "& .tasklist-link": {
                fontFamily: "Expletus Sans",
                textDecoration: "none",
                fontSize: "14px",
                opacity: "0.67",
                fontWeight: 500,
                lineHeight: "19px",
                color: "#4BA3B7",
              },
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#4BA3B7",
          },
          "& .description-box": {
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.105px",
            padding: "16px",
            color: "#2B2B2B",
            fontSize: "14px",
            background: "#F2F8FC",
            lineHeight: "26px",
            borderRadius: "12px",
          },
          "& .deals-task-wrap": {
            marginBottom: "16px",
            "&:first-child": {
              "& .deal-task-heading": {
                paddingTop: "0",
              },
            },
            "&:last-child": {
              marginBottom: "0",
            },
            "& .deal-task-heading": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 0",
              "& .deal-heading": {
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
              },
              "& .deal-total-task": {
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
            },
            "& .deal-task-ul": {
              padding: "0",
              width: "100%",
              "& .deal-task-ul": {
                paddingLeft: "32px",
              },
              "& .deal-task-li": {
                alignItems: "flex-start",
                background: "transparent",
                flexDirection: "column",
                padding: "0",
              },
              "& .deal-inner": {
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
                cursor: "pointer",
                padding: "16px 0",
                borderBottom: "1px solid #E8ECF2",
              },
              "& .tasklist-icon": {
                minWidth: "unset",
                marginRight: "16px",
                "& .MuiIconButton-root": {
                  padding: "0",
                },
              },
              "& .tasklist-label": {
                margin: "0",
                flex: "1",
              },
            },
            "& .task-title": {
              display: "flex",
              justifyContent: "space-between",
              "& .title": {
                color: "#2B2B2B",
                fontSize: "14px",
                lineHeight: "19px",
                fontFamily: "Expletus Sans",
                fontWeight: 400,
                "& .priority-status": {
                  color: "#C7263E",
                  marginRight: "3px",
                },
              },
              "& .title-right": {
                marginLeft: "10px",
                "& .controller-link": {
                  width: "22px",
                },
              },
            },
            "& .task-desc": {
              display: "flex",
              justifyContent: "space-between",
              color: "#94A3B8",
              fontSize: "12px",
              lineHeight: "16px",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.09px",
              marginTop: "3px",
              "& .desc-left": {
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              },
              "& .task-about": {
                paddingLeft: "14px",
                marginLeft: "8px",
                position: "relative",
                "&:before": {
                  content: '""',
                  width: "6px",
                  height: "6px",
                  borderRadius: "100%",
                  background: "#E8ECF2",
                  display: "inline-block",
                  position: "absolute",
                  left: "0",
                  top: "5px",
                },
              },
              "& .timing": {
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "17px",
                letterSpacing: "0.125px",
              },
            },
          },
        },
      },

      "& .modal-dialoginner": {
        padding: "80px 60px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        [theme.breakpoints.down("xs")]: {
          padding: "24px",
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
        "& .MuiRadio-root": {
          color: "#94A3B8",
          padding: "0",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
      },
      "& .modal-heading": {
        marginBottom: "20px",
        display: "flex",
        justifyContent: "space-between",
        "& .modal-title ": {
          fontWeight: 400,
          fontSize: "22px",
          lineHeight: "26px",
          fontFamily: "Expletus Sans",
          color: "#2b2b2b",
        },
        "& .heading-left-deals": {
          display: "flex",
          alignItems: "center",
          "& .auth-back": {
            cursor: "pointer",
            marginRight: "8px",
            opacity: "0.67",
            color: "#2B2B2B",
          },
          "& .modal-title ": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            color: "#2b2b2b",
          },
        },
        "& .close-icon": {
          color: "#94A3B8",
          lineHeight: "0",
        },
      },
      "& .modal-description-stage": {
        "& .datepicker-wrapper": {
          backgroundColor:'red',
          "& .rmdp-container": {
            position:"relative",
            "& .rmdp-input": {
              paddingLeft: "16px",
              letterSpacing: "0.105px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
            },
          },
        },
        "& .select-outer": {
          width: "100%",
          marginBottom: "24px",
          "& .MuiInputLabel-formControl": {
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "19px",
            letterSpacing: "0.1px",
            color: "#94A3B8",
            display: "flex",
            width: "calc(100% - 46px)",
            "&.MuiFormLabel-filled": {
              width: "auto",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0",
            color: "#000000",
            opacity: "1",
            fontWeight: "400",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "center",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .status-name": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "26px",
              letterSpacing: "0.105px",
            },
            "& .status": {
              width: "12px",
              height: "12px",
              background: "#94A3B8",
              borderRadius: "100%",
              marginLeft: "10px",
              "&.open": {
                background: "#4BA3B7",
              },
              "&.cancelled": {
                background: "#F7E223",
              },
              "&.sky-blue": {
                background: "#12AEF1",
              },
              "&.orange": {
                background: "#F6A318",
              },
              "&.yellow": {
                background: "#F7E223",
              },
              "&.green": {
                background: "#00BA88",
              },
              "&.black": {
                background: "#2B2B2B",
              },
            },
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: "#94A3B8",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              fontSize: "18px",
              lineHeight: "23px",
              marginLeft: "auto",
            },
          },
        },

        "& .task-progress-block": {
          "& .progress-info": {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "16px",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "14px",
            textTransform: "uppercase",
            "& .total-value": {
              color: "#94A3B8",
            },
          },
          "& .progress-wrap": {
            width: "100%",
            height: "4px",
            background: "#ECF1F4",
            borderRadius: "32px",
            "& .progress-inner": {
              background: "rgba(75, 163, 183, 0.37)",
              height: "inherit",
              borderRadius: "inherit",
              maxWidth: "100%",
              "&.inprogress": {
                background: "#4BA3B7",
              },
              "&.completed": {
                background: "#00BA88",
              },
            },
            "& .current-progress": {
              background: "#4BA3B7",
              height: "inherit",
              borderRadius: "16px",
              maxWidth: "100%",
              borderLeft: "4px solid #fff",
              marginLeft: "auto",
            },
          },
        },
        "& .task-subtitle": {
          color: "rgba(43, 43, 43, 0.67)",
          fontFamily: "Roboto",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 400,
          letterSpacing: "0.09px",
          marginBottom: "8px",
        },
        "& .tasklist-tabwrapper": {
          marginBottom: "48px",
          "&:last-child": {
            marginBottom: "0",
          },
          "& .assignee-tabs": {
            "& .MuiTabs-root": {
              marginBottom: "0",
            },
          },
          "& .MuiTabs-root": {
            borderBottom: "1px solid #E8ECF2",
            marginBottom: "17px",
            "& .MuiTab-root": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textTransform: "capitalize",
              opacity: "0.67",
              minWidth: "unset",
              padding: "16px 24px",
              "&.Mui-selected": {
                opacity: "1",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#4BA3B7",
          },
          "& .description-box": {
            borderRadius: "12px",
            background: "#F2F8FC",
            padding: "16px",
            color: "#2B2B2B",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "26px",
            letterSpacing: "0.105px",
            fontFamily: "Roboto",
          },
          "& .attachments-list": {
            "& .subheader-title": {
              position: "static",
              display: "flex",
              justifyContent: "space-between",
              padding: "0",
              paddingBottom: "16px",
              borderBottom: "1px solid #E8ECF2",
              "& .title": {
                color: "#2B2B2B",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                lineHeight: "16px",
                opacity: "0.67",
              },
              "& .tasklist-link": {
                color: "#4BA3B7",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                lineHeight: "19px",
                opacity: "0.67",
                textDecoration: "none",
              },
            },
            "& .list-item": {
              borderBottom: "1px solid #E8ECF2",
              paddingTop: "16px",
              paddingBottom: "16px",
              paddingLeft: "0",
              "& .list-img": {
                width: "28px",
                marginRight: "8px",
              },
              "& .list-name": {
                color: "#2B2B2B",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "Expletus Sans",
              },
            },
            "& .list-secondary": {
              right: "0",
              "& .MuiIconButton-root": {
                padding: "0",
                margin: "0",
                color: "#828282",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              },
            },
          },
          "& .tab-heading": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "24px",
            "& .heading-right": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& .heading-link": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
            "& .primary-txt": {
              color: "#2B2B2B",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "21px",
              letterSpacing: "0",
              marginBottom: "5px",
              fontFamily: "Expletus Sans",
            },
            "& .sub-txt": {
              color: "#94A3B8",
              fontSize: "13px",
              fontWeight: 400,
              lineHeight: "17px",
              letterSpacing: "0",
              fontFamily: "Roboto",
              marginBottom: "0",
            },
          },
        },
        "& .taskview-list": {
          marginBottom: "24px",
          "& .MuiListItem-root": {
            padding: "16px 0",
            borderBottom: "1px solid #E8ECF2",
            display: "flex",
            alignItems: "center",
            "&:last-child": {
              borderBottom: "0",
            },
            "& .list-label": {
              width: "40%",
              fontSize: "14px",
              lineHeight: "26px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.105px",
            },
            "& .list-description": {
              width: "60%",
              paddingLeft: "15px",
              fontSize: "14px",
              lineHeight: "26px",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontWeight: 400,
              letterSpacing: "0.105px",
              "& .list-subtitle": {
                fontSize: "10px",
                lineHeight: "14px",
                color: "#94A3B8",
                letterSpacing: "normal",
              },
              "& .assignee-list": {
                display: "flex",
                alignItems: "center",
                "& .assignee-bubble": {
                  lineHeight: "13.42px",
                  letterSpacing: "-0.244px",
                  position: "relative",
                  marginLeft: "-4px",
                  background: "#ECF1F4",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "100%",
                  border: "2px solid #FFF",
                  color: "rgba(43, 43, 43, 0.87)",
                  fontSize: "9.586px",
                  fontFamily: "Roboto",
                  fontWeight: 600,

                  "&.more-bubble": {
                    cursor: "pointer",
                  },
                  "&:first-child": {
                    marginLeft: "0",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",

                "& .label": {
                  color: "#94A3B8",
                },
                "& .value": {
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
            },
          },
        },

        "& .activitylist-ul": {
          padding: "0",
          "& .activity-li": {
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
            marginBottom: "32px",
            background: "transparent",
            position: "relative",
            "&:last-child": {
              marginBottom: "0",
              "&:before": {
                display: "none",
              },
            },
            "&:before": {
              content: "''",
              borderRight: "1px solid #E8ECF2",
              position: "absolute",
              top: "100%",
              left: "16px",
              height: "100%",
            },
            "& .activity-details": {
              margin: "0",
              "& .activity-info": {
                color: "#2B2B2B",
                fontSize: "12px",
                lineHeight: "16px",
                fontFamily: "Roboto",
                fontWeight: 400,
                marginBottom: "2px",
                letterSpacing: "0.09px",
                "& .name": {
                  color: "#4BA3B7",
                  marginRight: "5px",
                },
              },
              "& .activity-date": {
                color: "#94A3B8",
                fontSize: "10px",
                lineHeight: "17px",
                fontFamily: "Roboto",
                fontWeight: 400,
                letterSpacing: "0.125px",
              },
            },
            "& .activity-icon": {
              minWidth: "unset",
              width: "32px",
              height: "32px",
              marginRight: "16px",
            },
          },
        },

        "& .tasklist-member": {
          padding: "0",
          "& .tasklist-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            borderBottom: "1px solid #ECF1F4",
            padding: "26px 24px 18px",
            "& .tasklist-left": {
              display: "flex",
              alignItems: "center",
              "& .assignee-info": {
                marginLeft: "12px",
              },
              "& .assignee-profile": {
                width: "38px",
                height: "38px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ECF1F4",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontWeight: 600,
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
              "& .assignee-name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
              "& .assignee-email": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .tasklist-icon": {
              minWidth: "unset",
              marginLeft: "10px",
            },
            "& .tasklist-hours": {
              textAlign: "right",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& .hours": {
                display: "block",
                color: "#94A3B8",
                fontWeight: 400,
                marginTop: "3px",
              },
            },
          },
          "& .tasklist-total": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "16px 24px",
            borderBottom: "1px solid #ECF1F4",
            position: "static",
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .filter-select-campaign": {
              "&.sortby-control": {
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  fontSize: "20px",
                  width: "20px",
                  height: "20px",
                  color: "#2B2B2B",
                  top: "calc(50% - 11px)",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          "& .primary-txt": {
            color: "rgba(43, 43, 43, 0.87)",
            fontSize: "24px",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            lineHeight: "32px",
            marginBottom: "6px",
          },
          "& .sub-txt": {
            fontSize: "14px",
            lineHeight: "26px",
            color: "#94A3B8",
            letterSpacing: "0.105px",
            marginBottom: "0",
          },
        },
        "& .template-list": {
          padding: "0",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "0",
            paddingRight: "0",
            position: "static",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#225980",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "17px 16px 16px",
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
              },
            },
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "auto",
            maxWidth: "unset",
            justifyContent: "space-between",
            padding: "17px 24px",
            margin: "0",
            borderBottom: "1px solid #ECF1F4",
            opacity: "0.67",
            [theme.breakpoints.down("xs")]: {
              padding: "15px 0",
              margin: "0",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .filter-select-campaign": {
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
          },
          "& .template-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            padding: "21px 0",
            borderBottom: "1px solid #ECF1F4",
            "& .template-left": {
              "& .name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                marginBottom: "4px",
              },
              "& .sub-txt": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                letterSpacing: "0",
                marginBottom: "0",
              },
            },
            "& .template-icon": {
              minWidth: "unset",
              marginLeft: "10px",
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
        },
        "& .multiselect-membres": {
          display: "flex",
          alignItems: "center",
          width: "auto",
          maxWidth: "unset",
          justifyContent: "space-between",
          padding: "15px 60px",
          margin: "0 -40px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            padding: "15px 0",
            margin: "0",
          },
          "& .total-member": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "19px",
          },
          "& .filter-select-campaign": {
            "& .MuiSelect-select": {
              color: "#2b2b2b",
              opacity: "1",
              fontSize: "12px",
              letterSpacing: "0",
              paddingRight: "0",
            },
            "& .down-arrow": {
              fontSize: "20px",
              marginLeft: "6px",
            },
          },
        },

        "& .assignee-multiSelect-list": {
          paddingTop: "0",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "0",
            paddingRight: "0",
            position: "static",
            "&.multiselect-subheader": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              borderBottom: "1px solid #ECF1F4",
              padding: "15px 20px",
              marginBottom: "5px",
              "& .tasklist-link": {
                marginLeft: "10px",
                color: "#C7263E",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                textDecoration: "none",
              },
            },
            "&.multiselect-subtxt": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "normal",
              textTransform: "uppercase",
              padding: "10px 20px",
              borderBottom: "1px solid #ECF1F4",
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
          "& .MuiListItem-root": {
            padding: "15px 20px",
            cursor: "pointer",
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "15px 20px",
            borderBottom: "1px solid #ECF1F4",
            margin: "0",
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .filter-select-campaign": {
              "&.sortby-control": {
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  top: "calc(50% - 11px)",
                  color: "#2b2b2b",
                  fontSize: "20px",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
          "& .assignee-detail": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            "& .assignee-left": {
              display: "flex",
              "& .assignee-info": {
                marginLeft: "10px",
              },
              "& .assignee-profile": {
                width: "39px",
                height: "39px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ECF1F4",
                borderRadius: "100%",
                color: "rgba(43, 43, 43, 0.87)",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontWeight: 600,
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
              "& .assignee-name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
              "& .assignee-email": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .assignee-right": {
              marginLeft: "10px",
              "& .assinee-selected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "none",
                },
                "& .selected-img": {
                  display: "block",
                },
              },
              "& .assinee-unselected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "block",
                },
                "& .selected-img": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .project-create-wrapper": {
          textAlign: "center",
          marginBottom: "75px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "35px",
          },
          "& .project-img": {
            marginBottom: "40px",
          },
          "& .modal-title": {
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 400,
            marginBottom: "7px",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "18px",
              lineHeight: "24px",
            },
          },
          "& .sub-txt": {
            letterSpacing: "0.25px",
          },
        },
        "& .no-task-block": {
          textAlign: "center",
          padding: "31px 0 95px",
          "& .task-img": {
            marginBottom: "16px",
          },
          "& .heading": {
            color: "#2B2B2B",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            marginBottom: "8px",
          },
          "& .sub-txt": {
            color: "#94A3B8",
            letterSpacing: "0.105px",
            marginBottom: "0",
          },
          "& .button-wrapper": {
            marginTop: "30px",
            "& .MuiButton-root": {
              maxWidth: "327px",
              color: "#4BA3B7",
              borderColor: "#4BA3B7",
              minHeight: "48px",
              "&:hover": {
                color: "#4BA3B7",
                borderColor: "#4BA3B7",
              },
            },
          },
        },

        "& .budget-wrapper": {
          "& .budget-main": {
            "& .MuiSlider-root": {
              height: 23,
              borderRadius: "24px",
              padding: "0",
              "& .MuiSlider-rail": {
                background: "#00BA88",
                opacity: "0.17",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-track": {
                border: "none",
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-thumb": {
                border: "0",
                height: "23px",
                marginTop: "0",
                borderRadius: "0",
                backgroundColor: "transparent",
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&:before": {
                  display: "none",
                },
              },
              "& .MuiSlider-valueLabel": {
                // display: "none",
              },
            },
            "& .slider-wrap": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              padding: "16px",
              marginBottom: "24px",
              "&.overflow-count": {
                "& .legends-dots": {
                  background: "#F5DADE",
                  "&.actual": {
                    background: "#C7263E",
                  },
                },
                "& .progress-wrap": {
                  background: "#F5DADE",
                  "& .progress-inner": {
                    background: "#C7263E",
                  },
                  "& .progress-label": {
                    color: "#2B2B2B",
                  },
                },
              },
              "& .slider-heading": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "14px",
              },
              "& .slider-label": {
                color: "#94A3B8",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "19px",
                fontFamily: "Expletus Sans",
              },
              "& .legends-wrapper": {
                display: "flex",
                alignItems: "center",
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
              "& .slider-legends": {
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
                "&:last-child": {
                  marginRight: "0",
                },
              },
              "& .legends-dots": {
                width: "11px",
                height: "11px",
                background: "#D4F3EB",
                borderRadius: "100%",
                marginLeft: "8px",
                "&.actual": {
                  background: "#00BA88",
                },
              },
              "& .slider-inner": {
                display: "flex",
                alignItems: "center",
                "& .max-value": {
                  marginLeft: "12px",
                  color: "#2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiSlider-valueLabel": {
                  transform: "none",
                  transition: "none",
                  top: "0",
                  bottom: "0",
                  "& > span": {
                    width: "auto",
                    height: "23px",
                    transform: "none",
                    borderRadius: "0",
                    background: "transparent",
                    "& > span": {
                      color: "#2B2B2B",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      transform: "none",
                    },
                  },
                },
              },
            },
            "& .progress-wrap": {
              width: "100%",
              height: "23px",
              background: "rgba(0, 186, 136, 0.17)",
              borderRadius: "24px",
              position: "relative",
              "& .progress-inner": {
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
                maxWidth: "100%",
                borderRight: "3px solid #fff",
              },
              "& .progress-label": {
                textAlign: "center",
                color: "#fff",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "23px",
                letterSpacing: "0.09px",
                height: "inherit",
                maxWidth: "100%",
                position: "absolute",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
              },
            },
            "& .accordion-block": {
              boxShadow: "none",
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              minHeight: "unset",
              padding: "16px 15px",
              "&:before": {
                display: "none",
              },
              "& .accordion-heading": {
                minHeight: "unset",
                padding: "0",
                alignItems: "flex-start",
                "& .heading": {
                  width: "100%",
                  color: "#2B2B2B",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontWeight: 500,
                  marginBottom: "8px",
                },
                "& .sub-txt": {
                  marginBottom: "0",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                },
                "& .MuiIconButton-root": {
                  padding: "0",
                  margin: "0",
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px",
                    color: "#94A3B8",
                  },
                },
              },
              "& .accordion-details": {
                padding: "0",
                marginTop: "24px",
                "& table": {
                  "& tr": {
                    "& td": {
                      "&.MuiTableCell-root": {
                        color: "#2B2B2B",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        padding: "6px 8px 24px",
                        border: "0",
                        "&:first-child": {
                          paddingLeft: "0",
                          width: "43.5%",
                        },
                        "&:nth-child(2)": {
                          textAlign: "center",
                        },
                        "&:last-child": {
                          paddingRight: "0",
                          textAlign: "right",
                        },
                      },
                      "& .open-icon": {
                        display: "inline-block",
                        marginLeft: "3px",
                        position: "relative",
                        top: "2px",
                        "& .MuiSvgIcon-root": {
                          color: "#94A3B8",
                          fontSize: "14px",
                        },
                      },
                    },
                    "& th": {
                      border: "0",
                    },
                    "& .table-heading": {
                      color: "#94A3B8",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      padding: "0",
                      "&.last": {
                        padding: "8px 0 24px",
                      },
                    },
                  },
                },
              },
            },
          },
          "& .budget-footer": {
            borderTop: "1px dashed rgba(148, 163, 184, 0.37)",
            paddingTop: "20px",
            marginTop: "24px",
            textAlign: "right",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 400,
          },
        },
        "& .button-wrapper": {
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "32px",
          },
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#2b2b2b",
        letterSpacing: "0",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "27px",
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "& .form-col": {
            padding: "0 8px",
            flex: "0 0 100%",
            maxWidth: "100%",
            "&.col6": {
              flex: "0 0 50%",
              maxWidth: "50%",
            },
            "& .form-control": {
              marginBottom: "24px",
            },
          },
        },
      },
      "& .form-heading": {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        margin: "0 0 16px",
        letterSpacing: "0",
      },
      "& .profile-team": {
        "& .team-label": {
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontFamily: "Roboto",
        },
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        fontWeight: "500",
        "& .modal-link": {
          marginLeft: "3px",
        },
      },
      "& .modal-form-stage": {
        marginTop: "47px",
      },
      "& .current-team": {
        color: "#94A3B8",
        padding: "17px 16px",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px",
      },
      "& .filter-select-campaign": {
        "&.multi-select": {
          "&.full-outlined": {
            width: "100%",
            marginBottom: "24px",
            "& .multiselect-label": {
              display: "block",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              letterSpacing: "0.105px",
            },
            "& .MuiSelect-select": {
              fontFamily: "Roboto",
              fontWeight: 400,
              color: "#2B2B2B",
              padding: "0 40px 0 16px",
              lineHeight: "20px",
              letterSpacing: "0.105px",
              minHeight: "56px",
              "& .assignee-list": {
                marginLeft: "0",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "rgba(43,43,43,0.87)",
              width: "auto",
              "& ~.MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e8ecf2",
                },
              },
              "&.Mui-error": {
                color: "rgba(43,43,43,0.87)",
              },
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              zIndex: 1,
              color: "#94A3B8",
              top: "50%",
              right: "27px",
              transform: "translateY(-50%)",
            },
          },
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
            display: "flex",
            alignItems: "center",
            "& .select-icon": {
              marginRight: "6px",
            },
            "& .assignee-list": {
              display: "flex",
              alignItems: "center",
              marginLeft: "6px",
              "& .assignee-bubble": {
                color: "rgba(43, 43, 43, 0.87)",
                width: "24px",
                border: "2px solid #FFF",
                height: "24px",
                display: "flex",
                position: "relative",
                fontSize: "9.586px",
                background: "#ECF1F4",
                alignItems: "center",
                fontFamily: "Roboto",
                fontWeight: 600,
                lineHeight: "13.42px",
                marginLeft: "-4px",
                borderRadius: "100%",
                letterSpacing: "-0.244px",
                justifyContent: "center",
                "&:first-child": {
                  marginLeft: 0,
                },
              },
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
        },
        "&.status-control": {
          textTransform: "capitalize",
          "&.sky-blue": {
            color: "#12AEF1",
            "& .MuiSelect-select": {
              background: "rgba(148, 163, 184, 0.07)",
              color: "##12AEF1",
            },
            "& .MuiSelect-icon": {
              color: "#12AEF1",
            },
          },
          "&.orange": {
            color: "#F6A318",
            "& .MuiSelect-select": {
              background: "rgba(246, 163, 24, 0.07)",
              color: "#F6A318",
            },
            "& .MuiSelect-icon": {
              color: "#F6A318",
            },
          },
          "&.yellow": {
            color: "#F7E223",
            "& .MuiSelect-select": {
              background: "rgba(247, 226, 35, 0.07)",
              color: "#F7E223",
            },
            "& .MuiSelect-icon": {
              color: "#F7E223",
            },
          },
          "&.green": {
            color: "#00BA88",
            "& .MuiSelect-select": {
              background: "rgba(0, 186, 136, 0.07)",
              color: "#00BA88",
            },
            "& .MuiSelect-icon": {
              color: "#00BA88",
            },
          },

          "&.black": {
            color: "#2B2B2B",
            "& .MuiSelect-select": {
              background: "rgba(43, 43, 43, 0.07)",
              color: "#2B2B2B",
            },
            "& .MuiSelect-icon": {
              color: "#2B2B2B",
            },
          },

          "&.cancelled": {
            "& .MuiSelect-select": {
              background: "rgba(247, 226, 35, 0.07)",
              color: "#F7E223",
            },
            "& .MuiSelect-icon": {
              color: "#F7E223",
            },
          },
          "&.on-track": {
            "& .MuiSelect-select": {
              color: "#12AEF1",
            },
            "& .MuiSelect-icon": {
              color: "#12AEF1",
            },
          },
          "& .MuiSelect-icon": {
            width: "16px",
            height: "16px",
            right: "6px",
            color: "#12AEF1",
            top: "calc(50% - 8px)",
          },
          "& .MuiSelect-select": {
            color: "#12AEF1",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            borderRadius: "12px",
            background: "rgba(18, 174, 241, 0.07)",
            padding: "6px 22px 6px 12px",
          },
        },

        "& .select-icon": {
          position: "relative",
          marginRight: "6px",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "& .modal-footer": {
        marginTop: "auto",
        "& .half-btn": {
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          "& .MuiButton-root": {
            width: "auto",
            marginBottom: "0",
            marginRight: "10px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&.secondary-btn": {
              minWidth: "140px",
            },
          },
        },
      },
      "& .primary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .secondary-btn": {
        minHeight: "56px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "17px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .date-info": {
        "& .value": {
          "& img": {
            marginLeft: "5px",
          },
        },
      },
      "& .modal-grid-outer": {
        padding: "24px 40px",
        "& .modal-grid-main": {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          "& .modal-grid-item": {
            padding: "0 14px 28px",
            "&.wide": {
              // flex:"0 0 70%",
              // maxWidth:"70%",
              "& .modal-grid-item-inner": {
                justifyContent: "space-between",
                alignItems: "center",
              },
            },
            "&.thin": {
              // flex:"0 0 30%",
              // maxWidth:"30%",
              "& .modal-grid-item-inner": {
                minHeight: "250px",
                flexDirection: "column",
                justifyContent: "center",
                "& .icon-block": {
                  marginBottom: "35px",
                },
              },
            },
            "&.delivered": {
              gridColumn: "1 / 3",
              gridRow: "1 / 2",
            },
            "&.openers": {
              gridColumn: "3 / 4",
              gridRow: "1 / 3",
            },
            "&.clicks": {
              gridColumn: "1 / 2",
              gridRow: "2 / 4",
            },
            "&.unsubscribers": {
              gridColumn: "2 / 4",
              gridRow: "3 / 4",
            },
            "& .modal-grid-item-inner": {
              borderRadius: "21px",
              border: "2px solid #E8ECF2",
              padding: "25px",
              display: "flex",
              "& .desc-title": {
                fontSize: "30px",
                fontWeight: 400,
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                marginBottom: "5px",
                display: "flex",
                alignItems: "flex-end",
                lineHeight: 1,
                "& span": {
                  fontSize: "18px",
                  fontFamily: "Roboto",
                  display: "inline-block",
                  marginLeft: "5px",
                },
              },
              "& .desc-text": {
                fontSize: "22px",
                fontWeight: 400,
                color: "#4f4f4f",
                fontFamily: "Expletus Sans",
              },
              "& .icon-block": {
                height: "65px",
                width: "65px",
                backgroundColor: "rgba(236, 241, 244, 0.37)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "20px",
                border: "1px solid rgba(236, 241, 244, 0.37)",
              },
            },
          },
        },
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    chooseModalBox: {
      display: "flex",
      justifyContent: "flex-end",
    },
    dealOwnersModal: {
      width: "680px",
      height: "100vh",
      backgroundColor: "#ffffff",
      position: "relative",
      overflow: "auto",
      // padding: "0px 60px",
      "& .modal-heading": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginTop: "80px",
        padding: "0px 60px",
        "& .gr-heading-left": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .nav-text": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginLeft: "10px",
          },
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
        },
        "& .heading-right": {
          dispplay: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .plus-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
            marginLeft: "16px",
          },
        },
      },
      "& .modal-title-box": {
        padding: "0px 60px",
        margin: "45px 0px",
        "& .title-txt": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
        },
        "& .sub-title-txt": {
          color: "var(--task-completed-color, #2B2B2B)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .search-box": {
        margin: "24px 0",
        padding: "0px 60px",
      },
      "& .assign-owner": {
        "& .selected-owner-header": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 84px 20px",
          paddingBottom: "15px",
          borderBottom: "1px solid #ECF1F4",
          "& .selected-owner-txt": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .remove-contact-txt": {
            color: "#c7263e",
            fontSize: "14px",
            fontStyle: "normal",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            lineHeight: "normal",
            textTransform: "none",
          },
        },
        "& .selected-owner": {
          "& .select-owner-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "var(--background-background-light, #F2F8FC)",
            padding: "8px 84px",
            cursor: "pointer",
            "& .count": {
              display: "flex",
              alignItems: "center",
            },
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 84,
              color: "#4BA3B7",
            },
          },
        },
        "& .selected-contact": {
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#FFF",
            padding: "0 0 15px",
            margin: "0 84px 15px",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .contact-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 84,
              color: "#4BA3B7",
            },
          },
        },
      },
      "& .un-assign-owner": {
        marginTop: "20px",
        padding: "0 60px",
        "& .top-title": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
        },
        "& .top-list": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
          "& .count-owner": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            opacity: "0.5",
          },
          "& .select-control": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                top: "3px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              opacity: "0.671",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .un-assign-owner-list": {
          paddingTop: "16px",
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px 0px",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 60,
              color: "#4BA3B7",
            },
          },
          "& .owner-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0 0 15px",
            marginBottom: "10px",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "&:last-child": {
              marginBottom: "0",
            },
            "& .count": {
              display: "flex",
              alignItems: "center",
            },
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .circleCheck": {
              position: "absolute",
              right: 60,
              color: "#E8ECF2",
            },
          },
        },
        "& .bottom-button": {
          bottom: "24px",
          width: "100%",
          textTransform: "none",
          textDecoration: "none",
          color: "white",
          display: "flex",
          height: "48px",
          padding: "8px 24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          background: "var(--brand-color-primary-100, #4BA3B7)",
        },
      },
    },
    existingCampaignModal: {
      width: "680px",
      height: "100vh",
      backgroundColor: "#ffffff",
      position: "relative",
      overflow: "auto",
      // padding: "0px 60px",
      "& .continue-btn":{
       display:"flex",
       alignItems:"center",
       justifyContent:"center",
       "& .primary-btn": {
        minWidth: "80%",
        marginTop: "100px",
        marginBottom: "20px",
      },
      },
      "& .modal-heading": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginTop: "80px",
        padding: "0px 60px",
        "& .gr-heading-left": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .nav-text": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginLeft: "10px",
          },
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
        },
        "& .heading-right": {
          dispplay: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .plus-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
            marginLeft: "16px",
          },
        },
      },
      "& .modal-title-box": {
        padding: "0px 60px",
        margin: "45px 0px",
        "& .title-txt": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
        },
        "& .sub-title-txt": {
          color: "var(--task-completed-color, #2B2B2B)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .search-box": {
        margin: "24px 0",
        padding: "0px 60px",
        "& .MuiOutlinedInput-root": {
          flexDirection: "row-reverse",
        },
      },
      "& .existing-campaigns": {
        "& .selected-owner-header": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 84px 20px",
          paddingBottom: "15px",
          borderBottom: "1px solid #ECF1F4",
          "& .selected-owner-txt": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .remove-contact-txt": {
            color: "#c7263e",
            fontSize: "14px",
            fontStyle: "normal",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            lineHeight: "normal",
            textTransform: "none",
          },
        },
        "& .selected-owner": {
          "& .select-owner-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "var(--background-background-light, #F2F8FC)",
            padding: "8px 84px",
            cursor: "pointer",
            "& .count": {
              display: "flex",
              alignItems: "center",
            },
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 84,
              color: "#4BA3B7",
            },
          },
        },
        "& .selected-contact": {
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#FFF",
            padding: "0 0 15px",
            margin: "0 84px 15px",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .contact-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 84,
              color: "#4BA3B7",
            },
          },
        },
      },
      "& .un-assign-owner": {
        marginTop: "20px",
        padding: "0 60px",
        "& .un-assign-owner-top": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "15px",
          position: "relative",
          "&::after": {
            position: "absolute",
            content: '""',
            width: "100vw",
            height: "1px",
            top: "100%",
            left: "-60px",
            right: "0",
            backgroundColor: "#e8ecf2",
          },
          "& .MuiInput-root.MuiInputBase-formControl": {
            "&::before": {
              display: "none",
            },
            "&::after": {
              display: "none",
            },
            "& .MuiSelect-root": {
              paddingRight: "0",
              "& .MuiBox-root": {
                display: "flex",
                alignItems: "center",
                color: "#2b2b2b5e",
                "& img": {
                  marginLeft: "5px",
                },
              },
            },
          },
        },
        "& .top-title": {
          color: "#2b2b2b5e",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
        },
        "& .top-list": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
          "& .count-owner": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            opacity: "0.5",
          },
          "& .select-control-campaigns": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                top: "3px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              opacity: "0.671",
              fontWeight: "500",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                "& .sort-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .un-assign-owner-list": {
          paddingTop: "16px",
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px 0px",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 60,
              color: "#4BA3B7",
            },
          },
          "& .owner-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0 0 15px",
            marginBottom: "10px",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "&:last-child": {
              marginBottom: "0",
            },
            "& .count": {
              display: "flex",
              alignItems: "center",
            },
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "39px",
              width: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "15.485px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21.679px",
                letterSpacing: "-0.395px",
              },
            },
            "& .owner-info": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .circleCheck": {
              position: "absolute",
              right: 60,
              color: "#E8ECF2",
            },
            "& svg":{
              marginLeft:"10px",
              "&.icon-check":{
                fill:"#4ba3b7",
                position: "absolute",
                right: 60,
              },
              "&.circle-check": {
                position: "absolute",
                right: 60,
                color: "#E8ECF2",
              },

            },
          },
        },
        "& .bottom-button": {
          bottom: "24px",
          width: "100%",
          textTransform: "none",
          textDecoration: "none",
          color: "white",
          display: "flex",
          height: "48px",
          padding: "8px 24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          background: "var(--brand-color-primary-100, #4BA3B7)",
        },
      },
    },
    brevoInputWrapper: {
      display: "flex",
      flexDirection: "column",
      background: "#fff",
      overflow: "auto",
      width: "100%",
      height: "100%",

      "& .brevo-input-header": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: "84px",
        padding: "12px 24px",
        borderBottom: "1px solid #E8ECF2",

        "& .top-left": {},
        "& .top-right": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",

          "& .calendar-icon": {
            height: "24x",
            width: "24px",
            marginRight: "12px",
          },
          "& .selected-txt": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            whiteSpace: "nowrap",
          },
          "& .add-to-contact-btn": {
            textTransform: "none",
            borderRadius: "6px",
            background: "var(--brand-color-disable, #92C7D3)",
            width: "147px",
            height: "48px",
            padding: "8px 14px",
            color: "#ffffff",
            marginLeft: "32px",
          },
          "& .add-to-contact-btn-active": {
            textTransform: "none",
            borderRadius: "6px",
            background: "var(--brand-color-disable, #4BA3B7)",
            width: "147px",
            height: "48px",
            padding: "8px 14px",
            color: "#ffffff",
            marginLeft: "32px",
          },
        },
      },
      "& .main-wrapper": {
        padding: "0 90px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        [theme.breakpoints.down("xs")]: {
          padding: "0 30px",
        },
        "& .MuiButton-root": {
          borderRadius: "6px",
          background: "var(--brand-color-primary-100, #4BA3B7)",
          color: "#ffffff",
          textTransform: "none",
          fontFamily: "Expletus Sans",
          width: "100%",
          height: "48px",
          margin: "0",
        },
        "& .main-content-wrapper": {
          display: "flex",
          flexDirection: "column",
          "& .brevo-title": {
            color: "#2B2B2B",
            fontSize: "22px",
            fontStyle: "normal",
            linHeight: "normal",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            letterSpacing: "0.25px",
          },
          "& .brevo-desc": {
            color: "#94A3B8",
            margin: "0",
            fontSize: "14px",
            fontStyle: "normal",
            linHeight: "20px",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.25px",
          },
          "& .custom-input-wrapper": {
            margin: "31px 0 16px",
          },
          "& .list-wrapper": {
            marginBottom: "40px",
            "& .brevo-desc": {
              fontSize: "12px",
              marginBottom: '8px',
            }
          },
          "& .MuiList-root": {
            padding: "0 0 8px",
            "& .MuiListItem-root": {
              padding: "0 0 0 6px",
              color: "#94A3B8",
              fontSize: "12px",
              linHeight: "normal",
              fontFamily: "Roboto",
              display: "inline-block",
              "& + .MuiListItem-root": {
                marginTop: "5px",
              },
              "& a": {
                color: "#2B2B2B",
                marginLeft: "5px",
                transition: "all 0.3s",
                wordBreak: "break-all",
                "&:hover": {
                  textDecoration: "none",
                },
              },
            },
          },
        },
      },
      "& .sync-brevo-screen":{
         display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          [theme.breakpoints.down(767)]: {
            flexDirection: "column",
          },

          "& .img-contact-book": {
            width: "300px",
            height: "244px",
            objectFit: "contain",
          },
          "& .box-empty-right": {
            margin: "0 0 0 93px",
            maxWidth:"415px",
            [theme.breakpoints.down(767)]: {
              margin: "0",
              padding: "0 25px",
            },
            "& .txt-no-contact-title": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "normal",
              letterSpacing: "0.25px",
            },
            "& .txt-no-contact-sub-title": {
              color: "var(--task-completed-color, #94A3B8)",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "20px",
              letterSpacing: "0.25px",
              margin: "12px 0",
            },
            "& .MuiButton-root": {
              borderRadius: "6px",
              background: "var(--brand-color-primary-100, #4BA3B7)",
              color: "#ffffff",
              textTransform: "none",
              fontFamily: "Expletus Sans",
              width: "211px",
              height: "48px",
              margin: "48px 0px 0px",
            },
            "& .brevo-connect-btn":{
              width: "136px",
            }
          },
      },
    },
    syncBrevoModal: {
      display: "flex",
      flexDirection: "column",
      background: "#fff",
      overflow: "auto",
      width: "100%",
      height: "100%",
      "& .brevo-input-header": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: "84px",
        padding: "12px 24px",
        borderBottom: "1px solid #E8ECF2",

        "& .top-left": {},
        "& .top-right": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",

          "& .calendar-icon": {
            height: "24x",
            width: "24px",
            marginRight: "12px",
          },
          "& .selected-txt": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            whiteSpace: "nowrap",
          },
          "& .add-to-contact-btn": {
            textTransform: "none",
            borderRadius: "6px",
            background: "var(--brand-color-disable, #92C7D3)",
            width: "147px",
            height: "48px",
            padding: "8px 14px",
            color: "#ffffff",
            marginLeft: "32px",
          },
          "& .add-to-contact-btn-active": {
            textTransform: "none",
            borderRadius: "6px",
            background: "var(--brand-color-disable, #4BA3B7)",
            width: "147px",
            height: "48px",
            padding: "8px 14px",
            color: "#ffffff",
            marginLeft: "32px",
          },
        },
      },
      "& .main-wrapper": {
        padding: "0 90px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        [theme.breakpoints.down("xs")]: {
          padding: "0 30px",
        },
        "& .MuiButton-root": {
          borderRadius: "6px",
          background: "var(--brand-color-primary-100, #4BA3B7)",
          color: "#ffffff",
          textTransform: "none",
          fontFamily: "Expletus Sans",
          width: "100%",
          height: "48px",
          margin: "0",
        },
        "& .main-content-wrapper": {
          display: "flex",
          flexDirection: "column",
          "& .brevo-title": {
            color: "#2B2B2B",
            fontSize: "22px",
            fontStyle: "normal",
            linHeight: "normal",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            letterSpacing: "0.25px",
          },
          "& .brevo-desc": {
            color: "#94A3B8",
            margin: "0",
            fontSize: "14px",
            fontStyle: "normal",
            linHeight: "20px",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.25px",
          },
          "& .custom-input-wrapper": {
            margin: "31px 0 16px",
          },
          "& .list-wrapper": {
            marginBottom: "40px",
            "& .brevo-desc": {
              fontSize: "12px",
              marginBottom: '8px',
            }
          },
          "& .MuiList-root": {
            padding: "0 0 8px",
            "& .MuiListItem-root": {
              padding: "0 0 0 6px",
              color: "#94A3B8",
              fontSize: "12px",
              linHeight: "normal",
              fontFamily: "Roboto",
              display: "inline-block",
              "& + .MuiListItem-root": {
                marginTop: "5px",
              },
              "& a": {
                color: "#2B2B2B",
                marginLeft: "5px",
                transition: "all 0.3s",
                wordBreak: "break-all",
                "&:hover": {
                  textDecoration: "none",
                },
              },
            },
          },
        },
      },
      "& .sync-brevo-screen":{
         display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          [theme.breakpoints.down(767)]: {
            flexDirection: "column",
          },

          "& .img-contact-book": {
            width: "300px",
            height: "244px",
            objectFit: "contain",
          },
          "& .box-empty-right": {
            margin: "0 0 0 93px",
            maxWidth:"415px",
            [theme.breakpoints.down(767)]: {
              margin: "0",
              padding: "0 25px",
            },
            "& .txt-no-contact-title": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "normal",
              letterSpacing: "0.25px",
            },
            "& .txt-no-contact-sub-title": {
              color: "var(--task-completed-color, #94A3B8)",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              linHeight: "20px",
              letterSpacing: "0.25px",
              margin: "12px 0",
            },
            "& .MuiButton-root": {
              borderRadius: "6px",
              background: "var(--brand-color-primary-100, #4BA3B7)",
              color: "#ffffff",
              textTransform: "none",
              fontFamily: "Expletus Sans",
              width: "211px",
              height: "48px",
              margin: "48px 0px 0px",
            },
            "& .brevo-connect-btn":{
              width: "136px",
            }
          },
      },
    },
    popoverStyle: {
      display: "inline-flex",
      height: "auto",
      width: "310px",
      top:"140px",
      flexDirection: "column",
      alignItems: "center",
      flexShrink: 0,
      padding: "10px",
      borderRadius: "12px",
      background: "var(--white, #FFF)",
      marginTop:"24px",
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        lineHeight: "20px",
        fontSize: "14px",
        color: "#000000",
        letterSpacing: "0px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          justifyContent: "space-between",
          width: "100%",
          color: "#2B2B2B",
          opacity: "1",
        },
        "& .MuiFormControlLabel-label": {
          font: "inherit",
        },
      },
      "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#d3d9e1",
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8",
        },
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          padding: "5px",
          height: "20px",
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px",
          },
        },
      },
      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        boxShadow: "none",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px",
        },
      },
      "& .MuiSwitch-colorSecondary.Mui-checked":{
        color:'#FFF' 
      },
      "& .view-more-dropdown":{
        width: "310px",
        "& .dropdown-menu-item":{
          display: 'flex',
          padding: '12px 24px',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
        },
        "& .MuiFormControl-root":{
          width:"280px",
          marginLeft:"20px"
        },
      
        "& .dropdown-menu-item-column":{
          display: 'flex',
          padding: '8px 16px',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
        },
        "& .MuiSvgIcon-root":{
          color:"#E8ECF2"
        },
        // "& .dropdown-menu-item-checked":{
        //   color:"#4BA3B7",
        // }, 
        "& .MuiTouchRipple-root": {
          display: "none",
        },      
        "& .dropdown-menu-item-text":{
          color: '#2B2B2B',
          fontFamily: 'Expletus Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
        }
      },
    },
    popoverStepper: {
      top :"130px !important",
      right: "107px !important"
    },
    switchWrapper: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiFormControlLabel-root": {
        opacity: "0.671",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000000",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          width: "100%",
          justifyContent: "space-between",
          opacity: "1",
          color: "#2B2B2B",
        },
        "& .MuiFormControlLabel-label": {
          font: "inherit",
        },
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#d3d9e1",
        opacity: 1,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "#94A3B8",
        },
      },
      "& .MuiSwitch-root": {
        [theme.breakpoints.down("xs")]: {
          width: "33px",
          height: "20px",
          padding: "5px",
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down("xs")]: {
            padding: "6px",
          },
        },
      },
      "& .MuiSwitch-switchBase": {
        color: "#fafafa",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px)",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          width: "8px",
          height: "8px",
        },
      },
    },
    dropdownStyle: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "& .status-item": {
        display: "flex",
        "& .status-name": {
          marginRight: "10px",
        },
        "& .status": {
          width: "12px",
          height: "12px",
          background: "#94A3B8",
          borderRadius: "100%",
          marginLeft: "auto",
          "&.open": {
            background: "#4BA3B7",
          },
          "&.cancelled": {
            background: "#F7E223",
          },
        },
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        "&.assignee-multiSelect": {
          maxWidth: "400px",
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            position: "static",
            "&.multiselect-subheader": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              borderBottom: "1px solid #ECF1F4",
              paddingTop: "15px",
              paddingBottom: "15px",
              marginBottom: "5px",
              "& .tasklist-link": {
                marginLeft: "10px",
                color: "#C7263E",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                textDecoration: "none",
              },
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .priority-name": {
            margin: "0",
            "& .MuiTypography-root": {
              display: "flex",
            },
            "& .priority-status": {
              marginLeft: "auto",
            }
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              }
            },
            "& .assignee-detail": {
              "& .assignee-right": {
                "& .assinee-selected": {
                  "& .normal-img": {
                    display: "none",
                  },
                  "& .selected-img": {
                    display: "block",
                  },
                },
              },
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            }
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .select-icon": {
          marginRight: "6px",
        },
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
      "& .assignee-detail": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        "& .assignee-left": {
          display: "flex",
          alignItems: "center",
          "& .assignee-info": {
            marginLeft: "10px",
          },
          "& .assignee-profile": {
            width: "39px",
            height: "39px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ECF1F4",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Roboto",
            fontSize: "15.485px",
            fontWeight: 600,
            lineHeight: "21.679px",
            letterSpacing: "-0.395px",
          },
          "& .assignee-name": {
            fontSize: "14px",
            lineHeight: "19px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
          "& .assignee-email": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .assignee-right": {
          marginLeft: "10px",
          "& .assinee-selected": {
            width: "20px",
            height: "20px",
            "& .normal-img": {
              display: "none",
            },
            "& .selected-img": {
              display: "block",
            },
          },
          "& .assinee-unselected": {
            width: "20px",
            height: "20px",
            "& .normal-img": {
              display: "block",
            },
            "& .selected-img": {
              display: "none",
            },
          },
        },
      },
    },
    modalDialogBoxDrafts: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 580,
      backgroundColor: "#ffffff",
      padding: "48px",
      borderRadius: "24px",
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
        maxWidth: "327px",
        width: "90%",
        borderRadius: "12px",
        backgroundColor: "#F2F8FC",
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "22px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "23px",
        },
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "30px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
      "& .modal-desc": {
        fontSize: "14px",
        letterSpacing: "0.0075em",
        lineHeight: "26px",
        color: "#2b2b2b",
        fontWeight: 400,
        fontFamily: "Roboto",
        [theme.breakpoints.down("xs")]: {
          fontSize: "13px",
          lineHeight: "17px",
        },
        "& .title-dark": {
          fontWeight: "700",
        },
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "32px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "24px",
        },
        "& .primary-btn, & .secondary-btn ": {
          width: "auto",
          minWidth: "157px",
          [theme.breakpoints.down("xs")]: {
            minWidth: "70px",
            padding: "4px 6px",
          },
        },
        "& .MuiButton-root": {
          marginRight: "24px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
        },
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          minHeight: "30px",
          border: "0",
          color: "#4BA3B7",
        },
      },
    },
    createContactBox: {
      "&.deal-create":{
        bottom: "10px",
      },
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      padding: "0",
      overflow: "auto",
      maxWidth: "591px",
      width: "100%",
      marginLeft: "auto",
      maxHeight: "unset",

      display: "inline-block",
      top: "auto !important",
      bottom: "95px",
      right: "600px",
      position: "fixed",
      [theme.breakpoints.down("sm")]: {
        bottom: "65px",
      },
      [theme.breakpoints.down("xs")]: {
        top: "0 !important",
        bottom: "0",
        left: "0 !important",
        right: "0",
        maxWidth: "100%",
        borderRadius: "0",
        boxShadow: "none",
      },
      "& .contact-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        marginBottom: "0",
        padding: "40px 40px 14px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 17px",
        },
        "& .modal-title": {
          color: "#2B2B2B",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "24px",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
        },
        "& .sub-txt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.09px",
          marginTop: "4px",
        },
        "& .heading-right": {
          display: "flex",
          marginLeft: "10px",
          "& .heading-icon": {
            height: "16px",
            width: "16px",
            marginRight: "24px",
            "&:last-child": {
              marginRight: "0",
              height: "24px",
              width: "24px",
            },
          },
        },
      },
      "& .favorite-product": {
        textTransform: "none",
        textDecoration: "none",
      },
      "& .contact-content":{
        height: "calc(100vh - 300px)",
        padding: "10px 40px",
        overflowY:" auto", 
        [theme.breakpoints.down("sm")]: {
          height: "calc(100vh - 355px)",
        },
        [theme.breakpoints.down("xs")]: {
          height: "calc(100vh - 174px)",
          padding: "10px 24px",
        },   
      },
      "& .contact-content-group":{
        // height: "calc(100vh - 392px)",
        padding: "10px 40px",
        overflowY:" auto", 
        [theme.breakpoints.down("sm")]: {
          // height: "calc(100vh - 392px)",
        },
        [theme.breakpoints.down("xs")]: {
          height: "calc(100vh - 174px)",
          padding: "10px 24px",
        },   
      },
     
      "& .info-group": {
        padding: "16px 0px",
        overflowY: "auto",
        height: "auto",
        width: "100%",
        "& .group-title": {
          color: "#000",
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
          marginBottom: "16px",
        },
        "& .select-outer": {
          width: "100%",
          // marginTop:'-1.3vw',
          marginBottom: "16px",
           "& svg":{
            fill:'#94A3B8',  
          },
          "& .error-select":{
            color:'#C7263E',  
          },
          "& .Mui-error":{
            "& .MuiOutlinedInput-notchedOutline":{
              borderColor:'#C7263E',  
            }
          },       
          "& .MuiInputLabel-formControl": {
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "19px",
            color: "#94A3B8",
            letterSpacing: "0.1px",
            display: "flex",
            width: "calc(100% - 46px)",
            "&.MuiFormLabel-filled": {
              width: "auto",
              fontSize: "12px",
              color : "rgba(43,43,43,0.87)",
              lineHeight: "16px",
              fontFamily: "Roboto",
              letterSpacing: "0.0075em",
              fontWeight: "400",
            }
          },
          "& .MuiSelect-select": {
            letterSpacing: "0",
            fontSize: "14px",
            opacity: "0.671",
            color: "#000000",
            fontWeight: "500",
            display:'flex',
            fontFamily: "Expletus Sans",
            justifyContent:'space-between',
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
              fontSize: "12px",
              color : "rgba(43,43,43,0.87)",
              letterSpacing: "0.0075em",
              lineHeight: "16px",
              fontWeight: "400",
              fontFamily: "Roboto",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              lineHeight: "23px",
              fontSize: "18px",
              marginLeft: "auto",
            }
          }
        },
        "& .billing-address-subtitle": {
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& .billing-address-sub-title": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0.105px",
            marginBottom: "16px",
          },
          "& .shipping-address-chkbox": {
            color: "#4BA3B7",
          },
        },
        "& .shipping-address-txt": {
          // marginBottom: "16px",
          marginTop:"-13px",
        },
        "& .group-sub-title": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
          letterSpacing: "0.105px",
          marginTop: "-20px",
          marginBottom: "16px",
          display:"flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .MuiIconButton-label " :{
            color: "#E8ECF2",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        },
        "& .group-caption": {
          color: "var(--text-secondary, #94A3B8)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
        },
      },
      "& .form-heading": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginBottom: "16px",
      },
      "& .form-info-wrapper": {
        marginBottom: "32px",
        "&:last-child": {
          marginBottom: "0",
        },
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        "& .form-col": {
          flex: "0 0 100%",
          maxWidth: "100%",
          "&.col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          "& .form-control": {
            marginBottom: "16px",
          },
          "& .right-8": {
            marginRight: "8px",
          },
          "& .left-8": {
            marginLeft: "8px",
          },
          "& .MuiFormLabel-root": {
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              marginLeft: "auto",
              fontSize: "18px",
            },
          },
        },
        "& .choose-contact": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: "6px",
          width: "100%",
          height: "56px",
          border: "1px solid var(--stroke, #E8ECF2)",
          textTransform: "none",
          textDecoration: "none",
          padding: "0px 12px",
          justifyContent: "space-between",
          "& .count-selected": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          },
        },
      },
      "& .createtask-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "40px 40px 40px",
        "& .createtask-left": {
          display: "flex",
          alignItems: "center",
        },
        "& .outerbutton-wrapper": {
          marginRight: "8px",
          position: "relative",
          "& .outer-link": {
            minWidth: "48px",
            height: "48px",
            borderRadius: "48px",
            border: "1px dashed #E8ECF2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            padding: "10px",
            "& .priority": {
              color: "#94A3B8",
              "&.active-priority": {
                color: "#2B2B2B",
              },
            },
            "& .time": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              lineHeight: "19px",
              marginLeft: "5px",
            },
          },
        },
        "& .timestand-dropbox": {
          zIndex: "1300",
        },
        "& .primary-btn": {
          minWidth: "165px",
        },
      },

      "& .favorite-box": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "8px",
        padding: "24px",
        border: "1px solid #E8ECF2",
        "& .category-left-box": {
          "& .left-title": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginBottom: "10px",
          },
          "& .left-sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
          },
        },
        "& .category-right-box": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // marginTop: "24px",
          "& .total-amount-txt": {
            color: "var(--text-secondary, #94A3B8)",
            texAlign: "right",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .amount-txt": {
            color: "var(--text-secondary, #2B2B2B)",
            texAlign: "right",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginLeft: "6px",
          },
          "& .arrow-btn": {
            // minWidth: "24px",
            "& .btn-icon": {
              height: "16px",
              width: "16px",
              color:"#94A3B8"
            },
          },
        },
      },

      "& .wrap-option": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        marginBottom: "24px",

        "& .btn-add-option": {
          textTransform: "none",
          display: "flex",
          flexDirection: "row",
          padding: " 6px 12px",
          alignItems: "flex-start",
          gap: "8px",
          borderRadius: "15px",
          border: "1px solid var(--stroke, #E8ECF2)",
          color: "var(--gray, rgba(43, 43, 43, 0.87))",

          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",

          marginRight: "12px",
          marginBottom: "12px",
        },
      },
      "& .create-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "14px 40px 40px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px 55px",
        },
        "& .full-btn": {
          width: "100%",
          "& .MuiButton-root": {
            width: "100%",
          },
        },
        "& .create-contact-btn": {
          minWidth: "165px",
        },
      },
      "& .create-contact-btn": {
        display: "flex",
        height: "56px",
        padding: "8px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        flex: "1 0 0",
        borderRadius: "6px",
        background: "var(--brand-color-primary-100, #4BA3B7)",
        width: "100%",
        textTransform: "none",
        color: "var(--white, #FFF)",

        /* CTA/CTA_SMALL */
        fontFamily: "Expletus Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",       
      },
    },
    contactGroupsModal: {
      width: "680px",
      height: "100vh",
      backgroundColor: "#ffffff",
      position: "relative",
      overflow: "auto",
      // padding: "0px 60px",
      "& .modal-heading": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginTop: "50px",
        padding: "0px 60px",
        "& .gr-heading-left": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor:"pointer",
          "& .nav-text": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            marginLeft: "10px",
          },
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            letterSpacing: "0.105px",
          },
        },
        "& .heading-right": {
          dispplay: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .plus-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            width: "24px",
            height: "24px",
            color: "#94A3B8",
            marginLeft: "16px",
          },
        },
      },
      "& .MergeContacts": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        marginTop: "50px",
        justifyContent: "space-between",
        padding: "0px 60px",
        "& .gr-heading-left": {
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          "& .nav-text": {
            fontFamily: "Expletus Sans",
            color: "var(--task-completed-color, #2B2B2B)",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "500",
            marginLeft: "10px",
            lineHeight: "normal",
          },
        },
        "& .heading-left": {
          "& .modal-title": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontSize: "24px",
            fontFamily: "Expletus Sans",
            fontWeight: "400",
            fontStyle: "normal",
            lineHeight: "30px",
          },
          "& .sub-txt": {
            fontFamily: "Roboto",
            color: "var(--text-secondary, #94A3B8)",
            fontSize: "14px",
            fontWeight: "400",
            fontStyle: "normal",
            letterSpacing: "0.105px",
            lineHeight: "26px",
          },
        },
        "& .heading-right": {
          flexDirection: "row",
          dispplay: "flex",
          alignItems: "center",
          "& .plus-icon": {
            height: "24px",
            width: "24px",
            color: "#94A3B8",
          },
          "& .close-icon": {
            height: "24px",
            width: "24px",
            color: "#94A3B8",
            marginLeft: "16px",
          },
        },
      },
      "& .modal-title-box": {
        margin: "45px 0px",
        padding: "0px 60px",
        "& .title-txt": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26px",
        },
        "& .sub-title-txt": {
          color: "var(--task-completed-color, #2B2B2B)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
          marginTop: "8px",
        },
      },
      "& .search-box": {
        margin: "24px 0",
        padding: "0px 60px",
        "& .MuiOutlinedInput-root":{
          borderRadius: "12px",
          marginLeft: "-4px",
        },
        "& .MuiOutlinedInput-input":{
          marginLeft: "6px",
        },

      },
      "& .assign-contact": {
        "& .save-group-button": {
          bottom: "24px",
          width: "100%",
          textDecoration: "none",
          textTransform: "none",
          color: "white",
          display: "flex",
          height: "48px",
          justifyContent: "center",
          alignItems: "center",
          padding: "8px 24px",
          background: "#4BA3B7",
          borderRadius: "6px",
          margin:"20px auto"
        },
        "& .line":{
          borderBottom: "1px solid #E8ECF2",
          marginTop: "20px",
        },
        "& .top-title": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "normal",
          fontWeight: "500",
          margin: '25px 0 20px 70px',
        },
        "& .top-list": {
          justifyContent: "space-between",
          display: "flex",
          margin: "0px 70px",
          flexDirection: "row",
         
          "& .count-contact": {
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            lineHeight: "normal",
            fontStyle: "normal",
            fontWeight: "500",
          },
          "& .select-control": {
            "&.multi-select": {
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                position: "absolute",
                fontSize: "15px",
                right: "18px",
                top: "3px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              letterSpacing: "0.1px",
              opacity: "0.671",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              paddingBottom: "0",
              paddingRight: "2px",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        },
        "& .selected-contact-header": {
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0px 66px",
          marginBottom: "20px",
          "& .selected-contact-txt": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .remove-contact-txt": {
            color: "var(--hr-red, #C7263E)",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: "500",
            textTransform: "none",
          },
          "& .remove-contact-disable": {
            color: "var(--hr-red, #efafb9)",
            fontSize: "14px",
            fontStyle: "normal",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            lineHeight: "normal",
            textTransform: "none",
          },
        },
        "& .selected-contact": {
           padding:"0 60px",
          "& .select-contact-item": {
            display: "block",
            alignItems: "center",
            flexDirection: "row",
           
            padding: "8px 0px",
            borderBottom: '1px solid #E8ECF2',
            marginTop: "26px",
            "&.bg-light-blue":{
              background: "var(--background-background-light, #F2F8FC)",
              borderTop: '1px solid #E8ECF2',
            },
            "& .select-contact-item-block":{
              display: "flex",
              marginTop:"24px",
              padding:"8px 0",
            },
            "& .avatar": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "39px",
              height: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontSize: "15.485px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                lineHeight: "21.679px",
                fontWeight: "600",
                letterSpacing: "-0.395px",
              },
            },
            "& .contact-info": {
              display: "flex",
              marginLeft: "12px",
              flexDirection: "column",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                lineHeight: "normal",
                fontWeight: "500",
                textTransform: "capitalize",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                lineHeight: "normal",
                fontWeight: "500",
              },
              "& .departments": {
                display: "flex",
                marginTop:"4px",
                flexWrap: "wrap",
                marginBottom: "6px",
                "& .dep-item": {
                  display: "flex",
                  marginRight: "16px",
                  padding: "6px",
                  alignItems: "flex-start",
                  borderRadius: "6px",
                  gap: "8px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontSize: "10px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    fontWeight: "400",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              color: "#4BA3B7",
              right: 60,
              marginTop:"-10px",
            },
          },
        },
      },
      "& .un-assign-contact": {
        marginTop: "20px",
        padding: "0 60px",
        "& .top-title": {
          color: "var(--brand-color-brand-color-dark, #2B2B2B)",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
        },
        "& .un-assign-list": {
          paddingTop: "16px",
          paddingBottom: "40px",
          "& .select-contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px 0px",
            "& .avatar": {
              display: "flex",
              alignItems: "center",
              height: "39px",
              justifyContent: "center",
              width: "39px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              borderRadius: "19.5px",
              "& .short-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "15.485px",
                fontWeight: "600",
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
              },
            },
            "& .contact-info": {
              display: "flex",
              marginLeft: "12px",
              flexDirection: "column",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "normal",
                fontWeight: "400",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                marginTop: "2px",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "6px",
                marginTop: "2px",
                "& .dep-item": {
                  display: "flex",
                  padding: "6px",
                  marginRight: "16px",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "6px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  },
                },
              },
            },
            "& .checkedCirlce": {
              position: "absolute",
              right: 60,
              color: "#4BA3B7",
              marginTop: "26px",
            },
          },
          "& .contact-item": {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "8px 0",
            borderBottom: "1px solid #E8ECF2",
            marginTop: "20px",
            "& .checkedCirlceContact":{
              position: "absolute",
              right: 60,
              color: "#4BA3B7",
              marginTop: "-30px",
            },
            "& .avatar": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "39px",
              height: "39px",
              borderRadius: "19.5px",
              backgroundColor: "var(--background-background-medium, #ECF1F4)",
              "& .short-name": {
                fontFamily: "Roboto",
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontSize: "15.485px",
                fontStyle: "normal",
                lineHeight: "21.679px",
                fontWeight: "600",
                letterSpacing: "-0.395px",
              },
            },
            "& .contact-info": {
              flexDirection: "column",
              display: "flex",
              marginLeft: "12px",
              "& .name": {
                color: "var(--task-completed-color, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "capitalize",
                lineHeight: "normal",
              },
              "& .email": {
                color: "var(--text-secondary, #94A3B8)",
                fontSize: "12px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "normal",
                marginTop: "2px",
                marginBottom: "6px",
              },
              "& .departments": {
                display: "flex",
                flexWrap: "wrap",
                marginTop: "4px",
                marginBottom: "6px",
                "& .dep-item": {
                  display: "flex",
                  marginRight: "16px",
                  padding: "6px",
                  alignItems: "flex-start",
                  borderRadius: "6px",
                  gap: "8px",
                  background: "var(--background-background-medium, #ECF1F4)",
                  "& .dep-name": {
                    fontFamily: "Roboto",
                    color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                    fontSize: "10px",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    fontWeight: "400",
                  },
                },
              },
            },
            "& .circleCheck": {
              position: "absolute",
              right: 60,
              marginTop:"-30px",
              color: "#E8ECF2",
            },
          },
        },
        "& .bottom-button": {
          bottom: "24px",
          width: "100%",
          textTransform: "none",
          textDecoration: "none",
          color: "white",
          display: "flex",
          height: "48px",
          padding: "8px 24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          background: "var(--brand-color-primary-100, #4BA3B7)",
          marginTop:"100px"
        },
        "& .bottom-button-disabled": {
          bottom: "24px",
          width: "100%",
          textTransform: "none",
          textDecoration: "none",
          color: "white",
          display: "flex",
          height: "48px",
          padding: "8px 24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          background: "#92C7D3",
          marginTop:"100px",
          borderColor:"#92C7D3",
        },
        "& .top-list": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0px 70px",
         
          "& .count-contact": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "normal",
          },
          "& .select-control": {
            "&.multi-select": {
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .multiselect-label": {
                display: "none",
              },
              "& .multiple-clear": {
                fontSize: "15px",
                position: "absolute",
                right: "18px",
                zIndex: 1,
                top: "3px",
                color: "#94A3B8",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "12px",
              opacity: "0.671",
              letterSpacing: "0.1px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              paddingRight: "2px",
              paddingBottom: "0",
              paddingTop: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                width: "50px",
                height: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
          },
        },
      },
    },
  });
// Customizable Area End

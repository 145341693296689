// Customizable Area Start
import React from "react";
import { Typography, Slider, Box } from "@material-ui/core";
interface TakeQuizProps {
  quizData: any;
  quizTitle: string;
  quizCaption: string;
  saveData: (id: number, newValue: number) => void;
}

const TakeQuizWeb = (props: TakeQuizProps) => {
  const { quizData, quizTitle, quizCaption, saveData } = props;

  return (
    <Box className="quiz-question-wrapper">
      <Box className="score-heading">
        <Typography className="heading" variant="h1">
          {quizTitle}
        </Typography>
        <Box component="p" className="sub-txt">
          {quizCaption}
        </Box>
      </Box>
{quizData.map((question: any, index: number) => {
  const {
    id,
    title,
    min_score_value,
    max_score_value,
    answer,
  } = question;

  // Ensure min < max regardless of input order
  const min = Math.min(min_score_value, max_score_value);
  const max = Math.max(min_score_value, max_score_value);

  // Generate 5 marks, ensuring correct ordering
  const interval = (max - min) / 4;
  const marks = Array.from({ length: 5 }, (_, i) => ({
    value: max - i * interval, // Start from max and decrement
    label: (
      <>
        {" "}
        <span>{String.fromCharCode(69 - i)}</span>{" "}
        <span>{max - i * interval}</span>
      </>
    ),
  }));

  return (
    <Box key={id} className="quiz-question-box">
      <Box className="quiz-question">
        <Box component="span" className="serial-no filled-question">
          {index + 1}. {title}
        </Box>
      </Box>
      <Slider
        value={answer}
        onChange={(_, newValue) => saveData(index, newValue as number)}
        min={min}
        max={max}
        step={1}
        valueLabelDisplay="auto"
        marks={marks}
        className="quiz-slider"
      />
    </Box>
  );
})}

    </Box>
  );
};

export default TakeQuizWeb;
// Customizable Area End

import React, { Component } from "react";
import {
    Box,
    Checkbox,
    Typography,
    Button,
    Card,
    Grid,
    FormControl,
    Select,
    MenuItem,
    CardContent,
    Modal,
    List,
    ListItem,
    ListItemIcon,
    TextField,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { Chart } from "react-charts";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import SortQuizWeb from "./SortQuiz.web";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import OwnerDashboardController, { Props } from "./OwnerDashboardController.web";
import CommonModal from "./components/CommonModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    framePng, down_arrow, tuneVertical, tick, escore_quiz_tab, remove_icon,
    checkBoxEmpty,
    checkBoxFilled
} from "./assets";
import { Link } from "react-router-dom";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import { calendarGrayNew } from "../../ActivityLog/src/assets";


export class OwnerDashboard extends OwnerDashboardController {
    constructor(props: Props) {
        super(props);
      }    

    statisticList = (data: any, type: string) => {
        return (
            <>
                {data.map((item: any) => (
                    <Card className={type === 'header' ? "google-card" : "google-card2"} key={item.key} onClick={item.onClick}>
                        <CardContent className="content-card">
                            <Box className="right-content">
                                <Box className="quiz-title">
                                    {this.t(item.key)}
                                </Box>
                                <Box className="quiz-title">
                                    {item.count}
                                </Box>
                                  <Box className="quiz-date">
                                    {this.t(item.status)}
                                  </Box>
                                
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </>
        );
    };

    CustomCheckbox = ({ id, isChecked, onClick }: { id: number; isChecked: boolean; onClick: (id: number) => void }) => {
        return (
            <div
                onClick={() => onClick(id)}
                className="check-box-styles"
                style={{border: isChecked ? 'none' : '2px solid #BDBDBD',
                        backgroundColor: isChecked ? '#4BA3B7' : 'white', // Green if checked, white if not
                      }}
            >
                {isChecked && (
                    <div className="checked-styles">
                        <img src={tick} alt="tick-mark"/>
                    </div>
                )}
            </div>
        );
    };

    chooseEQuizzes = (classes: any, t: any) => {
        const { isFavQuizzesModalOpen } = this.state;
    
        return (
            <CommonModal
                isOpen={isFavQuizzesModalOpen}
                onClose={this.handleModalClose}
                title={this.t("Choose E-Quizzes")}
                description={this.t("Select up to 3 E-Quizzes to set as your favorites.")}
                bodyContent={
                    <Box style={{padding: "0 60px"}}>
                        {/* Search bar */}
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth
                                type="search"
                                placeholder={this.t("Start typing to search...")}
                                onChange={(event: any) => console.log('Search input:', event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group" data-test-id="mobileFilter">
                                <img src={tuneVertical} alt="Filter Icon" />
                            </Box>
                            
                        </Box>
                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                                {this.t("6 E-Quizzes")}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={this.t}
                            />
                        </Box>
    
                        {/* Quiz list */}
                        <Box sx={{ minWidth: 275, paddingLeft: 20 }} className={classes.fbCardRow}>
                            {this.QuizList()}
                        </Box>

                        <Button
                            style={{marginTop: "50px", fontSize: "14px", fontWeight: 500}}
                            className={`primary-btn ${classes.primaryButton}`}
                            title="Continue"
                            onClick={this.continueToFavorites}
                        >
                            {this.t("Continue")}
                        </Button>
                        
                    </Box>
                }
                classes={classes}
            />
        );
    };

    campaignsReach = (classes: any, t: any) => {
        const { isCampaignsModalOpen } = this.state;
        const { isNewContactsModalOpen,newContacts } = this.state;
        const newContactData:any = newContacts?.map((itm) => itm?.attributes);
        const buisnessCategory = newContactData?.flatMap((contact: any) => 
            contact?.business_info
          )  
        const billingAddress = newContactData?.flatMap((contact: any) => 
            contact?.billing_address
        )   
    
        return (
            <CommonModal
                isOpen={isCampaignsModalOpen}
                onClose={this.handleModalClose}
                title={this.t(`${this.state.campaignsReachCount} Campaign Views`)}
                description=""
                bodyContent={
                    <Box style={{padding: "0 60px"}}>
                        {/* Search bar */}
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth
                                type="search"
                                placeholder={this.t("Start typing to search...")}
                                onChange={(event: any) => console.log('Search input:', event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box 
                            className="filter-group" data-test-id="mobileFilter"
                            onClick={this.handleFilterPopUp}
                            >
                                <img src={tuneVertical} alt="Filter Icon" onClick={this.handleOpenCampaignReachFilter}
                                />
                            </Box>
                            {this.state.isOpenCampaignFilterPopup && 
          <Modal
          hideBackdrop
          className={classes.modalDialog}
          aria-describedby="modal-modal-description"
          onClose={this.handleCloseFilterPopUp}
          aria-labelledby="modal-modal-title"
          open={this.state.isOpenCampaignFilterPopup}
        >
          <Box className={`filter-dialogbox ${classes.modalDialogBox}`}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading"  style={{
                                    padding: '0px 17px 0px 76px'
                                }}
              >
                <Box className="filter-heading"  style={{gap: '5px'}}
                >
                  <Box
                    className="auth-back"
                    onClick={this.handleCloseFilterPopUp}
                    data-test-id="close-activity-log-filter"
                  >
                    <i>
                      <svg
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        focusable="false"
                        className="MuiSvgIcon-root"
                      >
                        <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                      </svg>
                    </i>
                  </Box>
                 Filters
                </Box>
                <Box
                  data-test-id="activityLogClose"
                  onClick={this.handleCloseFilterPopUp}
                  className="close-icon"
                >
                 <Typography variant="h2" className="form-heading" style={{
                                                    color:'#4BA3B7'
                                                }} onClick={this.handleResetContactFilters}
                 > 
                    Reset
                    </Typography>
                </Box>
              </Box>
              <Box className="modal-description">
                <Box component="form" className="modal-form" >
              
                  <Box className="form-info-wrapper">
                  <Typography variant="h2" className="form-heading">
                    To
                    </Typography>
                <Autocomplete
                disablePortal
                options={newContactData?.flatMap((contact: any) => 
                    contact.contact_groups.map((group: any) => group.name)
                  )}                  
                className="autocomplete-search"
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Reciever"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{
                                    fontSize: '20px',
                                    color:'#a8a5a5'
                                }} />
                            </InputAdornment>
                        ),
                    }}
                />
                )}
                onChange={(event, value) => this.setState({ selectedGroupOptions: value })}
            />
                  </Box>
                  <Box className="form-info-wrapper">
                    <Typography  variant="h2" className="form-heading">

                    Campaign Title
                    </Typography>
                    <Autocomplete
                    disablePortal
                    options={this.state.completedTasks.map((tasks: any) => tasks.attributes.title)}
                    className="autocomplete-search"
                    renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Search Campaign Title"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{
                                    fontSize: '20px',
                                    color:'#a8a5a5'
                                }} />
                            </InputAdornment>
                        ),
                    }}
                />
                )}
                onChange={(event, value) => this.setState({ selectedCompanyOptions: value })}
            />
                  </Box>
                  <Box className="form-info-wrapper">
                    <Typography  variant="h2" className="form-heading">
                    Design
                    </Typography>
                    <Autocomplete
                disablePortal
                options={buisnessCategory.map((business:any) => business.data.attributes.business_category_name).filter((name:any) => name)}
                className="autocomplete-search"
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Search Design"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{
                                    fontSize: '20px',
                                    color:'#a8a5a5'
                                }} />
                            </InputAdornment>
                        ),
                    }}
                />
                )}
                onChange={(event, value) => this.setState({ selectedbuisnessOptions: value })}
            />
                  </Box>
                  <Box className="form-info-wrapper">
                    <Typography  variant="h2" className="form-heading">
                   Date Sent
                    </Typography>
                    <Box className="sub-txt" component="p">
                                                        <CustomDatePicker
                                                            t={this.t}
                                                            value={this.state.logDates}
                                                            placeholder={'Choose Date'}
                                                            range={false}
                                                            currentDate=""
                                                            onChange={this.dateRangeFilter}
                                                            // datePickerRef={this.datePickerRef}
                                                            data-test-id="logDateFilter"
                                                            onOpenPickNewDate={false}
                                                            calendarIcon={calendarGrayNew} />
                                                    </Box>
                  </Box>
            <Button className="apply-filter-btn" 
          onClick={this.getNewContactsFilter}
            >
                Apply Filters
            </Button>
              </Box>
            </Box>
          </Box>
           </Box>
        </Modal>
                            }
                        </Box>
                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                               {`0 ${this.t("Campaigns")}`}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={this.t}
                            />
                        </Box>
                    </Box>
                }
                classes={classes}
            />
        );
    }

    newContacts = (classes: any, t: any) => {
        const { isNewContactsModalOpen,newContacts } = this.state;
        const newContactData:any = newContacts?.map((itm) => itm?.attributes);
        const buisnessCategory = newContactData?.flatMap((contact: any) => 
            contact?.business_info
          )  
        const billingAddress = newContactData?.flatMap((contact: any) => 
            contact?.billing_address
        )   
        console.log(buisnessCategory,'buisnessCategory')
        return (
            <CommonModal
                isOpen={isNewContactsModalOpen}
                onClose={this.handleModalClose}
                title={(`${this.state.newContactsCount} ${this.t("New Contacts")}`)}
                description=""
                bodyContent={
                    <Box style={{padding: "0 60px"}}>
                        {/* Search bar */}
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth
                                type="search"
                                placeholder={this.t("Start typing to search...")}
                                onChange={this.handleSearchInputChange}
                                value={this.state.searchQuery}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon 
                                        />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group" data-test-id="mobileFilter" onClick={this.handleOpenContactFilter}
                            >
                                <img src={tuneVertical} alt="Filter Icon" />
                            </Box>
                            {this.state.isOpenContactFilterPopup && 
          <Modal
          hideBackdrop
          className={classes.modalDialog}
          aria-describedby="modal-modal-description"
          onClose={this.handleCloseFilterPopUp}
          aria-labelledby="modal-modal-title"
          open={this.state.isOpenContactFilterPopup}
        >
          <Box className={`filter-dialogbox ${classes.modalDialogBox}`}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading"  style={{
                                    padding: '0px 17px 0px 76px'
                                }}
              >
                <Box className="filter-heading"  style={{gap: '5px'}}
                >
                  <Box
                    className="auth-back"
                    onClick={this.handleCloseFilterPopUp}
                    data-test-id="close-activity-log-filter"
                  >
                    <i>
                      <svg
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        focusable="false"
                        className="MuiSvgIcon-root"
                      >
                        <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                      </svg>
                    </i>
                  </Box>
                 Filters
                </Box>
                <Box
                  data-test-id="activityLogClose"
                  onClick={this.handleCloseFilterPopUp}
                  className="close-icon"
                >
                 <Typography variant="h2" className="form-heading" style={{
                                                    color:'#4BA3B7'
                                                }} onClick={this.handleResetContactFilters}
                 > 
                    Reset
                    </Typography>
                </Box>
              </Box>
              <Box className="modal-description">
                <Box component="form" className="modal-form" >
              
                  <Box className="form-info-wrapper">
                  <Typography variant="h2" className="form-heading">
                    Group
                    </Typography>
                <Autocomplete
                disablePortal
                options={newContactData?.flatMap((contact: any) => 
                    contact.contact_groups.map((group: any) => group.name)
                  )}                  
                className="autocomplete-search"
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Search Groups"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{
                                    fontSize: '20px',
                                    color:'#a8a5a5'
                                }} />
                            </InputAdornment>
                        ),
                    }}
                />
                )}
                onChange={(event, value) => this.setState({ selectedGroupOptions: value })}
            />
                  </Box>
                  <Box className="form-info-wrapper">
                    <Typography  variant="h2" className="form-heading">

                    Company
                    </Typography>
                    <Autocomplete
                    disablePortal
                    options={buisnessCategory.map((business:any) => business.data.attributes.business_category_name).filter((name:any) => name)}
                    className="autocomplete-search"
                    renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Search Company"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{
                                    fontSize: '20px',
                                    color:'#a8a5a5'
                                }} />
                            </InputAdornment>
                        ),
                    }}
                />
                )}
                onChange={(event, value) => this.setState({ selectedCompanyOptions: value })}
            />
                  </Box>
                  <Box className="form-info-wrapper">
                    <Typography  variant="h2" className="form-heading">
                    Buisness Category
                    </Typography>
                    <Autocomplete
                disablePortal
                options={buisnessCategory.map((business:any) => business.data.attributes.business_category_name).filter((name:any) => name)}
                className="autocomplete-search"
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Search Category"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{
                                    fontSize: '20px',
                                    color:'#a8a5a5'
                                }} />
                            </InputAdornment>
                        ),
                    }}
                />
                )}
                onChange={(event, value) => this.setState({ selectedbuisnessOptions: value })}
            />
                  </Box>
                  <Box className="form-info-wrapper">
                    <Typography  variant="h2" className="form-heading">
                   Shipping Country
                    </Typography>
                    <Autocomplete
                disablePortal
                options={billingAddress.map((business:any) => business.data.attributes.country).filter((name:any) => name)}
                className="autocomplete-search"
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Search Country"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{
                                    fontSize: '20px',
                                    color:'#a8a5a5'
                                }} />
                            </InputAdornment>
                        ),
                    }}
                />
                )}
                onChange={(event, value) => this.setState({ selectedBillingOptions: value })}
            />
                  </Box>
                  <Box className="form-info-wrapper">
                  <Box className="form-info-switch">
                  <Typography  variant="h2" className="form-heading">
                   Has an Open Deal
                    </Typography>
                    <FormControlLabel
                        control={<Switch checked={this.state.hasOpenDeal}
                        onChange={() => this.handleToggle("hasOpenDeal")}
                        className="form-info-switch-toggle"
                        />}
                        label=""
                    />
                    </Box>     
                   <Box className="form-info-switch">
                  <Typography  variant="h2" className="form-heading">
                  Has Interaction 
                    </Typography>
                    <FormControlLabel
                        control={<Switch checked={this.state.hasInteraction}
                        onChange={() => this.handleToggle("hasInteraction")}
                         className="form-info-switch-toggle"
                        />}
                        label=""
                    />
                  </Box>
                  <Box className="form-info-switch">
                  <Typography  variant="h2" className="form-heading">
                   Has Favorite Products
                    </Typography>
                    <FormControlLabel
                        control={<Switch
                        checked={this.state.hasFavoriteProducts}
                        onChange={() => this.handleToggle("hasFavoriteProducts")}
                         className="form-info-switch-toggle"
                        />}
                        label=""
                    />
                </Box>
                <Box className="form-info-switch">
                  <Typography  variant="h2" className="form-heading">
                   Has Notes
                    </Typography>
                    <FormControlLabel
                        control={<Switch checked={this.state.hasNotes}
                        onChange={() => this.handleToggle("hasNotes")}
                         className="form-info-switch-toggle"
                        />}
                        label=""
                    />
                </Box>
               </Box>
            <Button className="apply-filter-btn" 
          onClick={this.getNewContactsFilter}
            >
                Apply Filters
            </Button>
              </Box>
            </Box>
          </Box>
           </Box>
        </Modal>
                            }
                        </Box>
                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                                {`${this.state.newContactsCount} ${this.t("Outreaches")}`}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={this.t}
                            />
                        </Box>
    
                        <Box sx={{ minWidth: 275, paddingLeft: 20 }} className={classes.fbCardRow}>
                        <div className={classes.newContacts}>
                         {this.state.newContacts.map((obj) => {
                            return(
                                <div className="contacts-row">
                                    <span className="contact">
                                        <span className="initials">CW</span>
                                        <span>
                                            <div className= "contact-name">{`${obj.attributes.first_name}` + " " + `${obj.attributes.last_name}`}</div>
                                            <div className= "contact-email">{`${obj.attributes.email}`}</div>
                                        </span>
                                    </span>
                                    <span style={{fontSize: "12px"}}>10</span>
                                </div>
                            )
                          })}
                        </div>
                        </Box>

                        
                    </Box>
                }
                classes={classes}
            />
        );
    }

    private _tasksCompleted = (classes: any, t: any) => {
        const { isTasksCompletedModalOpen, completedTasks,showCompletedTasks } = this.state;
        const totaTime = showCompletedTasks.reduce((total, obj) => total + obj.attributes.duration, 0);
        const assigneeNames = showCompletedTasks
        .flatMap((task) => task?.attributes ?? [])
        .flatMap((attr) => attr?.assignees ?? [])
        .map((assignee) => ({
            id: assignee.id, 
            label: `${assignee.first_name} ${assignee.last_name}`, 
        }));    
        const projectName = showCompletedTasks
            .flatMap((task) => task?.attributes ?? [])
            .flatMap((attr) => attr?.project_title ?? []);
        const taskName = showCompletedTasks
            .flatMap((task) => task?.attributes ?? [])
            .flatMap((attr) => attr?.task_list_name ?? []);

        return (
            <CommonModal
                isOpen={isTasksCompletedModalOpen}
                onClose={this.handleModalClose}
                title={`${completedTasks.length} ${this.t("Tasks Completed")}`}
                description=""
                bodyContent={<Box style={{ padding: "0 60px" }}>
                    {/* Search bar */}
                    <Box className="search-wrapper">
                        <CustomInputWeb
                            fullWidth
                            type="search"
                            placeholder={this.t("Start typing to search...")}
                            onChange={(event: any) => console.log('Search input:', event.target.value)}
                            startAdornment={<InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>} />
                        <Box className="filter-group" data-test-id="mobileFilter"
                            onClick={this.handleFilterPopUp}
                        >
                            <img src={tuneVertical} alt="Filter Icon" />
                        </Box>
                        {this.state.isOpenFilterPopup &&
                            <Modal
                                // hideBackdrop
                                className={classes.modalDialog}
                                aria-describedby="modal-modal-description"
                                onClose={this.handleCloseFilterPopUp}
                                aria-labelledby="modal-modal-title"
                                open={this.state.isOpenFilterPopup}
                            >
                                <Box className={`filter-dialogbox ${classes.modalDialogBox}`} 
                                >
                                    <Box className="modal-dialoginner">
                                        <Box className="modal-heading" style={{
                                    padding: '0px 17px 0px 76px'
                                }}
                                        >
                                            <Box className="filter-heading"  style={{
                                                        gap: '5px'
                                                    }}
                                            >
                                                <Box
                                                    className="auth-back"
                                                    onClick={this.handleCloseFilterPopUp}
                                                    data-test-id="close-activity-log-filter"
                                                >
                                                    <i>
                                                        <svg
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            focusable="false"
                                                            className="MuiSvgIcon-root"
                                                        >
                                                            <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                                                        </svg>
                                                    </i>
                                                </Box>
                                                Filters
                                            </Box>
                                            <Box
                                                data-test-id="activityLogClose"
                                                onClick={this.handleResetFilters}
                                                className="close-icon"
                                            >
                                                <Typography variant="h2" className="form-heading" style={{
                                                    color:'#4BA3B7'
                                                }}
                                                >
                                                    Reset
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box className="modal-description">
                                            <Box component="form" className="modal-form">
                                                <Box className="form-info-wrapper">
                                                    <Typography variant="h2" className="form-heading">
                                                        Deadline
                                                    </Typography>
                                                    <Box className="sub-txt" component="p">
                                                        <CustomDatePicker
                                                            t={this.t}
                                                            value={this.state.logDates}
                                                            placeholder={'Choose Date'}
                                                            range={false}
                                                            currentDate=""
                                                            onChange={this.dateRangeFilter}
                                                            // datePickerRef={this.datePickerRef}
                                                            data-test-id="logDateFilter"
                                                            onOpenPickNewDate={false}
                                                            calendarIcon={calendarGrayNew} />
                                                    </Box>
                                                </Box>
                                                <Box className="form-info-wrapper">
                                                    <Typography variant="h2" className="form-heading">
                                                        Priority
                                                    </Typography>
                                                    <Box className="form-control">
                                                        <Button
                                                            className={`priority-btn ${this.state.priority === 0 ? "active" : ""}`}
                                                            onClick={() => this.handlePriorityChange(0)}
                                                        >
                                                            None
                                                        </Button>
                                                        <Button
                                                            className={`priority-btn ${this.state.priority === 1 ? "active" : ""}`}
                                                            onClick={() => this.handlePriorityChange(1)}
                                                        >
                                                            Critical
                                                        </Button>
                                                        <Button
                                                            className={`priority-btn ${this.state.priority === 2 ? "active" : ""}`}
                                                            onClick={() => this.handlePriorityChange(2)}
                                                        >
                                                            Emergency
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                <Box className="form-info-wrapper">
                                                    <Typography variant="h2" className="form-heading">
                                                        Project
                                                    </Typography>
                                                    <Autocomplete
                                                        disablePortal
                                                        options={projectName}
                                                        className="autocomplete-search"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Search Project"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <SearchIcon style={{
                                                                                fontSize: '20px',
                                                                                color: '#a8a5a5'
                                                                            }} />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        )}
                                                        onChange={(event, value) => this.setState({ selectedProjectOptions: value })} />
                                                </Box>
                                                <Box className="form-info-wrapper">
                                                    <Typography variant="h2" className="form-heading">
                                                        Tasklist
                                                    </Typography>
                                                    <Autocomplete
                                                        disablePortal
                                                        options={taskName}
                                                        className="autocomplete-search"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Search Project"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <SearchIcon style={{
                                                                                fontSize: '20px',
                                                                                color: '#a8a5a5'
                                                                            }} />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        )}
                                                        onChange={(event, value) => this.setState({ selectedTaskListOptions: value })} />
                                                </Box>
                                                <Box className="form-info-wrapper">
                                                    <Typography variant="h2" className="form-heading">
                                                        Assignee (individual or team)
                                                    </Typography>
                                                    <Autocomplete
    disablePortal
    options={assigneeNames}
    className="autocomplete-search"
    multiple
    getOptionLabel={(option) => option.label}
    renderInput={(params) => (
        <TextField
            {...params}
            label="Search Project"
            variant="outlined"
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon style={{ fontSize: '20px', color: '#a8a5a5' }} />
                    </InputAdornment>
                ),
            }}
        />
    )}
    onChange={(event, value) =>
        this.setState({ selectedAssigneeOptions: value.map((item) => item.id) })
    }
/>
                                                </Box>
                                            </Box>
                                            <Button className="apply-filter-btn"
                                                onClick={this.handleApplyFilter}
                                            >Apply Filters
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Modal>}
                    </Box>
                    <Box style={{ marginTop: "15px", marginLeft: "-15px", marginRight: "-25px" }} className={`${classes.dialogContent} filter-box-fb`}>
                        <Typography
                            className={`${classes.dialogContent} quiz-count`}
                        >
                            {`${totaTime} Hours Logged`}
                        </Typography>
                        <SortQuizWeb
                            classes={classes}
                            sortHandler={() => { } }
                            sortType="librarySort"
                            data-test-id="sort-dropdown"
                            t={this.t} />
                    </Box>

                    <Box sx={{ minWidth: 275, paddingLeft: 20 }} className={classes.fbCardRow}>
                        <div className={classes.newContacts}>
                            
                            {completedTasks.map((obj) => {
                                return (
                                    <div className="contacts-row">
                                        <span className="contact">
                                            <span>
                                                <div className="contact-name">{obj.attributes.title}</div>
                                                <div className="contact-email">Wine Collection 2022 / Decemb...</div>
                                            </span>
                                        </span>
                                        <span style={{ fontSize: "12px" }}>{obj.attributes.duration}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </Box>


                </Box>}
                classes={classes} />
        );
    };
    public get tasksCompleted() {
        return this._tasksCompleted;
    }
    public set tasksCompleted(value) {
        this._tasksCompleted = value;
    }

    HexBadge = ({ grade }: { grade: string }) => (
        <Box
            style={{
                width: 40,
                height: 40,
                backgroundColor: this.getHexBadgeColor(grade),
                clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography style={{ color: "#fff", fontFamily: "Expletus Sans" }}>{grade}</Typography>
        </Box>
    );

    QuizList = () => {
        // const filteredQuizzes = this.getQuizzesForTab();

        return (
            <Box style={{marginTop: "30px"}}>
                {this.state.allQuizes.map((quiz, index) => (
                    <Box
                        key={quiz.attributes.id}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        style={{
                            marginBottom: 30,
                            paddingBottom: 8,
                        }}
                    >
                        <Box display="flex" alignItems="center">
                        <this.HexBadge grade={quiz.attributes.score} />
                        
                            <Box ml={2}>
                                <Typography variant="body1" style={{ fontWeight: 500, fontFamily: "Expletus Sans" }}>
                                    {quiz.attributes.title}
                                </Typography>
                                <Typography variant="body2" style={{ color: "#757575" }}>
                                    {new Date(quiz.attributes.created_at).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" })}
                                </Typography>
                            </Box>
                        </Box>
    
                        <this.CustomCheckbox 
                             id={quiz.id}
                             isChecked={this.state.selectedCheckboxes.includes(quiz.id)} 
                             onClick={this.handleCheckboxChange} />
                    </Box>
                ))}
            </Box>
        );
    };
    

    render() {
        const {classes} = this.props;
        const pageURL = window.location.pathname;
        const headerData = this.getHeaderData();
        const footerData = this.getFooterData();
        const primaryAxis = this.getPrimaryAxis();
        const secondaryAxes = this.getSecondaryAxes();
        const data = this.data;
        const t = this.t;
        console.log(this.state.selectedAssigneeOptions,'selectedAssigneeOptions')
        return (
            <Box>
        {/* {this.state.isLoading && <WebLoader />} */}
        <Box
          className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
        >
          <GlobalHeaderWeb pageLink={pageURL} />
          <Box className={classes.innerWrapper}>

        <Box className={classes.teamModuleWrapper}>
            <Grid container spacing={2}>
                <Grid item sm={9} xs={9}>
                    <Box className="google-heading-wrapper">
                        <Box className="heading-left">
                            <Typography className="heading" variant="h1">
                                {this.t("Statistics")}
                            </Typography>
                        </Box>
                        <Box style={{marginRight: "-20px"}} className="heading-right">
                            <Box className="filter-list">
                                <FormControl className="select-control sortby-control">
                                    <Select
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                            classes: { paper: classes.dropdownClass },
                                        }}
                                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                            this.setState({ selectedStatisticsFilter: e.target.value as string });
                                        }}
                                        // defaultValue="Last 7 Days"
                                        value={this.state.selectedStatisticsFilter}
                                        displayEmpty
                                        style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                        inputProps={{ IconComponent: () => null }}
                                        renderValue={(value) => {
                                            return (
                                                <Box className={classes.filterBox}>
                                                    <span>{this.t(value) as string}</span>
                                                    <img
                                                        className="select-icon"
                                                        style={{marginRight: "-20px", marginLeft: "15px"}}
                                                        src={down_arrow}
                                                        alt="sortVariant"
                                                    />
                                                </Box>
                                            );
                                        }}
                                        data-test-id="sbmt-quiz-dropdown"
                                        
                                    >
                                        <MenuItem className={classes.dropdown_ele} value="4 weeks">{this.t("Last 4 weeks")}</MenuItem>
                                        <MenuItem className={classes.dropdown_ele} value="This month">{this.t("This month")}</MenuItem>
                                        <MenuItem className={classes.dropdown_ele} value="This quarter">{this.t("This quarter")}</MenuItem>
                                        <MenuItem className={classes.dropdown_ele} value="This semester">{this.t("This semester")}</MenuItem>
                                        <MenuItem className={classes.dropdown_ele} value="This year">{this.t("This year")}</MenuItem>
                                    </Select>
                                </FormControl>

                            </Box>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ minWidth: 275 }} className="google-card-row">
                                {this.statisticList(headerData, 'header')}
                            </Box>
                        </Box>
                    </Box>

                    <Box className="google-heading-wrapper">
                        <Box className="heading-left">
                            <Typography className="heading" variant="h1">
                                {this.t("Deals")}
                            </Typography>
                        </Box>
                        <Box style={{marginRight: "-20px"}} className="heading-right  align-see-all">
                                        <Box className="filter-list">
                                            <FormControl className="select-control sortby-control">
                                                <Select
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                        classes: { paper: classes.dropdownClass },
                                                    }}
                                                    // onChange={this.handleSubmittedQuizesSort}
                                                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                                        this.setState({ selectedDealsStatisticsFilter: e.target.value as string });
                                                    }}
                                                    value={this.state.selectedDealsStatisticsFilter}
                                                    displayEmpty
                                                    style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                                    inputProps={{ IconComponent: () => null }}
                                                    renderValue={(value) => {
                                                        return (
                                                            <Box className={classes.filterBox}>
                                                                <span>{this.t(value) as string}</span>
                                                                <img
                                                                    className="select-icon"
                                                                    style={{marginRight: "-20px", marginLeft: "15px"}}
                                                                    src={down_arrow}
                                                                    alt="sortVariant"
                                                                />
                                                            </Box>
                                                        );
                                                    }}
                                                    data-test-id="sbmt-quiz-dropdown"
                                                    
                                                >
                                                    <MenuItem className={classes.dropdown_ele} value="4 weeks">{this.t("Last 4 weeks")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This month">{this.t("This month")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This quarter">{this.t("This quarter")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This semester">{this.t("This semester")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This year">{this.t("This year")}</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Box>
                                        <Link to={"/deals"} style={{ textDecoration: "none" }}>
                                          <Typography style={{cursor: "pointer"}} className="see-all-txt">{this.t("See All")}</Typography>
                                        </Link>
                                    </Box>

                        <Box sx={{ width: "100%", height: "100%" }}>
                            <Box className="google-card-row">
                                <Box sx={{ width: "1500px", height: "400px" }}>
                                    <Chart
                                        options={{
                                            data,
                                            primaryAxis,
                                            secondaryAxes,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ minWidth: 275 }} className="google-card-row">
                                {this.statisticList(footerData, 'footer')}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={3} xs={3}>
                    <Box className="google-heading-wrapper" style={{padding: "25px"}}>
                        <Box style={{width: "100%"}}>
                        <div className="see-all-txt">
                        <Box className="heading-left">
                            <Typography className="heading" variant="h1">
                                {this.t("E-Score")}
                            </Typography>
                        </Box>
                        <Box className="heading-right">
                        <Link to={"/EScore"} style={{ textDecoration: "none" }}>
                            <Typography style={{ cursor: "pointer", fontWeight: 500, fontSize: "14px", color: "#4BA3B7" }} variant="h3">
                                {this.t("See All")}
                            </Typography>
                        </Link>
                        </Box>
                        </div>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>  
                        <Box className="tabs-container">
                            {this.tabValues.map((tab) => {
                              return(
                                <Box key={tab} onClick={() => this.handleTabClick(tab)} className={this.state.activeTab === tab ? "owner-activeTab" : "owner-tabs"}>{this.t(tab)}</Box>
                              )
                            })}
                          </Box>
                        </Box>
                        <Box className="quizzes-container">
                        {this.getQuizzesForTab()?.length === 0 
                          ?
                          <Box className="no-quizzes">
                            <Box sx={{ marginTop: "180px" }}>
                                <img src={framePng} alt="emptydata" />
                            </Box>

                            <Box
                                style={{
                                    maxWidth: 400,
                                    marginTop: "40px",
                                    marginBottom: "20px",
                                    textAlign: 'center',
                                }}
                            >
                                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#2B2B2B", fontFamily: "Expletus Sans" }}>No Favorite E-Quizzes</Typography>

                                <Typography
                                    style={{ fontWeight: 500, fontSize: "14px", color: "#94A3B8", fontFamily: "Expletus Sans" }}
                                >
                                    {this.t("Exploring Through Your Favorite E-Quizzes")}
                                </Typography>
                                <Box mt={5} className="choose-favourite-btn">
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        className={`${classes.chooseBtn}`}
                                        onClick={this.handleModalOpen}
                                    >
                                        {this.t("Choose Favorite E-Quizzes")}
                                    </Button>
                                    {this.state.isFavQuizzesModalOpen && this.chooseEQuizzes(classes, t)}
                                </Box>
                                </Box>
                            </Box>  
                            :
                            <Box sx={{ marginTop: "20px" }}>
                            {/* Render the quizzes for the selected tab */}
                            <Box className="right-tabs-container">
                                {this.getQuizzesForTab()?.map((quiz, index) => (
                                <Box className="right-tabs">
                                <Box className="quiz-details">
                                    <this.HexBadge grade={quiz.attributes.quiz.data.attributes.score} />
                                    <Box key={index} sx={{ marginBottom: "10px"}}>
                                        <Typography variant="body1">{quiz.attributes.quiz.data.attributes.title}</Typography>
                                        <Typography variant="body2" style={{ color: "#94A3B8" }}>{new Date(quiz.attributes.quiz.data.attributes.created_at).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</Typography>
                                    </Box>
                                </Box>
                                  <img className="removeMenu" 
                                    src={remove_icon} 
                                    onClick={() => this.setState(prevState => ({
                                        isRemoveFavorite: prevState.isRemoveFavorite === quiz.id ? null : quiz.id
                                    }))}  
                                    alt="remove-icon" />
                                   {this.state.isRemoveFavorite === quiz.id && (
                                        <Box onClick={() => this.removeFavorite(quiz.id)} className="remove-favorite-btn">Remove favorite</Box>
                                   )}
                                </Box>
                                ))}
                                {this.state.favoriteQuizzes.length < 3 && 
                                <Box mt={5} className="choose-favourite-btn">
                                 <Button
                                     fullWidth
                                     variant="outlined"
                                     className={`${classes.chooseBtn}`}
                                     onClick={this.handleModalOpen}
                                 >
                                     {this.t("Choose Favorite E-Quizzes")}
                                 </Button>
                                 {this.state.isFavQuizzesModalOpen && this.chooseEQuizzes(classes, t)}
                                 </Box>
                                }
                            </Box>
                            <img className="e-score-img" src={escore_quiz_tab} />
                            </Box>
                          }
                        
                        </Box>
                         {this.state.isCampaignsModalOpen && this.campaignsReach(classes, t)}
                         {this.state.isNewContactsModalOpen && this.newContacts(classes, t)}
                         {this.state.isTasksCompletedModalOpen && this.tasksCompleted(classes, t)}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>

        </Box>
        </Box>
        </Box>

        );
    }
}

export default withStyles(customStyles, { withTheme: true })(OwnerDashboard);
